<div class="dialog-container">
  <h2 mat-dialog-title>{{ title }}</h2>
  
  <div mat-dialog-content>
    <!-- Progress Bar -->
    <div class="progress-container">
      <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>
      <div class="progress-text">{{ progress }}% Complete</div>
    </div>
    
    <!-- Steps List -->
    <div class="steps-container">
      <!-- Non-conflict steps -->
      <div class="step-group-card" *ngIf="getNonConflictSteps().length > 0" id="regular-changes-card">
        <div class="step-group-header">
          <h3>Pending Changes <span *ngIf="regularProgress" class="progress-indicator">{{ regularProgress }}</span></h3>
        </div>
        <mat-list>
          <div *ngFor="let step of getNonConflictSteps()" class="step-item" [class.completed]="step.completed" [id]="'step-' + step.id">
            <div class="step-row">
              <!-- Status Icon -->
              <mat-icon *ngIf="step.completed && step.success" class="success-icon" [inline]="true">check_circle</mat-icon>
              <mat-icon *ngIf="step.completed && !step.success" class="error-icon" [inline]="true">error</mat-icon>
              <mat-icon *ngIf="!step.completed" class="pending-icon" [inline]="true">pending</mat-icon>
              
              <!-- Step Text -->
              <span class="step-text" [matTooltip]="step.text">
                {{ step.text }}
              </span>
            </div>
            
            <!-- Error Message (if any) -->
            <div *ngIf="step.error" class="error-message" style="white-space: pre-wrap; word-break: break-word; max-width: 100%;">
              {{ step.error }}
            </div>
          </div>
        </mat-list>
      </div>
      
      <!-- Swap groups -->
      <div *ngFor="let group of getConflictGroups(); let i = index" class="step-group-card conflict-group" [id]="'swap-group-' + group.id">
        <div class="step-group-header">
          <h3>Swap Group {{ i + 1 }} <span *ngIf="groupProgress[group.id]" class="progress-indicator" [class.complete]="groupProgress[group.id] === 'Publish complete'">{{ groupProgress[group.id] }}</span></h3>
        </div>
        <mat-list>
          <div *ngFor="let step of group.steps" class="step-item" [class.completed]="step.completed" [id]="'step-' + step.id">
            <div class="step-row">
              <!-- Status Icon -->
              <mat-icon *ngIf="step.completed && step.success" class="success-icon" [inline]="true">check_circle</mat-icon>
              <mat-icon *ngIf="step.completed && !step.success" class="error-icon" [inline]="true">error</mat-icon>
              <mat-icon *ngIf="!step.completed" class="pending-icon" [inline]="true">pending</mat-icon>
              
              <!-- Step Text -->
              <span class="step-text" 
                [class.unallocated]="step.isUnallocated" 
                [class.published]="step.isPublished" 
                [matTooltip]="step.text">
                {{ step.text }}
              </span>
            </div>
            
            <!-- Error Message (if any) -->
            <div *ngIf="step.error" class="error-message" style="white-space: pre-wrap; word-break: break-word; max-width: 100%;">
              {{ step.error }}
            </div>
          </div>
        </mat-list>
      </div>
    </div>
    
    <!-- Status Message -->
    <div *ngIf="isComplete" class="status-message" [ngClass]="status">
      {{ statusMessage }}
    </div>
  </div>
  
  <div mat-dialog-actions align="end">
    <button mat-button *ngIf="!isComplete" (click)="onCancel()" color="warn">Cancel</button>
    <button mat-button *ngIf="isComplete" (click)="onClose()">Close</button>
  </div>
</div>
