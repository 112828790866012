import { Component, OnInit } from '@angular/core';
import { RosterService } from '../../services/roster.service';
import { StaffService } from '../../services/staff.service';
import { CarePlanService } from '../../services/careplan.service';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-roster-settings',
  templateUrl: './roster-settings.component.html',
  styleUrls: ['./roster-settings.component.css']
})
export class RosterSettingsComponent implements OnInit {
  autoAllocateEnabled = true;
  showAllStaffEnabled = true;
  isRefreshingStaff = false;
  isRefreshingServicePlan = false;

  constructor(
    private rosterService: RosterService,
    private staffService: StaffService,
    private carePlanService: CarePlanService,
    private helperService: HelperService
  ) { }

  ngOnInit() {
    // Default to enabled for fresh sessions
    this.autoAllocateEnabled = true;
    this.showAllStaffEnabled = true;
  }

  toggleAutoAllocate() {
    this.autoAllocateEnabled = !this.autoAllocateEnabled;
    this.rosterService.setAutoAllocateKeyStaff(this.autoAllocateEnabled);
  }

  toggleShowAllStaff() {
    this.showAllStaffEnabled = !this.showAllStaffEnabled;
    this.rosterService.setShowAllStaff(this.showAllStaffEnabled);
  }

  refreshStaffDetails() {
    if (this.isRefreshingStaff) return;
    
    this.isRefreshingStaff = true;
    this.staffService.refreshStaffCache().subscribe({
      next: () => {
        this.helperService.openSnackBar('Staff details refresh completed. Please reload the page to see changes.', 'Close');
        this.isRefreshingStaff = false;
      },
      error: (error) => {
        this.helperService.openSnackBar('Error refreshing staff details: ' + error.message, 'Close');
        this.isRefreshingStaff = false;
      }
    });
  }

  refreshClientServicePlan() {
    if (this.isRefreshingServicePlan) return;
    
    this.isRefreshingServicePlan = true;
    this.carePlanService.refreshClientServicePlanCache().subscribe({
      next: () => {
        this.helperService.openSnackBar('Client service plan refresh completed. Please reload the page to see changes.', 'Close');
        this.isRefreshingServicePlan = false;
      },
      error: (error) => {
        this.helperService.openSnackBar('Error refreshing client service plan: ' + error.message, 'Close');
        this.isRefreshingServicePlan = false;
      }
    });
  }
}
