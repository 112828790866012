import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PermissionsService } from '../services/permissions.service';

@Injectable({
    providedIn: 'root'
})
export class RosteringGuard {
    constructor(private permissionsService: PermissionsService) {}

    canActivate(): Observable<boolean> {
        // Allow access if user has either roster or reallocate permissions
        return this.permissionsService.hasAnyFeatureAccess(['roster', 'reallocate']);
    }
}
