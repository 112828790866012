import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

export interface PublishStep {
  id: string;
  text: string;
  completed: boolean;
  success: boolean;
  error?: string;
  isUnallocation?: boolean;
  isConflictResolution?: boolean;
  groupId?: string;
  isUnallocated?: boolean;
  isPublished?: boolean;
  finalFailure?: boolean; // Flag to mark steps that should not be overridden
}

export interface ConflictGroup {
  id: string;
  steps: PublishStep[];
}

export interface PublishProgressData {
  title: string;
  pendingCount: number;
  conflictCount: number;
}

@Component({
  selector: 'app-publish-progress-dialog',
  templateUrl: './publish-progress-dialog.component.html',
  styleUrls: ['./publish-progress-dialog.component.css']
})
export class PublishProgressDialogComponent implements OnInit, OnDestroy {
  title: string;
  steps: PublishStep[] = [];
  private subscriptions: Subscription[] = [];
  isComplete = false;
  status: 'progress' | 'success' | 'error' | 'warning' = 'progress';
  statusMessage = '';
  progress = 0;
  totalSteps = 0;
  completedSteps = 0;
  
  // Progress tracking
  regularProgress: string | null = null;
  groupProgress: { [groupId: string]: string } = {};
  
  // Current processing state
  private currentGroupId: string | null = null;
  private isUnallocating = false;
  private scrollMode: 'individual' | 'group' = 'individual';
  private lastCompletedStepId: string | null = null;

  constructor(
    public dialogRef: MatDialogRef<PublishProgressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PublishProgressData
  ) {
    this.title = data.title;
    this.totalSteps = data.pendingCount + data.conflictCount;
  }

  ngOnInit() {
    // Initialize with empty steps, they will be added dynamically
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  /**
   * Adds a new step to the progress dialog
   * @param step The step to add
   * @param incrementTotalSteps Whether to increment the total steps count (default: false)
   * Used when dynamically adding steps after initialization
   */
  addStep(step: PublishStep, incrementTotalSteps: boolean = false): void {
    this.steps.push(step);
    
    // Increment total steps count if requested (for dynamically added steps)
    if (incrementTotalSteps) {
      this.totalSteps++;
    }
  }

  /**
   * Marks a step as completed
   */
  completeStep(id: string, success: boolean = true, error?: string): void {
    const step = this.steps.find(s => s.id === id);
    if (step) {
      const wasNotCompleted = !step.completed;
      step.completed = true;
      step.success = success;
      
      // IMPORTANT: Only set error if no specific error is already present
      // or if it's a final failure step (to preserve specific error messages)
      if (error && (!step.error || step.error.trim() === '' || !step.finalFailure)) {
        step.error = error;
      }
      
      // If successful, mark as published (unless it's an unallocation step)
      if (success && !step.isUnallocation) {
        this.markStepPublished(id);
      }
      
      if (wasNotCompleted) {
        this.completedSteps++;
      }
      this.updateProgress();
      
      // Store the last completed step ID for scrolling
      this.lastCompletedStepId = id;
      
      // If we're in individual scroll mode, scroll to this step
      if (this.scrollMode === 'individual' && !step.groupId) {
        this.scrollToStep(id);
      }
    }
  }

  /**
   * Updates the overall progress percentage
   */
  private updateProgress(): void {
    // if completed steps is greater than total steps, set progress to 100%
    if (this.completedSteps > this.totalSteps) {
      this.progress = 100;
      return
    }

    this.progress = Math.round((this.completedSteps / this.totalSteps) * 100);
  }

  /**
   * Sets the dialog to success state
   */
  setSuccess(message: string = 'All changes published successfully'): void {
    this.status = 'success';
    this.statusMessage = message;
    this.isComplete = true;
  }

  /**
   * Sets the dialog to error state
   */
  setError(message: string = 'Some changes failed to publish. See details above.'): void {
    this.status = 'error';
    this.statusMessage = message;
    this.isComplete = true;
  }

  /**
   * Sets the dialog to warning state
   */
  setWarning(message: string = 'Some changes were published with warnings.'): void {
    this.status = 'warning';
    this.statusMessage = message;
    this.isComplete = true;
  }

  /**
   * Cancels the publish process
   */
  onCancel(): void {
    this.dialogRef.close({ canceled: true });
  }

  /**
   * Closes the dialog
   */
  onClose(): void {
    if (this.isComplete) {
      this.dialogRef.close({ canceled: false });
    }
  }

  /**
   * Gets all steps that are not part of a conflict group
   */
  getNonConflictSteps(): PublishStep[] {
    return this.steps.filter(step => !step.groupId);
  }

  /**
   * Gets all conflict groups with their steps
   */
  getConflictGroups(): ConflictGroup[] {
    // Get all unique group IDs
    const groupIds = [...new Set(
      this.steps
        .filter(step => step.groupId)
        .map(step => step.groupId)
    )];

    // Sort the group IDs to ensure consistent ordering
    // Dynamic swap group (which starts with 'z-') should be displayed last
    const sortedGroupIds = [...groupIds].sort((a, b) => {
      // First check if either is the dynamic group
      if (a?.startsWith('z-')) return 1;  // z- groups at the end
      if (b?.startsWith('z-')) return -1;
      
      // Then sort normally
      return a?.localeCompare(b || '') || 0;
    });

    // Create a group for each unique group ID
    return sortedGroupIds.map(groupId => ({
      id: groupId!,
      steps: this.steps.filter(step => step.groupId === groupId)
    }));
  }

  /**
   * Updates the progress indicator for regular changes
   */
  updateRegularProgress(current: number, total: number, phase: 'Publishing' = 'Publishing'): void {
    // Simplified progress indicator without counts
    this.regularProgress = phase === 'Publishing' 
      ? `Publishing changes...`
      : `${phase}...`;
    
    // Only scroll to the regular card if we're in group mode
    // In individual mode, we'll scroll to the specific step that was just completed
    if (this.scrollMode === 'group') {
      this.scrollToCard('regular-changes-card');
    }
  }

  /**
   * Updates the progress indicator for a conflict group
   */
  updateGroupProgress(groupId: string, current: number, total: number, phase: 'Unallocating' | 'Publishing' = 'Publishing'): void {
    // Simplified progress indicator without counts
    this.groupProgress[groupId] = `${phase}...`;
    
    // Switch to group scroll mode when we start processing conflict groups
    this.switchToGroupScrollMode();
    
    // Scroll to the group card
    this.scrollToCard(`swap-group-${groupId}`);
    
    this.currentGroupId = groupId;
    this.isUnallocating = phase === 'Unallocating';
  }

  /**
   * Clears the progress indicator for a group or sets it to completed
   * Also ensures all steps in the group are marked as completed
   */
  completeGroupProgress(groupId: string): void {
    // Only update if the group is still in a publishing state
    if (this.groupProgress[groupId] && this.groupProgress[groupId].includes('...')) {
      // Check if any steps in this group failed
      const groupSteps = this.steps.filter(step => step.groupId === groupId);
      
      // Get failed steps (both already completed and marked as failed)
      const failedSteps = groupSteps.filter(step => step.completed && !step.success);
      
      if (failedSteps.length > 0) {
        // Some steps failed - mark the group as failed
        this.groupProgress[groupId] = 'Completed with errors';
      } else {
        // All completed steps were successful
        this.groupProgress[groupId] = 'Publish complete';
      }
      
      // IMPORTANT: Only mark INCOMPLETE steps - respect the status of steps that have already been marked as completed
      // This avoids overriding any failure states that were explicitly set by the event processing
      const incompletedSteps = groupSteps.filter(step => !step.completed);
      
      // Before processing incomplete steps, restore any steps marked with finalFailure
      const finalFailureSteps = groupSteps.filter(step => step.finalFailure);
      
      incompletedSteps.forEach(step => {
        // Only mark as successful if we didn't have any failures in the group
        const success = failedSteps.length === 0;
        
        // Only provide a generic error message if the step doesn't already have one
        const genericError = failedSteps.length > 0 && (!step.error || step.error.trim() === '') 
          ? 'Processing skipped due to previous errors in this group' 
          : step.error;
        
        this.completeStep(step.id, success, genericError);
      });
      
      // Now restore any steps that were marked with finalFailure
      finalFailureSteps.forEach(step => {
        // Ensure these steps stay marked as failed
        step.success = false;
        // Keep the original error message
      });
      
      // After a short delay, hide the group progress status only if there were no errors
      setTimeout(() => {
        if (this.groupProgress[groupId] === 'Publish complete') {
          this.groupProgress[groupId] = '';
        }
        
        // Check if any steps have their error messages lost
        const stepsWithFinalFailure = groupSteps.filter(step => step.finalFailure);
        stepsWithFinalFailure.forEach(step => {
          // Ensure the error text is still visible even after group completion
          if (!step.error || step.error.trim() === '') {
            step.error = 'Failed due to unresolved conflicts';
          }
        });
      }, 1200);
    }
  }

  /**
   * Marks a step as unallocated
   */
  markStepUnallocated(id: string): void {
    const step = this.steps.find(s => s.id === id);
    if (step) {
      step.isUnallocated = true;
      step.isPublished = false;
    }
  }

  /**
   * Marks a step as published
   */
  markStepPublished(id: string): void {
    const step = this.steps.find(s => s.id === id);
    if (step) {
      step.isUnallocated = false;
      step.isPublished = true;
    }
  }

  /**
   * Scrolls to the specified card
   */
  private scrollToCard(cardId: string): void {
    setTimeout(() => {
      const card = document.getElementById(cardId);
      if (card) {
        card.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);
  }

  /**
   * Scrolls to a specific step by ID
   */
  private scrollToStep(stepId: string): void {
    setTimeout(() => {
      const stepElement = document.getElementById(`step-${stepId}`);
      if (stepElement) {
        // Scroll to the step with some extra space to see what's coming next
        stepElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }, 100);
  }

  /**
   * Switches to group scroll mode
   * Once switched to group mode, we don't go back to individual mode
   */
  switchToGroupScrollMode(): void {
    this.scrollMode = 'group';
  }
}
