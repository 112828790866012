import { Component, ElementRef, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from "@angular/core";
import { RosterService } from "../../services/roster.service";
import { ChangeManagementService } from "../../services/change-management.service";
import { DragDropService } from "../../services/drag-drop.service";
import { Subscription } from 'rxjs';
import { PublishComponent } from "src/app/components/publish/publish.component";
import { HelperService } from "../../services/helper.service";

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-roster',
    templateUrl: './roster.component.html',
    styleUrls: ['./roster.component.css', './scheduler.css', './rosterView.css', './folders.css']
})
export class RosterComponent implements OnInit, OnDestroy {
    @ViewChild("scheduler_here", { static: false }) schedulerContainer!: ElementRef;
    @ViewChild(PublishComponent) publishComponent!: PublishComponent;
    isLoading: boolean = true;
    isUAT: boolean = false;
    pendingChangesCount: number = 0;
    private isLoadingSubscription!: Subscription;
    private publishCompleteSubscription!: Subscription;
    private pendingChangesCountSubscription!: Subscription;

    constructor(
        private rosterService: RosterService,
        private changeManagementService: ChangeManagementService,
        private helperService: HelperService,
        private dragDropService: DragDropService,
        private elementRef: ElementRef
    ) { }

    ngOnInit(): void {
        const savedEnv = this.helperService.getStoredValue('ecase_env', 'prod');
        this.isUAT = savedEnv === 'uat';

        this.isLoadingSubscription = this.rosterService.isLoading$.subscribe((isLoading: boolean) => {
            this.isLoading = isLoading;
        });

        this.publishCompleteSubscription = this.changeManagementService.publishComplete$.subscribe(() => {
            if (this.publishComponent) {
                this.publishComponent.onPublishComplete();
            }
        });

        this.pendingChangesCountSubscription = this.changeManagementService.pendingChangesCount$.subscribe(count => {
            this.pendingChangesCount = count;
        });
    }

    ngAfterViewInit(): void {
        this.initializeScheduler();
    }

    initializeScheduler(): void {
        if (this.schedulerContainer) {
            this.loadData();
        }
    }

    loadData(): void {
        this.rosterService.setSchedulerContainer(this.schedulerContainer);
        this.rosterService.initScheduler();
        this.dragDropService.initializeDragAndDrop(this.elementRef);
        setTimeout(() => {
            this.isLoading = false;
        });
    }

    toggleEnvironment() {
        this.isUAT = !this.isUAT;
        const newEnv = this.isUAT ? 'uat' : 'prod';
        this.helperService.storeValue('ecase_env', newEnv);
        
        // Store the environment change timestamp
        localStorage.setItem('env_change_timestamp', Date.now().toString());
        
        // Force the page to reload
        window.location.reload();
      }

    show_minical() {
        this.rosterService.show_minical();
    }

    publishPendingChanges() {
        this.rosterService.publishPendingChanges();
    }

    cancelPendingChanges() {
        this.rosterService.cancelPublishing();
    }

    ngOnDestroy(): void {
        const scheduler = this.rosterService.scheduler;
        if (scheduler) {
            scheduler.destructor();
        }
        if (this.isLoadingSubscription) {
            this.isLoadingSubscription.unsubscribe();
        }
        if (this.publishCompleteSubscription) {
            this.publishCompleteSubscription.unsubscribe();
        }
        if (this.pendingChangesCountSubscription) {
            this.pendingChangesCountSubscription.unsubscribe();
        }
    }
}
