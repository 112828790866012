import { Injectable } from '@angular/core';
import { TaskItem } from '../models/task.model';
import { Observable, from, of, forkJoin } from 'rxjs';
import { map, concatMap, tap, finalize, takeUntil } from 'rxjs/operators';
import { PublishProgressDialogComponent } from '../components/publish/publish-progress-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ConflictManagementService {
  // Single dynamic group ID for all runtime-detected conflicts
  // Using 'z-' prefix ensures it sorts last alphabetically in the UI
  private readonly DYNAMIC_GROUP_ID = 'z-dynamic-swap-group';
  
  constructor() { }
  
  /**
   * Create conflict groups from a list of events and their conflict relationships
   * @param pendingEvents List of events with pending changes
   * @param hasConflict Function to check if an event has a conflict (returns conflicting event ID or null)
   * @returns Object containing conflict group information
   */
  createConflictGroups(
    pendingEvents: TaskItem[], 
    hasConflict: (eventId: string) => string | null
  ): {
    nonConflictEvents: TaskItem[];
    conflictGroupEvents: { groupId: string, events: TaskItem[] }[];
    eventToGroupMap: Map<string, string>;
    dynamicEventIds: Set<string>;
  } {
    // Maps and collections for tracking conflict groups
    const conflictGroups: Map<string, Set<string>> = new Map();
    const eventToGroupMap: Map<string, string> = new Map();
    let groupCounter = 0;
    
    // Create a dynamic conflict group that will be populated at runtime if needed
    const dynamicEventIds: Set<string> = new Set<string>();
    
    // First pass: create initial groups for direct conflicts
    pendingEvents.forEach(event => {
      const conflictId = hasConflict(event.id);
      if (conflictId) {
        // Check if either event is already in a group
        const eventGroupId = eventToGroupMap.get(event.id);
        const conflictGroupId = eventToGroupMap.get(conflictId);

        if (eventGroupId && conflictGroupId) {
          // Both events are in groups, merge the groups
          if (eventGroupId !== conflictGroupId) {
            const eventGroup = conflictGroups.get(eventGroupId)!;
            const conflictGroup = conflictGroups.get(conflictGroupId)!;
            
            // Merge into the first group
            conflictGroup.forEach(id => {
              eventGroup.add(id);
              eventToGroupMap.set(id, eventGroupId);
            });
            
            // Remove the second group
            conflictGroups.delete(conflictGroupId);
          }
        } else if (eventGroupId) {
          // Only the event is in a group, add the conflict to the same group
          conflictGroups.get(eventGroupId)!.add(conflictId);
          eventToGroupMap.set(conflictId, eventGroupId);
        } else if (conflictGroupId) {
          // Only the conflict is in a group, add the event to the same group
          conflictGroups.get(conflictGroupId)!.add(event.id);
          eventToGroupMap.set(event.id, conflictGroupId);
        } else {
          // Neither is in a group, create a new group
          const groupId = `group-${groupCounter++}`;
          conflictGroups.set(groupId, new Set([event.id, conflictId]));
          eventToGroupMap.set(event.id, groupId);
          eventToGroupMap.set(conflictId, groupId);
        }
      }
    });

    // Organize events by group
    const nonConflictEvents = pendingEvents.filter(event => !eventToGroupMap.has(event.id));
    nonConflictEvents.sort((a, b) => {
      return new Date(a.start_date).getTime() - new Date(b.start_date).getTime();
    });

    const conflictGroupEvents: { groupId: string, events: TaskItem[] }[] = [];
    Array.from(conflictGroups.entries()).forEach(([groupId, eventIds]) => {
      const groupEvents = pendingEvents.filter(event => eventIds.has(event.id));
      conflictGroupEvents.push({ groupId, events: groupEvents });
    });
    
    // Add a container for the dynamic group, which will be populated at runtime
    conflictGroups.set(this.DYNAMIC_GROUP_ID, new Set());

    return {
      nonConflictEvents,
      conflictGroupEvents,
      eventToGroupMap,
      dynamicEventIds
    };
  }
  

  /**
   * Add an event to the dynamic conflict group
   * @param event The event to add
   * @param eventToGroupMap The map of events to their group IDs
   * @param dynamicEventIds Set of event IDs in the dynamic group
   * @param progressDialog The progress dialog to update
   * @param errorMessage The error message received
   * @returns True if the event was added to the dynamic group
   */
  addToDynamicConflictGroup(
    event: TaskItem,
    eventToGroupMap: Map<string, string>,
    dynamicEventIds: Set<string>,
    progressDialog: PublishProgressDialogComponent,
    errorMessage: string
  ): boolean {
    // Format the error message to show full eCase error
    const formattedErrorMessage = `eCase error message: ${errorMessage}`;
    // Check if already in the dynamic conflict group
    if (eventToGroupMap.has(event.id) && eventToGroupMap.get(event.id) === this.DYNAMIC_GROUP_ID) {
      // Already in dynamic group - just mark as failed if this is a repeated call during processing
      
      // Update the step to show the error message
      const existingStep = progressDialog.steps.find(s => s.id === event.id && s.groupId === this.DYNAMIC_GROUP_ID);
      if (existingStep) {
        progressDialog.completeStep(event.id, false, errorMessage);
      }
      
      return false;
    }
    // If not already in any conflict group
    else if (!eventToGroupMap.has(event.id)) {
      // Add to dynamic group
      dynamicEventIds.add(event.id);
      eventToGroupMap.set(event.id, this.DYNAMIC_GROUP_ID);
      
      // Find the existing UI step
      const existingStep = progressDialog.steps.find(s => s.id === event.id);
      
      if (existingStep) {
        // Update the existing step to move it to the dynamic swap group
        existingStep.isConflictResolution = true;
        existingStep.groupId = this.DYNAMIC_GROUP_ID;
        existingStep.completed = false; // Reset completion status
        existingStep.success = false;   // Reset success status
      } else {
        // If for some reason there's no existing step, create one
        progressDialog.addStep({
          id: event.id,
          text: `Event ${event.id} - ${new Date(event.start_date).toLocaleTimeString()}`,
          completed: false,
          success: false,
          isConflictResolution: true,
          groupId: this.DYNAMIC_GROUP_ID
        });
      }
      
      return true;
    }
    // Already in a different conflict group
    else {
      // Already in some other conflict group
      return false;
    }
  }
  
  /**
   * Create a conflict group for dynamically detected conflicts
   * @param events All events in the system
   * @param dynamicEventIds Set of event IDs in the dynamic group
   * @returns The dynamic conflict group, or null if none exists
   */
  createDynamicConflictGroup(
    events: TaskItem[],
    dynamicEventIds: Set<string>
  ): { groupId: string, events: TaskItem[] } | null {
    if (dynamicEventIds.size === 0) {
      return null;
    }
    
    const groupEvents = events.filter(event => dynamicEventIds.has(event.id));
    if (groupEvents.length === 0) {
      return null;
    }
    
    return { 
      groupId: this.DYNAMIC_GROUP_ID,
      events: groupEvents
    };
  }
  
  /**
   * Check if an event is unallocated based on the carer ID
   * @param event The event to check
   * @returns True if the event is unallocated
   */
  isEventUnallocated(event: TaskItem): boolean {
    const carerString = event.carer_string;
    if (!carerString) {
      return true;
    }
    const carerId = parseInt(carerString);
    return carerId >= 2000000 && carerId < 3000000;
  }
}
