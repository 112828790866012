export const environment = {
    production: false,
    clientName: 'Henley',
    msalConfig: {
        auth: {
            clientId: '24981299-8d52-4e15-b7b4-00cc1cdbd194',
            authority: 'https://login.microsoftonline.com/c886e28e-cc9f-41a2-b482-372bfdc52470',
            redirectUri: 'https://scheduler.resitech.services/',
            postLogoutRedirectUri: 'https://scheduler.resitech.services/'
        }
    },
    graphApi: {
		scopes: ['User.Read', 'GroupMember.Read.All'],
		uri: 'https://graph.microsoft.com/v1.0'
	},
	backend:
	{
        scopes: ['api://88b9a2f0-5659-497a-acc1-134197f33d23/access_as_user'],
		uri: 'https://scheduler.resitech.services/api'
	}
  };
