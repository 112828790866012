import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { Observable, from, of } from 'rxjs';
import { BaseApiService } from './api-base.service';
import { HelperService } from './helper.service';
import { switchMap, catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClientService extends BaseApiService {
  private env: string;
  private readonly CACHE_NAME = 'client-photos-cache';

  constructor(
    http: HttpClient,
    msalService: MsalService,
    private helperService: HelperService
  ) {
    super(http, msalService);
    this.env = this.helperService.getStoredValue('ecase_env', 'prod');
  }

  /**
   * Loads a client's profile photo from the API and caches it in the browser
   * @param residentId The ID of the resident/client
   * @returns Observable<Blob> The photo as a Blob
   */
  getClientPhoto(residentId: string): Observable<Blob> {
    // First check if the photo is in the cache
    return from(this.getPhotoFromCache(residentId)).pipe(
      switchMap(cachedPhoto => {
        if (cachedPhoto) {
          return of(cachedPhoto); // Return cached photo if found
        }

        // If not in cache, fetch from API
        const url = `${this.apiUrl}/ecase/${this.env}/photos/clients/${residentId}`;
        return this.getBlob(url).pipe(
          tap(photo => {
            // Cache the photo for future use
            this.cachePhoto(residentId, photo);
          })
        );
      }),
      catchError(error => {
        throw error;
      })
    );
  }

  /**
   * Attempts to retrieve a photo from the browser cache
   * @param residentId The ID of the resident/client
   * @returns Promise<Blob | null> The cached photo or null if not found
   */
  private async getPhotoFromCache(residentId: string): Promise<Blob | null> {
    try {
      const cache = await caches.open(this.CACHE_NAME);
      const response = await cache.match(residentId);
      if (response) {
        return await response.blob();
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  /**
   * Caches a photo in the browser cache
   * @param residentId The ID of the resident/client
   * @param photo The photo blob to cache
   */
  private async cachePhoto(residentId: string, photo: Blob): Promise<void> {
    try {
      const cache = await caches.open(this.CACHE_NAME);
      const response = new Response(photo);
      await cache.put(residentId, response);
    } catch (error) {
    }
  }
}
