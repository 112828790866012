<div class="settings-container">
  <button mat-icon-button
    [matMenuTriggerFor]="settingsMenu"
    class="settings-button"
    [ngClass]="{'settings-warning': !autoAllocateEnabled || !showAllStaffEnabled}"
    matTooltip="Settings">
    <mat-icon>settings</mat-icon>
  </button>
  
  <mat-menu #settingsMenu="matMenu" class="settings-menu">
    <div class="menu-content" (click)="$event.stopPropagation()">
      <button mat-button 
        class="toggle-button"
        [ngClass]="autoAllocateEnabled ? 'enabled-button' : 'disabled-button'"
        (click)="toggleAutoAllocate()"
        matTooltip="Auto-allocate services to key staff">
        <span class="status-dot" [ngClass]="autoAllocateEnabled ? 'enabled-dot' : 'disabled-dot'"></span>
        Auto allocate
      </button>
      
      <button mat-button 
        class="toggle-button"
        [ngClass]="showAllStaffEnabled ? 'enabled-button' : 'disabled-button'"
        (click)="toggleShowAllStaff()"
        matTooltip="Display all staff including those without any services allocated">
        <span class="status-dot" [ngClass]="showAllStaffEnabled ? 'enabled-dot' : 'disabled-dot'"></span>
        Show empty rows
      </button>
    </div>
  </mat-menu>
</div>
