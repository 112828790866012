import { Component, OnInit } from '@angular/core';
import { RosterService } from '../../services/roster.service';

@Component({
  selector: 'app-roster-settings',
  templateUrl: './roster-settings.component.html',
  styleUrls: ['./roster-settings.component.css']
})
export class RosterSettingsComponent implements OnInit {
  autoAllocateEnabled = true;
  showAllStaffEnabled = true;

  constructor(private rosterService: RosterService) { }

  ngOnInit() {
    // Default to enabled for fresh sessions
    this.autoAllocateEnabled = true;
    this.showAllStaffEnabled = true;
  }

  toggleAutoAllocate() {
    this.autoAllocateEnabled = !this.autoAllocateEnabled;
    this.rosterService.setAutoAllocateKeyStaff(this.autoAllocateEnabled);
  }

  toggleShowAllStaff() {
    this.showAllStaffEnabled = !this.showAllStaffEnabled;
    this.rosterService.setShowAllStaff(this.showAllStaffEnabled);
  }
}
