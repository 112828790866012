<div mat-dialog-title>Updating key staff</div>
<div mat-dialog-content>
  <mat-list>
    <mat-list-item *ngFor="let step of steps" [class.completed]="step.completed">
      <mat-checkbox [checked]="step.completed" [disabled]="true">
        <span [class.strikethrough]="step.completed">{{ step.text }}</span>
      </mat-checkbox>
    </mat-list-item>
  </mat-list>
  
  <div *ngIf="status !== 'progress'" class="status-message" [class.error]="status === 'error'">
    {{ statusMessage }}
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button [disabled]="!isComplete" (click)="onClose()">Close</button>
</div>
