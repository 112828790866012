<!-- App Logo -->
<div class="logo-section">
    <img [src]="isUAT ? 'assets/symbol/symbol-fill-uat-500.png' : 'assets/symbol/symbol-fill-prod-500.png'" alt="logo"
        class="logo">
</div>

<!-- Main Content Section -->
<div class="main-content">
    <!-- Filter Icons Section -->
    <div class="filter-section">

        <!-- Carer State Toggle -->
        <div class="filter-group">
            <ul class="filter-list">
                <li>
                    <div class="menu-container">
                        <button mat-icon-button [class]="getCarerIconState().classes"
                            (click)="handleUserIconClick($event)"
                            (mouseenter)="onButtonMouseEnter($event, 'carerState')"
                            (mouseleave)="onButtonMouseLeave('carerState')">
                            <mat-icon>{{getCarerIconState().icon}}</mat-icon>
                        </button>
                        <app-mini-menu [options]="carerStateOptions" [isVisible]="showCarerStateMenu"
                            [selectedValue]="viewState" [buttonTop]="carerStateButtonTop"
                            (optionSelected)="onCarerStateOptionSelected($event)"
                            (visibilityChange)="showCarerStateMenu = $event">
                        </app-mini-menu>
                    </div>
                </li>
            </ul>
        </div>

        <!-- Time of Day -->
        <div class="filter-group">
            <ul class="filter-list">
                <li>
                    <div class="menu-container">
                        <button mat-icon-button [class.active]="timeOfDay !== TimeOfDay.ANY"
                            (mouseenter)="onButtonMouseEnter($event, 'time')" (mouseleave)="onButtonMouseLeave('time')">
                            <mat-icon>{{getTimeOfDayIcon()}}</mat-icon>
                        </button>
                        <app-mini-menu [options]="timeOptions" [isVisible]="showTimeMenu" [selectedValue]="timeOfDay"
                            [buttonTop]="timeButtonTop" (optionSelected)="onTimeOptionSelected($event)"
                            (visibilityChange)="showTimeMenu = $event">
                        </app-mini-menu>
                    </div>
                </li>
            </ul>
        </div>

        <!-- Client Type -->
        <div class="filter-group">
            <ul class="filter-list">
                <li>
                    <div class="menu-container">
                        <button mat-icon-button [class.active]="clientType !== ClientType.BOTH"
                            (mouseenter)="onButtonMouseEnter($event, 'client')"
                            (mouseleave)="onButtonMouseLeave('client')">
                            <mat-icon>{{getClientTypeIcon()}}</mat-icon>
                        </button>
                        <app-mini-menu [options]="clientOptions" [isVisible]="showClientMenu"
                            [selectedValue]="clientType" [buttonTop]="clientButtonTop"
                            (optionSelected)="onClientOptionSelected($event)"
                            (visibilityChange)="showClientMenu = $event">
                        </app-mini-menu>
                    </div>
                </li>
            </ul>
        </div>

        <!-- Auto Allocate Toggle -->
        <div class="filter-group">
            <ul class="filter-list">
                <li>
                    <div class="menu-container">
                        <button mat-icon-button 
                            [class.active]="!autoAllocateEnabled"
                            (click)="toggleAutoAllocate()"
                            matTooltip="{{autoAllocateEnabled ? 'Auto-allocate Key Staff Enabled' : 'Auto-allocate Key Staff Disabled'}}"
                            [matTooltipPosition]="'right'">
                            <mat-icon>{{autoAllocateEnabled ? 'key' : 'key_off'}}</mat-icon>
                        </button>
                    </div>
                </li>
            </ul>
        </div>

    </div>

    <!-- Footer Section -->
    <div class="footer-section">
        <!-- Notification Icon -->
        <button mat-icon-button *ngIf="unreadCount > 0" (click)="openNotifications()"
            matTooltip="{{unreadCount}} unread notifications" [matTooltipPosition]="'right'" class="has-notifications">
            <mat-icon [class.warning-icon]="hasWarningOrCritical" [class.critical-icon]="hasCritical">
                notifications
            </mat-icon>
            <span class="notification-badge" *ngIf="unreadCount > 0">{{unreadCount}}</span>
        </button>

        <button mat-icon-button [matMenuTriggerFor]="settingsMenu" matTooltip="Settings" [matTooltipPosition]="'right'">
            <mat-icon>settings</mat-icon>
        </button>
        <mat-menu #settingsMenu="matMenu">
            <button mat-menu-item (click)="toggleColumnWidth()">
                <mat-icon class="rotate-90">{{ isWideColumns ? 'compress' : 'expand' }}</mat-icon>
                <span>{{ isWideColumns ? 'Default Width' : 'Wide Columns' }}</span>
            </button>
            <button mat-menu-item (click)="toggleHideIconsSetting()">
                <mat-icon>{{ hideIconsForShortEvents ? 'check_box' : 'check_box_outline_blank' }}</mat-icon>
                <span>Hide icons for short services</span>
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="toggleTheme()">
                <mat-icon>{{ isDarkMode ? 'light_mode' : 'dark_mode' }}</mat-icon>
                <span>{{ isDarkMode ? 'Light Mode' : 'Dark Mode' }}</span>
            </button>
            <button mat-menu-item (click)="openLegend()">
                <mat-icon>info</mat-icon>
                <span>Legend</span>
            </button>
            <button mat-menu-item [matMenuTriggerFor]="refreshMenu">
                <mat-icon>refresh</mat-icon>
                <span>Refresh eCase Data</span>
            </button>
        </mat-menu>

        <mat-menu #refreshMenu="matMenu">
            <button mat-menu-item (click)="refreshAllData()" [disabled]="refreshAllDataInProgress">
                <mat-icon>refresh</mat-icon>
                <span>Refresh All Data</span>
            </button>
            <button mat-menu-item (click)="refreshProfilePhotos()" [disabled]="refreshProfilePhotosInProgress">
                <mat-icon>photo</mat-icon>
                <span>Refresh Profile Photos</span>
            </button>
        </mat-menu>

        <button mat-button (click)="toggleEnvironment()" [matTooltip]="isUAT ? 'Switch to PROD' : 'Switch to UAT'"
            [matTooltipPosition]="'right'" class="env-toggle" [class.prod-button]="!isUAT" [class.uat-button]="isUAT">
            {{ isUAT ? 'UAT' : 'PROD' }}
        </button>
    </div>
</div>
