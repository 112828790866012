import { Component } from '@angular/core';
import { HelperService } from '../services/helper.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent {
    isUAT: boolean;

    constructor(private helperService: HelperService) {
        this.isUAT = this.helperService.getStoredValue('ecase_env', 'prod') === 'uat';
    }
}
