import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StaffService } from '../../services/staff.service';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-email-mismatch-dialog',
  templateUrl: './email-mismatch-dialog.component.html',
  styleUrls: ['./email-mismatch-dialog.component.css']
})
export class EmailMismatchDialogComponent {
  theme: string;
  constructor(
    public dialogRef: MatDialogRef<EmailMismatchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { email: string },
    private staffService: StaffService,
    private helperService: HelperService
  ) {
    // Get environment theme (prod/uat)
    this.theme = `theme-${this.helperService.getStoredValue('ecase_env', 'prod')}`;
  }

  copyEmail(input: HTMLInputElement) {
    input.select();
    document.execCommand('copy');
    input.setSelectionRange(0, 0);
  }

  close() {
    this.dialogRef.close(false);
  }

  refreshAndRetry() {
    this.staffService.refreshStaffCache().subscribe({
      next: () => {
        this.dialogRef.close(true);
        window.location.reload();
      },
      error: () => {
        // Even if refresh fails, close dialog and reload to retry
        this.dialogRef.close(true);
        window.location.reload();
      }
    });
  }
}
