import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { TaskItem } from '../models/task.model';
import { IconService } from './icon.service';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  private activeSnackBarRef?: MatSnackBarRef<TextOnlySnackBar>;

  constructor(
    private snackBar: MatSnackBar,
    private iconService: IconService
  ) { }

  openSnackBar(message: string, action: string) {
    const config = new MatSnackBarConfig();
    config.duration = 5000;
    config.verticalPosition = 'top';
    this.snackBar.open(message, action, config);
  }

  openProgressSnackBar(initialMessage: string): MatSnackBarRef<TextOnlySnackBar> {
    const config = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.duration = undefined; // Stay open until manually closed
    this.activeSnackBarRef = this.snackBar.open(initialMessage, '', config);
    return this.activeSnackBarRef;
  }

  updateProgressSnackBar(message: string) {
    if (this.activeSnackBarRef) {
      this.activeSnackBarRef.instance.data = { ...this.activeSnackBarRef.instance.data, message };
    }
  }

  closeProgressSnackBar() {
    if (this.activeSnackBarRef) {
      this.activeSnackBarRef.dismiss();
      this.activeSnackBarRef = undefined;
    }
  }

  public getStoredValue(key: string, defaultValue: any) {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  }

  public storeValue(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  saveLastUsedDate(date: Date | any) {
    if (date instanceof Date && !isNaN(date.getTime())) {
      localStorage.setItem('lastUsedDate', date.toISOString());
    } else {
      // if it's a string, try to convert it to a date
      try {
        const parsedDate = new Date(date);
        if (!isNaN(parsedDate.getTime())) {
          localStorage.setItem('lastUsedDate', parsedDate.toISOString());
        }
      } catch (error) {
        // if it's not a valid date, use the current date
        localStorage.setItem('lastUsedDate', new Date().toISOString());
      }
    }
  }

  getLastUsedDate(): Date {
    const storedDate = localStorage.getItem('lastUsedDate');
    return storedDate ? new Date(storedDate) : new Date();
  }

  areDatesEqual(date1: Date, date2: Date): boolean {
    return date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate() &&
      date1.getHours() === date2.getHours() &&
      date1.getMinutes() === date2.getMinutes();
  }

  private getColorForClass(className: string): string {
    switch (className) {
      case 'relocated-section-1000001': return '#0bb319';
      case 'relocated-section-1000002': return '#2da3cb';
      case 'relocated-section-1000010': return '#a87c0c';
      default: return '#c1c1c1';
    }
  }

  private generateAppointmentContent(
    start: Date,
    end: Date,
    task: TaskItem,
    options: { includeDefaultAllocation: boolean; lineClassName: string }
  ): string {
    let clientDetails: string = '';

    if (typeof task.client_name === 'string') {
      clientDetails = task.client_name
        .replace(/^\~\~/g, '<s title="cancelled">')
        .replace(/\~\~$/g, '</s>');
    } else if (task.client_name != null) {
      clientDetails = String(task.client_name)
        .replace(/^\~\~/g, '<s title="cancelled">')
        .replace(/\~\~$/g, '</s>');
    }

    const timeRange = `${format(start, 'HH:mm')} - ${format(end, 'HH:mm')}`;

    let suggestedCarerName = task.suggested_carer_name ? task.suggested_carer_name : 'Not set';

    let defaultIconColor = this.getColorForClass(task.css_custom);
    if (task.pending_change && defaultIconColor === '#c1c1c1') {
      defaultIconColor = '#000000';
    }

    const isFemale = task.client_gender === 'Female';

    const clientIcon = isFemale
      ? this.iconService.clientf.withColor(defaultIconColor)
      : this.iconService.client.withColor(defaultIconColor);
    const serviceIcon = this.iconService.service.withColor(defaultIconColor);
    let clockIcon = this.iconService.clock.withColor(defaultIconColor);
    let userIcon = this.iconService.user.withColor(defaultIconColor);

    let timeDisplay;
    if (task.pending_timing_change) {
      clockIcon = this.iconService.clockSolid.withColor('#ee0000');
      const originalTimeRange = `${format(new Date(task.original_start_date), 'HH:mm')} - ${format(
        new Date(task.original_end_date),
        'HH:mm'
      )}`;
      timeDisplay = `<span style="color: red; font-weight: 700;">${timeRange}</span><span style="color: black;"> - (was ${originalTimeRange})</span>`;
    } else {
      timeDisplay = timeRange;
    }

    let content = '';
    const duration = (end.getTime() - start.getTime()) / (1000 * 60);
    const hideIconsForShort = duration < 30 && this.getStoredValue('hideIconsForShortEvents', true);
    const showIcons = !hideIconsForShort;

    content += `
      <div class="${options.lineClassName}">
        ${ showIcons ? `<span class="key">${clockIcon}</span>` : '' }
        <span>${timeDisplay}</span>
      </div>
    `;
    
    // Only show client details for non-staff events
    if (!task.is_staff_event) {
      content += `
        <div class="${options.lineClassName}">
          ${ showIcons ? `<span class="key">${clientIcon}</span>` : '' }
          <span><b>${clientDetails}</b></span>
        </div>
      `;
    }

    if (options.includeDefaultAllocation) {
      content += `
        <div class="${options.lineClassName}">
          ${ showIcons ? `<span class="key">${userIcon}</span>` : '' }
          <span>Default allocation: <b>${suggestedCarerName}</b></span>
        </div>
      `;
    }

    content += `
      <div class="${options.lineClassName} appointment-text-wrap">
        ${ showIcons ? `<span class="key">${serviceIcon}</span>` : '' }
        ${task.is_2pa ? `<span style="color: blue; font-weight: bold;">2PA </span>` : ''}
        <span class="appointment-text">${task.text}</span>
      </div>
    `;

    return content;
  }

  get_tooltip_text(start: Date, end: Date, task: TaskItem): string {
    const content = this.generateAppointmentContent(start, end, task, {
      includeDefaultAllocation: true,
      lineClassName: 'tooltip-line'
    });

    return `
      <div class="tooltip">
        <div class="tooltip-inner">
          ${content}
        </div>
      </div>
    `;
  }

  getAppointmentHtml(start: Date, end: Date, task: TaskItem): string {
    const content = this.generateAppointmentContent(start, end, task, {
      includeDefaultAllocation: false,
      lineClassName: 'line'
    });

    return `
      <span class="cal_event_line_text">
        <div class="cal_event_line_text_inner">
          ${content}
        </div>
      </span>
    `;
  }


  // get_tooltip_text(start: Date, end: Date, task: TaskItem): string {
  //   let clientDetails: string = '';

  //   if (typeof task.client_name === 'string') {
  //     clientDetails = task.client_name
  //       .replace(/^\~\~/g, '<s title="cancelled">')
  //       .replace(/\~\~$/g, '</s>');
  //   } else if (task.client_name != null) {
  //     clientDetails = String(task.client_name)
  //       .replace(/^\~\~/g, '<s title="cancelled">')
  //       .replace(/\~\~$/g, '</s>');
  //   }

  //   const timeRange = `${format(start, 'HH:mm')} - ${format(end, 'HH:mm')}`;

  //   let suggestedCarerName = task.suggested_carer_name ? task.suggested_carer_name : 'Not set';

  //   let timeDisplay;
  //   if (task.pending_timing_change) {
  //     const originalTimeRange = `${format(new Date(task.original_start_date), 'HH:mm')} - ${format(new Date(task.original_end_date), 'HH:mm')}`;
  //     timeDisplay = `<span style="color: red; font-weight: 700;">${timeRange}</span> <span style="color: black;">(was ${originalTimeRange})</span>`;
  //   } else {
  //     timeDisplay = timeRange;
  //   }

  //   return `
  //       <div class="tooltip">
  //         <div class="tooltip-inner">

  //           <div class="tooltip-line">
  //             <span class="key"><img src="assets/clock-plus.png" height="12" class="icon"></span>
  //             <span>${timeDisplay}</span>
  //           </div>

  //           <div class="tooltip-line">
  //             <span class="key"><img src="assets/client.png" height="12" class="icon"></span>
  //             <span><b>${clientDetails}</b></span>
  //           </div>

  //           <div class="tooltip-line">
  //             <span class="key"><img src="assets/client.png" height="12" class="icon"></span>
  //             <span>Default allocation: <b>${suggestedCarerName}</b></span>
  //           </div>

  //           <div class="tooltip-line appointment-text-wrap">
  //             <span class="key"><img src="assets/service.png" height="12" class="icon"></span>
  //             <span class="appointment-text">${task.text}</span>
  //           </div>

  //         </div>
  //       </div>`;
  // }


  // getAppointmentHtml(start: Date, end: Date, task: TaskItem): string {
  //   const timeRange = `${format(start, 'HH:mm')} - ${format(end, 'HH:mm')}`;
  //   let clientDetails: string = '';

  //   if (typeof task.client_name === 'string') {
  //     clientDetails = task.client_name
  //       .replace(/^\~\~/g, '<s title="cancelled">')
  //       .replace(/\~\~$/g, '</s>');
  //   } else if (task.client_name != null) {
  //     clientDetails = String(task.client_name)
  //       .replace(/^\~\~/g, '<s title="cancelled">')
  //       .replace(/\~\~$/g, '</s>');
  //   }


  //   let defaultIconColor = this.getColorForClass(task.css_custom);
  //   // if there is a pending change and the defaulticon color is d3d3d3, set it to black
  //   if (task.pending_change && defaultIconColor === '#c1c1c1') {
  //     defaultIconColor = '#000000';
  //   }
  //   const isFemale = task.client_gender === 'Female'

  //   const clientIcon = isFemale ? this.iconService.clientf.withColor(defaultIconColor) : this.iconService.client.withColor(defaultIconColor);
  //   const serviceIcon = this.iconService.service.withColor(defaultIconColor);
  //   let clockIcon = this.iconService.clock.withColor(defaultIconColor);
  //   let line1 = `<span class="key">${clockIcon}</span><span>${timeRange}</span>`;

  //   if (task.pending_timing_change) {
  //     clockIcon = this.iconService.clockSolid.withColor('#ee0000');
  //     const originalTimeRange = `${format(new Date(task.original_start_date), 'HH:mm')} - ${format(new Date(task.original_end_date), 'HH:mm')}`;
  //     line1 = `<span class="key">${clockIcon}</span><span style="color: #ee0000; font-weight: 700;">${timeRange}</span><span style="color: black;"> - (was ${originalTimeRange})</span>`;
  //   }

  //   return `
  //       <span class="cal_event_line_text">
  //         <div class="cal_event_line_text_inner">
  //           <div class="line">
  //             ${line1}
  //           </div>
  //           <div class="line">
  //             <span class="key">${clientIcon}</span>
  //             <span style="font-size:12px"><b>${clientDetails}</b></span>
  //           </div>
  //           <div class="line appointment-text-wrap"> 
  //             <span class="key">${serviceIcon}</span>
  //             <span class="appointment-text">${task.text}</span>
  //           </div>
  //         </div>
  //       </span>`;

  // }
}
