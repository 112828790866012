import { Component, OnInit } from '@angular/core';
import { StaffService } from '../../services/staff.service';
import { GraphService } from '../../services/graph.service';
import { ParsedStaffDetails } from '../../models/staff.model';

@Component({
  selector: 'app-staff-test',
  template: `
    <div style="padding: 20px;">
      <h2>Staff API Test</h2>
      <button mat-raised-button color="primary" (click)="testGetStaffDetails()">
        Test Get Staff Details
      </button>
      <button mat-raised-button color="accent" (click)="testRefreshCache()" style="margin-left: 10px;">
        Test Refresh Cache
      </button>

      <div *ngIf="staffDetails" style="margin-top: 20px;">
        <h3>Staff Details:</h3>
        <div>
          <p><strong>Staff ID:</strong> {{staffDetails.StaffID}}</p>
          <p><strong>Employee ID:</strong> {{staffDetails.EmployeeID || 'Not assigned'}}</p>
          <p><strong>Name:</strong> {{staffDetails.FirstName}} {{staffDetails.LastName}}</p>
          <p><strong>Status:</strong> {{staffDetails.StaffStatus}}</p>
          <p><strong>Department:</strong> {{staffDetails.Department || 'Not assigned'}}</p>
          <p><strong>Email:</strong> {{staffDetails.Email}}</p>
        </div>
        
        <h4>Roles:</h4>
        <div *ngIf="staffDetails.roles.length > 0; else noRoles">
          <ul>
            <li *ngFor="let role of staffDetails.roles">{{ role }}</li>
          </ul>
        </div>
        <ng-template #noRoles>
          <p>No roles assigned</p>
        </ng-template>

        <div *ngIf="staffDetails.roles.includes('RN')" style="margin-top: 10px; color: green;">
          User has RN role
        </div>
      </div>
    </div>
  `
})
export class StaffTestComponent implements OnInit {
  staffDetails: ParsedStaffDetails | null = null;

  constructor(
    private staffService: StaffService,
    private graphService: GraphService
  ) {}

  ngOnInit() {}

  testGetStaffDetails() {
    this.graphService.getUserProfile().subscribe(
      (profile) => {
        console.log('Graph profile:', profile);
        
        if (profile.mail) {
          this.staffService.getStaffDetails(profile.mail).subscribe(
            (response) => {
              console.log('Staff details response:', response);
              this.staffDetails = response;
            },
            (error) => {
              console.error('Error getting staff details:', error);
            }
          );
        } else {
          console.error('No email found in profile');
        }
      },
      (error) => {
        console.error('Error getting graph profile:', error);
      }
    );
  }

  testRefreshCache() {
    this.staffService.refreshStaffCache().subscribe(
      (response) => {
        console.log('Refresh cache response:', response);
      },
      (error) => {
        console.error('Error refreshing cache:', error);
      }
    );
  }
}
