import { Injectable, ElementRef } from '@angular/core';
import { Scheduler, SchedulerStatic } from "@dhx/scheduler";
import { HelperService } from './helper.service';
import { TaskService } from './task.service';
import { ChangeManagementService } from './change-management.service';
import { DragDropService } from './drag-drop.service';
import { TaskItem } from '../models/task.model';

@Injectable({
  providedIn: 'root'
})
export class SchedulerConfigService {
  constructor(
    private helperService: HelperService,
    private taskService: TaskService,
    private changeManagementService: ChangeManagementService,
    private dragDropService: DragDropService
  ) {}

  configureTimelineView(scheduler: SchedulerStatic, y_unit: any[]): void {
    const currentDate = scheduler.getState().date;
    const scrollDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 6, 0, 0);

    scheduler.createTimelineView({
      section_autoheight: false,
      name: "timeline",
      x_unit: "minute",
      x_date: "%i",
      x_step: 10,
      x_size: 144,
      x_start: 0,
      x_length: 144,
      y_unit: y_unit,
      folder_dy: 28,
      event_dy: "full",
      event_min_dy: 50,
      dy: 62,
      dx: 250,
      y_property: "carer_string",
      render: "tree",
      scrollable: true,
      scroll_position: scrollDate,
      column_width: 40,
      second_scale: {
        x_unit: "hour",
        x_date: "%H:%i"
      }
    });
  }

  initScheduler(schedulerContainer: ElementRef): SchedulerStatic {
    const scheduler = Scheduler.getSchedulerInstance();
    this.configureSchedulerPlugins(scheduler);
    this.configureSchedulerSettings(scheduler);
    this.configureTimelineView(scheduler, []);
    scheduler.init(schedulerContainer.nativeElement, new Date(), "timeline");
    return scheduler;
  }

  private configureSchedulerPlugins(scheduler: SchedulerStatic): void {
    scheduler.plugins({
      multiselect: true,
      minical: true,
      timeline: true,
      tooltip: true,
      cookie: true,
      treetimeline: true
    });
  }

  private configureSchedulerSettings(scheduler: SchedulerStatic): void {
    scheduler.config.all_timed = true;
    scheduler.config.ajax_error = "console";
    scheduler.config.date_format = '%Y-%m-%d %H:%i:%s';
    scheduler.config.default_date = '%l, %j %F';
    scheduler.config.details_on_dblclick = true;
    scheduler.config.drag_create = false;
    scheduler.config.drag_resize = false;
    scheduler.config.drag_move = true;
    scheduler.config.dblclick_create = false;
    scheduler.config.edit_on_create = false;
    scheduler.config.icons_select = [];
    scheduler.config.show_loading = true;
    scheduler.locale.labels['timeline_tab'] = "Timeline";
    scheduler.locale.labels.section_description = "Section";
    scheduler.setSkin("material");
  }

  setupSchedulerTemplates(scheduler: SchedulerStatic): void {
    scheduler.templates.event_bar_text = (start: Date, end: Date, appointment: any) =>
      this.helperService.getAppointmentHtml(start, end, appointment);

    scheduler.templates.tooltip_text = (start: Date, end: Date, appointment: any) =>
      this.helperService.get_tooltip_text(start, end, appointment);

    scheduler.templates.event_class = (event_start_date, event_end_date, appointment) => {
      let cssClass = (appointment.css_ecase + " " + appointment.css_custom).trim();
      if (appointment.pending_change) {
        cssClass += " pending-change";
      }
      return cssClass.trim();
    };
    
    scheduler.templates.tooltip_date_format = function (date) {
      var formatFunc = scheduler.date.date_to_str("%d %M %Y %H:%i:%s");
      return formatFunc(date);
    }

    scheduler.templates.timeline_row_class = (section: any) => {
      if (section.children) {
        return "folder children";
      }
      return "";
    };
  }

  private addPreferredExcludedStyles(): void {
    // Add styles if they don't exist
    if (!document.getElementById('preferred-excluded-styles')) {
      const styles = document.createElement('style');
      styles.id = 'preferred-excluded-styles';
      styles.textContent = `
        .preferred-carer {
          background: repeating-linear-gradient(
            45deg,
            rgba(0, 255, 0, 0.1),
            rgba(0, 255, 0, 0.1) 10px,
            transparent 10px,
            transparent 20px
          );
        }
        .excluded-carer {
          background: repeating-linear-gradient(
            45deg,
            rgba(255, 0, 0, 0.1),
            rgba(255, 0, 0, 0.1) 10px,
            transparent 10px,
            transparent 20px
          );
        }
      `;
      document.head.appendChild(styles);
    }
  }

  private clearPreferredExcludedCarers(): void {
    const cells = document.querySelectorAll('.dhx_matrix_scell');
    cells.forEach(cell => {
      cell.classList.remove('preferred-carer', 'excluded-carer');
    });
  }

  private highlightPreferredExcludedCarers(task: TaskItem): void {
    this.clearPreferredExcludedCarers();
    
    const cells = document.querySelectorAll('.dhx_timeline_label_row');
    cells.forEach(row => {
      const carerId = row.getAttribute('data-row-id');
      if (carerId) {
        if (task.preferred_carers.includes(carerId)) {
          const cell = row.querySelector('.dhx_matrix_scell');
          if (cell) {
            cell.classList.add('preferred-carer');
          }
        }
        if (task.excluded_carers.includes(carerId)) {
          const cell = row.querySelector('.dhx_matrix_scell');
          if (cell) {
            cell.classList.add('excluded-carer');
          }
        }
      }
    });
  }

  setupSchedulerEvents(scheduler: SchedulerStatic): void {
    // Add styles for preferred/excluded carers
    this.addPreferredExcludedStyles();

    // Handle drag start
    scheduler.attachEvent("onBeforeDrag", (id: string) => {
      const task = scheduler.getEvent(id) as TaskItem;
      this.highlightPreferredExcludedCarers(task);
      return true;
    });

    // Handle drag end
    scheduler.attachEvent("onDragEnd", () => {
      this.clearPreferredExcludedCarers();
    });

    // Handle event validation
    scheduler.attachEvent("onBeforeEventChanged", (ev, e, is_new, original) => {
      if (new Date(ev.start_date) < new Date()) {
        this.helperService.openSnackBar('Cannot change past events', 'Dismiss');
        return false;
      }

      const isMoveAllowedResult = this.taskService.isMoveAllowed(ev.section_key, ev.section_label, ev.carer_string, ev.css_custom);

      if (isMoveAllowedResult.disallowReason) {
        this.helperService.openSnackBar(isMoveAllowedResult.disallowReason || 'This action is not allowed', 'Dismiss');
        return false;
      }

      const isTimingChange = !this.helperService.areDatesEqual(ev.start_date, new Date(original.original_start_date));
      const isAllocationChange = this.taskService.hasPendingAllocationChange(ev);

      ev.pending_allocation_change = isAllocationChange;
      ev.pending_timing_change = isTimingChange;
      ev.pending_change = ev.pending_timing_change || ev.pending_allocation_change;
      ev.css_custom = isMoveAllowedResult.classString || '';

      scheduler.updateEvent(ev.id);

      this.changeManagementService.updatePendingChangesCount(scheduler.getEvents());
      return true;
    });

    // Handle folder toggle events
    scheduler.attachEvent("onAfterFolderToggle", () => {
      setTimeout(() => {
        this.dragDropService.setupDragHandlersForVisibleCells();
      }, 100);
    });

    scheduler.attachEvent("onScaleAdd", () => {
      setTimeout(() => {
        this.dragDropService.setupDragHandlersForVisibleCells();
      }, 100);
    });

    scheduler.attachEvent("onBeforeEventDelete", (id: string) => {
      console.log('Event replaced');
    });
  }

  show_minical(scheduler: SchedulerStatic) {
    if (!scheduler) return;

    if (scheduler.isCalendarVisible()) {
      scheduler.destroyCalendar();
    } else {
      scheduler.renderCalendar({
        position: "dhx_minical_icon",
        date: scheduler.getState().date,
        navigation: true,
        handler: (date: Date) => {
          scheduler.setCurrentView(date, 'timeline');
          scheduler.destroyCalendar();
        }
      });
    }
  }
}
