<h2 mat-dialog-title>Event Details</h2>
<mat-dialog-content>
  <p><strong>Client: </strong>{{ data.event.client_name }}</p>
  <p><strong>Activity: </strong>{{ data.event.text }}</p>
  <p><strong>Time: </strong><span [innerHTML]="formattedTime"></span></p>
  <mat-form-field>
    <mat-label>Key Staff</mat-label>
    <mat-select [(ngModel)]="selectedKeyStaff">
      <mat-option *ngFor="let staff of staffOptions" [value]="staff.key">
        {{ staff.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button color="warn" *ngIf="hasPendingChange" (click)="onRevert()">Revert All Changes</button>
  <button mat-button color="primary" (click)="onSave()" [disabled]="selectedKeyStaff === null">Save</button>
</mat-dialog-actions>
