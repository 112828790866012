<div class="publish-container">
    
    <div class="spinner-container">
        <div *ngIf="publishing" class="progress-spinner">
            <mat-progress-spinner mode="indeterminate" [diameter]="40" strokeWidth="4"></mat-progress-spinner>
        </div>
        <div class="pending-count">{{ pendingChangesCount }}</div>        
    </div>
    
    <button mat-raised-button
        [color]="publishing ? 'warn' : (simultaneousStartsCount > 0 ? 'accent' : 'primary')"
        (click)="publishing ? cancelPublish() : publish()"
        [disabled]="pendingChangesCount === 0 || simultaneousStartsCount > 0"
        [matTooltip]="getButtonTooltip()"
        [matTooltipShowDelay]="200"
        class="publish-button">
        {{ publishing ? 'Cancel' : (simultaneousStartsCount > 0 ? 'Simultaneous Starts (' + simultaneousStartsCount + ')' : 'Publish') }}
    </button>
</div>
