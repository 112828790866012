import { Component, OnInit } from '@angular/core';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PermissionsService } from '../services/permissions.service';
import { AppComponent } from '../app.component';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    loginDisplay = false;

    // Keep these as Observables so the HTML can async-pipe them.
    hasSchedulerAccess$ = of(false);
    schedulerButtonText$ = of('');
    showAuthMessage$ = of(false);

    constructor(
        private permissionsService: PermissionsService,
        private appComponent: AppComponent
    ) {
    }

    ngOnInit() {
        this.setLoginDisplay();

        // Initialize observables with default values
        this.setupSchedulerObservables();

        // If we are already logged in, force a re-initialization of user session
        // so that the PermissionsService can fetch (or re-fetch) the user's roles/features.
        if (this.loginDisplay) {
            this.permissionsService.initializeUserSession().subscribe({
                next: () => {
                    // After it's reinitialized, set up your Observables again
                    this.setupSchedulerObservables();
                },
                error: (err) => {
                    // Still set them up in case you want to degrade gracefully
                    this.setupSchedulerObservables();
                }
            });
        }
    }

    setLoginDisplay() {
        // Because HomeComponent references appComponent.loginDisplay
        this.loginDisplay = this.appComponent.loginDisplay;
    }

    /**
     * Setup the Observables used for conditionally showing the "Schedule" or "Reallocate" button.
     */
    private setupSchedulerObservables() {
        this.hasSchedulerAccess$ = this.permissionsService.hasAnyFeatureAccess(['roster', 'reallocate']);
        
        // Only show auth message after session initialization and when user is logged in but has no access
        this.showAuthMessage$ = combineLatest([
            of(this.loginDisplay),
            this.hasSchedulerAccess$
        ]).pipe(
            map(([isLoggedIn, hasAccess]) => isLoggedIn && !hasAccess)
        );

        this.schedulerButtonText$ = combineLatest([
            this.permissionsService.hasFeatureAccess('roster'),
            this.permissionsService.hasFeatureAccess('reallocate')
        ]).pipe(
            map(([canRoster, canReallocate]) => {
                if (canRoster) return 'Schedule';
                if (canReallocate) return 'Reallocate';
                return '';
            })
        );
    }

    loginRedirect() {
        this.appComponent.loginRedirect();
    }

    logout() {
        this.appComponent.logout();
    }
}
