import { Component, EventEmitter, Output, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FilterGridDialogComponent } from '../filter-grid-dialog/filter-grid-dialog.component';
import { NotificationDialogComponent } from '../notification-dialog/notification-dialog.component';
import { NotificationService } from '../../services/notification.service';
import { HelperService } from '../../services/helper.service';
import { ThemeService } from '../../services/theme.service';
import { FilterService } from '../../services/filter.service';
import { CarerStateService, ViewState } from '../../services/carer-state.service';
import { CorporateService } from '../../services/corporate.service';
import { Subscription, combineLatest } from 'rxjs';
import {
  TimeOfDay,
  ClientType,
  UserType
} from '../../models/filter.model';
import { RosterService } from '../../services/roster.service';

@Component({
  selector: 'app-roster-sidenav',
  templateUrl: './roster-sidenav.component.html',
  styleUrls: ['./roster-sidenav.component.scss']
})
export class RosterSidenavComponent implements OnInit, OnDestroy {
  // Expose enums to template
  readonly TimeOfDay = TimeOfDay;
  readonly ClientType = ClientType;

  isDarkMode = false;
  isUAT = false;
  autoAllocateEnabled = true;  // Will be updated from filter state
  isWideColumns = false;  // Default to normal width
  @Output() filterChanged = new EventEmitter<Record<string, boolean>>();
  @Output() dataRefreshRequested = new EventEmitter<string>();

  filters: Record<string, boolean> = {
    // Staff Type
    staff: true,
    shifts: true
  };

  // Track states separately from other filters
  clientType: ClientType = ClientType.BOTH;
  timeOfDay: TimeOfDay = TimeOfDay.ANY;
  userType: UserType = UserType.BOTH;
  showTimeMenu = false;
  showClientMenu = false;
  showCarerStateMenu = false;
  timeButtonTop = 0;
  clientButtonTop = 0;
  carerStateButtonTop = 0;

  carerStateOptions: { icon: string, tooltip: string, value: ViewState }[] = [
    { icon: 'visibility', tooltip: 'Default View', value: 'default' },
    { icon: 'visibility_off', tooltip: 'Show Hidden', value: 'showHidden' }
  ];

  viewState: ViewState = 'default';
  private clickTimeout: ReturnType<typeof setTimeout> | null = null;
  private readonly DOUBLE_CLICK_DELAY = 300;

  timeOptions = [
    { icon: 'wb_sunny', tooltip: 'Day Shift Only', value: TimeOfDay.DAY },
    { icon: 'schedule', tooltip: 'Any Time', value: TimeOfDay.ANY },
    { icon: 'nights_stay', tooltip: 'PM & Night Shift', value: TimeOfDay.NIGHT }
  ];

  clientOptions = [
    { icon: 'accessible', tooltip: 'HPAC Only', value: ClientType.HPAC_ONLY },
    { icon: 'volunteer_activism', tooltip: 'All Services', value: ClientType.BOTH },
    { icon: 'accessibility_new', tooltip: 'Independent Living Only', value: ClientType.INDEPENDENT_ONLY }
  ];

  private labelsSub?: Subscription;
  private hpacLabel = 'HPAC';
  private independentLabel = 'Independent Living';

  // Notification properties
  unreadCount = 0;
  hasWarningOrCritical = false;
  hasCritical = false;
  hideIconsForShortEvents: boolean = true;
  private notificationSubs: Subscription[] = [];
  private filterStateSub?: Subscription;

  constructor(
    private helperService: HelperService,
    private themeService: ThemeService,
    private filterService: FilterService,
    private carerStateService: CarerStateService,
    private dialog: MatDialog,
    private corporateService: CorporateService,
    private rosterService: RosterService,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef
  ) {
    this.themeService.isDark$.subscribe(isDark => {
      this.isDarkMode = isDark;
    });
  }

  openFilterGridDialog(): void {
    this.dialog.open(FilterGridDialogComponent, {
      width: '600px',
      data: { currentClientType: this.clientType, currentTimeOfDay: this.timeOfDay }
    });
  }

  ngOnInit(): void {
    const savedEnv = this.helperService.getStoredValue('ecase_env', 'prod');
    this.isUAT = savedEnv === 'uat';

    // Subscribe to filter changes and initialize with current state
    this.filterStateSub = this.filterService.getFilterState().subscribe(state => {
      if (state) {
        this.timeOfDay = state.timeOfDay || TimeOfDay.ANY;
        this.clientType = state.clientType || ClientType.BOTH;
        this.userType = state.userType || UserType.BOTH;
        this.autoAllocateEnabled = state.autoAllocateEnabled ?? true;
        this.emitFilterState();
        this.cd.detectChanges();
      }
    });

    // Subscribe to all notification states at once
    this.notificationSubs.push(
      combineLatest([
        this.notificationService.getUnreadCount(),
        this.notificationService.getHasWarningOrCritical(),
        this.notificationService.getHasCriticalMessages()
      ]).subscribe(([count, hasWarning, hasCritical]) => {
        this.unreadCount = count;
        this.hasWarningOrCritical = hasWarning;
        this.hasCritical = hasCritical;
      })
    );

    // Load column width preference
    const savedWidth = localStorage.getItem('roster_column_width');
    this.isWideColumns = savedWidth === 'wide';
    this.hideIconsForShortEvents = this.helperService.getStoredValue('hideIconsForShortEvents', true);

    // Initialize view state from carer state service
    this.viewState = this.carerStateService.getCurrentViewState();

    // Fetch corporate labels
    this.labelsSub = this.corporateService.getLabels().subscribe(labels => {
      this.hpacLabel = labels.hpac_label;
      this.independentLabel = labels.independent_label;

      // Update client options with new labels
      this.clientOptions = [
        { icon: 'accessible', tooltip: `${this.hpacLabel} Only`, value: ClientType.HPAC_ONLY },
        { icon: 'volunteer_activism', tooltip: 'All Services', value: ClientType.BOTH },
        { icon: 'accessibility_new', tooltip: `${this.independentLabel} Only`, value: ClientType.INDEPENDENT_ONLY }
      ];
    });
  }

  ngOnDestroy(): void {
    if (this.labelsSub) {
      this.labelsSub.unsubscribe();
    }
    if (this.filterStateSub) {
      this.filterStateSub.unsubscribe();
    }
    this.notificationSubs.forEach(sub => sub.unsubscribe());
  }

  onButtonMouseEnter(event: MouseEvent, menuType: 'time' | 'client' | 'carerState'): void {
    const buttonRect = (event.target as HTMLElement).getBoundingClientRect();
    // Convert pixels to rem (16px = 1rem)
    const topInRem = buttonRect.top / 16;
    if (menuType === 'time') {
      this.timeButtonTop = topInRem;
      this.showTimeMenu = true;
    } else if (menuType === 'client') {
      this.clientButtonTop = topInRem;
      this.showClientMenu = true;
    } else if (menuType === 'carerState') {
      this.carerStateButtonTop = topInRem;
      this.showCarerStateMenu = true;
    }
  }

  onButtonMouseLeave(menuType: 'time' | 'client' | 'carerState'): void {
    if (menuType === 'time') {
      this.showTimeMenu = false;
    } else if (menuType === 'client') {
      this.showClientMenu = false;
    } else if (menuType === 'carerState') {
      this.showCarerStateMenu = false;
    }
  }

  onTimeOptionSelected(value: TimeOfDay): void {
    this.timeOfDay = value;
    this.filterService.updateFilter({ timeOfDay: value });
    this.emitFilterState();
    this.showTimeMenu = false;
  }

  onClientOptionSelected(value: ClientType): void {
    this.clientType = value;
    this.filterService.updateFilter({ clientType: value });
    this.emitFilterState();
    this.showClientMenu = false;
  }

  getClientTypeIcon(): string {
    switch (this.clientType) {
      case ClientType.BOTH:
        return 'volunteer_activism';  // Icon showing both types
      case ClientType.HPAC_ONLY:
        return 'accessible';  // Wheelchair icon
      case ClientType.INDEPENDENT_ONLY:
        return 'accessibility_new';  // Standing person icon
      default:
        return 'volunteer_activism';  // Default fallback
    }
  }

  getTimeOfDayIcon(): string {
    switch (this.timeOfDay) {
      case TimeOfDay.ANY:
        return 'schedule';  // Clock icon for any time
      case TimeOfDay.DAY:
        return 'wb_sunny';  // Sun icon for day shift
      case TimeOfDay.NIGHT:
        return 'nights_stay';  // Moon icon for night shift
      default:
        return 'schedule';  // Default fallback
    }
  }

  getClientTypeTooltip(): string {
    switch (this.clientType) {
      case ClientType.BOTH:
        return 'All Clients';
      case ClientType.HPAC_ONLY:
        return `${this.hpacLabel} Only`;
      case ClientType.INDEPENDENT_ONLY:
        return `${this.independentLabel} Only`;
      default:
        return 'All Clients';  // Default fallback
    }
  }

  getTimeOfDayTooltip(): string {
    switch (this.timeOfDay) {
      case TimeOfDay.ANY:
        return 'Any Time';
      case TimeOfDay.DAY:
        return 'Day Shift';
      case TimeOfDay.NIGHT:
        return 'PM & Night Shift';
      default:
        return 'Any Time';  // Default fallback
    }
  }

  handleUserIconClick(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    if (this.clickTimeout === null) {
      this.clickTimeout = setTimeout(() => {
        this.handleSingleClick();
        this.clickTimeout = null;
      }, this.DOUBLE_CLICK_DELAY);
    } else {
      clearTimeout(this.clickTimeout);
      this.clickTimeout = null;
      this.handleDoubleClick();
    }
  }

  private handleSingleClick(): void {
    // Cycle between default and showHidden
    this.viewState = this.viewState === 'showHidden' ? 'default' : 'showHidden';
    this.carerStateService.setViewState(this.viewState);
    this.emitFilterState();
  }

  private handleDoubleClick(): void {
    // Reset to default view on double click
    this.viewState = 'default';
    this.carerStateService.setViewState(this.viewState);
    this.emitFilterState();
  }

  onCarerStateOptionSelected(value: ViewState): void {
    this.viewState = value;
    this.carerStateService.setViewState(value);
    this.emitFilterState();
    this.showCarerStateMenu = false;
  }

  getCarerIconState(): { icon: string, classes: string } {
    switch (this.viewState) {
      case 'showHidden':
        return { icon: 'visibility_off', classes: 'active' };
      default:
        return { icon: 'visibility', classes: '' };
    }
  }

  getUserTypeIcon(): string {
    switch (this.userType) {
      case UserType.BOTH:
        return 'group';  // Icon showing both types
      case UserType.STAFF:
        return 'person';  // Staff icon
      case UserType.SHIFTS:
        return 'view_timeline';  // Shifts icon
      default:
        return 'group';  // Default fallback
    }
  }

  getUserTypeTooltip(): string {
    switch (this.userType) {
      case UserType.BOTH:
        return 'All Users';
      case UserType.STAFF:
        return 'Staff Only';
      case UserType.SHIFTS:
        return 'Shifts Only';
      default:
        return 'All Users';  // Default fallback
    }
  }

  private emitFilterState(): void {
    // Convert states to filter state
    const filterState = {
      // Client type filters
      hpac: this.clientType === ClientType.BOTH || this.clientType === ClientType.HPAC_ONLY,
      independent: this.clientType === ClientType.BOTH || this.clientType === ClientType.INDEPENDENT_ONLY,
      // Time of day filters
      am: this.timeOfDay === TimeOfDay.ANY || this.timeOfDay === TimeOfDay.DAY,
      pm: this.timeOfDay === TimeOfDay.ANY || this.timeOfDay === TimeOfDay.NIGHT,
      // User type filters
      staff: this.userType === UserType.BOTH || this.userType === UserType.STAFF,
      shifts: this.userType === UserType.BOTH || this.userType === UserType.SHIFTS
    };
    this.filterChanged.emit(filterState);
  }

  toggleEnvironment(): void {
    this.isUAT = !this.isUAT;
    const newEnv = this.isUAT ? 'uat' : 'prod';
    this.helperService.storeValue('ecase_env', newEnv);
    localStorage.setItem('env_change_timestamp', Date.now().toString());
    window.location.reload();
  }

  toggleTheme(): void {
    this.themeService.toggleTheme();
  }

  toggleColumnWidth(): void {
    this.isWideColumns = !this.isWideColumns;
    localStorage.setItem('roster_column_width', this.isWideColumns ? 'wide' : 'default');
    this.rosterService.setColumnWidth(this.isWideColumns ? 'wide' : 'default');
  }

  toggleAutoAllocate(): void {
    this.autoAllocateEnabled = !this.autoAllocateEnabled;
    this.notificationService.clearMessages();
    this.filterService.updateFilter({ autoAllocateEnabled: this.autoAllocateEnabled });
    this.rosterService.updateAutoAllocation();
  }

  toggleHideIconsSetting(): void {
    this.hideIconsForShortEvents = !this.hideIconsForShortEvents;
    this.helperService.storeValue('hideIconsForShortEvents', this.hideIconsForShortEvents);
  }

  openLegend(): void {
    const img = new Image();
    img.src = 'assets/legend.png';
    const w = window.open('');
    if (w) {
      w.document.write(img.outerHTML);
      w.document.title = 'Legend';
    }
  }

  refreshData(type: 'carePlans' | 'staffDetails' | 'roles' | 'clients'): void {
    this.dataRefreshRequested.emit(type);
  }

  openNotifications(): void {
    this.dialog.open(NotificationDialogComponent, {
      width: '800px',
      maxHeight: '90vh'
    });
  }

  refreshAllDataInProgress: boolean = false;
  refreshProfilePhotosInProgress: boolean = false;

  refreshAllData(): void {
    if (this.refreshAllDataInProgress) {
      return;
    }
    this.refreshAllDataInProgress = true;
    this.corporateService.refreshMetaCache(this.isUAT ? 'uat' : 'prod').subscribe({
      next: (resp) => {
        this.helperService.openSnackBar(resp.message || 'Meta cache refreshed successfully', 'Close');
        this.refreshAllDataInProgress = false;
      },
      error: (err) => {
        this.helperService.openSnackBar('Error refreshing meta cache', 'Close');
        this.refreshAllDataInProgress = false;
      }
    });
  }

  refreshProfilePhotos(): void {
    if (this.refreshProfilePhotosInProgress) {
      return;
    }
    this.refreshProfilePhotosInProgress = true;
    this.corporateService.refreshImages(this.isUAT ? 'uat' : 'prod').subscribe({
      next: (resp) => {
        this.helperService.openSnackBar(resp.message || 'Profile photos refreshed successfully', 'Close');
        this.refreshProfilePhotosInProgress = false;
      },
      error: (err) => {
        this.helperService.openSnackBar('Error refreshing profile photos', 'Close');
        this.refreshProfilePhotosInProgress = false;
      }
    });
  }

}
