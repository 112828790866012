import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TaskItem } from '../models/task.model';
import { PublishProgressDialogComponent, PublishStep } from '../components/publish/publish-progress-dialog.component';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PublishProgressService {
  constructor(private dialog: MatDialog) { }

  /**
   * Opens a progress dialog and returns the dialog component and reference
   */
  openProgressDialog(
    nonConflictCount: number, 
    conflictCount: number
  ): { 
    progressDialog: PublishProgressDialogComponent, 
    dialogRef: any 
  } {
    const dialogRef = this.dialog.open(PublishProgressDialogComponent, {
      width: '650px',
      disableClose: true,
      autoFocus: false, // Prevent auto-focusing elements to avoid ARIA issues
      restoreFocus: false, // Don't restore focus to the element that opened the dialog
      data: {
        title: 'Publishing Changes',
        pendingCount: nonConflictCount,
        conflictCount: conflictCount
      }
    });
    
    return { 
      progressDialog: dialogRef.componentInstance, 
      dialogRef 
    };
  }

  /**
   * Opens an error dialog with the given message
   */
  openErrorDialog(errorMessage: string): Observable<any> {
    const dialogRef = this.dialog.open(PublishProgressDialogComponent, {
      width: '650px',
      disableClose: true,
      autoFocus: false,
      restoreFocus: false,
      data: {
        title: 'Cannot Publish Changes',
        pendingCount: 0,
        conflictCount: 0
      }
    });
    
    dialogRef.componentInstance.setError(errorMessage);
    return of([]);
  }

  /**
   * Adds an event to the progress dialog with formatted display text
   */
  addEventToProgressDialog(
    event: TaskItem, 
    progressDialog: PublishProgressDialogComponent, 
    isConflictResolution: boolean,
    groupId?: string
  ): void {
    // Format the start time for display
    const startTime = new Date(event.start_date);
    const formattedTime = startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    
    // Create a more meaningful description
    const clientName = event.client_name || 'Unknown Client';
    let stepText = `${formattedTime}: ${clientName}`;
    
    // Only add carer name if it's not "Unknown Carer"
    if (event.carer_name && event.carer_name !== 'Unknown Carer') {
      stepText += ` → ${event.carer_name}`;
    }
    
    progressDialog.addStep({
      id: event.id,
      text: stepText,
      completed: false,
      success: false,
      isUnallocation: false,
      isConflictResolution,
      groupId
    });
  }

  /**
   * Sets final status in the progress dialog based on the results
   */
  finishProgress(
    progressDialog: PublishProgressDialogComponent, 
    success: boolean, 
    message?: string
  ): void {
    if (success) {
      progressDialog.setSuccess(message || 'All changes published successfully.');
    } else {
      // Check if any steps failed
      const failedSteps = progressDialog.steps.filter(step => step.completed && !step.success);
      
      if (failedSteps.length > 0) {
        progressDialog.setWarning(
          message || `${failedSteps.length} of ${progressDialog.steps.length} changes failed to publish.`
        );
      } else if (message) {
        progressDialog.setError(message);
      }
    }
  }
}
