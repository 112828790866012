<h2 mat-dialog-title>Reallocate Services</h2>
<mat-dialog-content>
  <div class="dialog-content">
    <p>Reallocate {{serviceCount}} services from {{sourceStaffName}}</p>
    
    <mat-form-field appearance="fill" class="staff-select">
      <mat-label>Select Staff Member</mat-label>
      <mat-select [(ngModel)]="selectedStaff">
        <mat-option *ngFor="let staff of staffOptions" [value]="staff.key">
          {{staff.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-raised-button color="primary" (click)="onConfirm()" [disabled]="!selectedStaff">
    Reallocate
  </button>
</mat-dialog-actions>
