import { Component, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { HelperService } from 'src/app/services/helper.service';
import { RosterService } from 'src/app/services/roster.service';

@Component({
  selector: 'app-publish',
  templateUrl: './publish.component.html',
  styleUrls: ['./publish.component.css']
})
export class PublishComponent {
  @Input() set pendingChangesCount(value: number) {
    this._pendingChangesCount = value;
    this.cdr.detectChanges();
  }
  get pendingChangesCount(): number {
    return this._pendingChangesCount;
  }
  private _pendingChangesCount = 0;

  @Input() set conflictsCount(value: number) {
    this._conflictsCount = value;
    this.cdr.detectChanges();
  }
  get conflictsCount(): number {
    return this._conflictsCount;
  }
  private _conflictsCount = 0;

  @Input() set simultaneousStartsCount(value: number) {
    this._simultaneousStartsCount = value;
    this.cdr.detectChanges();
  }
  get simultaneousStartsCount(): number {
    return this._simultaneousStartsCount;
  }
  private _simultaneousStartsCount = 0;

  @Output() publishChanges = new EventEmitter<void>();
  @Output() cancelPublishing = new EventEmitter<void>();

  publishing = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private helperService: HelperService,
    private rosterService: RosterService
  ) {

  }

  publish() {
    this.rosterService.checkForUnpublishableChanges();
    this.publishing = true;
    this.publishChanges.emit();
  }

  cancelPublish() {
    this.cancelPublishing.emit();
    this.publishing = false;
  }

  onPublishComplete() {
    this.publishing = false;
    this.helperService.openSnackBar('Changes published successfully', 'Close');
  }

  /**
   * Get the tooltip text for the publish button based on its state
   */
  getButtonTooltip(): string {
    if (this.simultaneousStartsCount > 0) {
      const eventText = this.simultaneousStartsCount === 1 ? 'event' : 'events';
      return `Cannot publish: ${this.simultaneousStartsCount} ${eventText} with simultaneous starts detected. A simultaneous start occurs when a carer has two events at the same time.`;
    } else if (this.pendingChangesCount === 0) {
      return 'No changes to publish';
    } else {
      return 'Publish pending changes';
    }
  }
}
