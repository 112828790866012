import { Component, ElementRef, OnInit, OnDestroy, ViewChild, ViewEncapsulation, AfterViewInit } from "@angular/core";
import { RosterService } from "../../services/roster.service";
import { ChangeManagementService } from "../../services/change-management.service";
import { DragDropService } from "../../services/drag-drop.service";
import { Subscription } from 'rxjs';
import { PublishComponent } from "src/app/components/publish/publish.component";

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-roster',
    templateUrl: './roster.component.html',
    styleUrls: ['./roster.component.css', './scheduler.css', './rosterView.css', './folders.scss', 'labels.scss']
})
export class RosterComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild("scheduler_here", { static: false }) schedulerContainer!: ElementRef;
    @ViewChild(PublishComponent) publishComponent!: PublishComponent;
    isLoading = true;
    pendingChangesCount = 0;
    conflictsCount = 0;
    simultaneousStartsCount = 0;
    private isLoadingSubscription!: Subscription;
    private publishCompleteSubscription!: Subscription;
    private conflictsCountSubscription!: Subscription;
    private pendingChangesCountSubscription!: Subscription;
    private simultaneousStartsCountSubscription!: Subscription;

    constructor(
        private rosterService: RosterService,
        private changeManagementService: ChangeManagementService,
        private dragDropService: DragDropService,
        private elementRef: ElementRef        
    ) { }

    ngOnInit(): void {
        this.isLoadingSubscription = this.rosterService.isLoading$.subscribe((isLoading: boolean) => {
            this.isLoading = isLoading;
        });

        this.publishCompleteSubscription = this.changeManagementService.publishComplete$.subscribe((complete: boolean) => {
            if (complete && this.publishComponent) {
                this.publishComponent.onPublishComplete();
            }
        });

        this.pendingChangesCountSubscription = this.changeManagementService.pendingChangesCount$.subscribe(count => {
            this.pendingChangesCount = count;
        });

        this.conflictsCountSubscription = this.changeManagementService.conflictsCount$.subscribe(count => {
            this.conflictsCount = count;
        });

        this.simultaneousStartsCountSubscription = this.changeManagementService.simultaneousStartsCount$.subscribe(count => {
            this.simultaneousStartsCount = count;
        });
        
    }

    ngAfterViewInit(): void {
        this.initializeScheduler();
    }

    initializeScheduler(): void {
        if (this.schedulerContainer) {
            this.loadData();
        }
    }

    loadData(): void {
        this.rosterService.setSchedulerContainer(this.schedulerContainer);
        this.rosterService.initScheduler();
        this.dragDropService.initializeDragAndDrop(this.elementRef);
        setTimeout(() => {
            this.isLoading = false;
        });
    }

    show_minical() {
        this.rosterService.show_minical();
    }

    publishPendingChanges() {
        this.rosterService.publishPendingChanges();
    }

    cancelPendingChanges() {
        this.rosterService.cancelPublishing();
    }


    ngOnDestroy(): void {
        const scheduler = this.rosterService.scheduler;
        if (scheduler) {
            scheduler.destructor();
        }
        if (this.isLoadingSubscription) {
            this.isLoadingSubscription.unsubscribe();
        }
        if (this.publishCompleteSubscription) {
            this.publishCompleteSubscription.unsubscribe();
        }
        if (this.pendingChangesCountSubscription) {
            this.pendingChangesCountSubscription.unsubscribe();
        }
        if (this.conflictsCountSubscription) {
            this.conflictsCountSubscription.unsubscribe();
        }
        if (this.simultaneousStartsCountSubscription) {
            this.simultaneousStartsCountSubscription.unsubscribe();
        }
    }
}
