import { Injectable } from '@angular/core';
import { SchedulerStatic } from "@dhx/scheduler";
import { HelperService } from './helper.service';
import { TaskItem } from '../models/task.model';

@Injectable({
  providedIn: 'root'
})
export class SchedulerEventsService {
  private _currentScheduler?: SchedulerStatic;

  constructor(private helperService: HelperService) { }

  /**
   * Sets the current scheduler instance
   * This should be called by RosterService when the scheduler is initialized or updated
   */
  setCurrentScheduler(scheduler: SchedulerStatic | undefined): void {
    this._currentScheduler = scheduler;
  }

  /**
   * Gets the current scheduler instance
   */
  getCurrentScheduler(): SchedulerStatic | undefined {
    return this._currentScheduler;
  }

  /**
   * Gets all events from the current scheduler
   */
  getAllEvents(): any[] {
    if (!this._currentScheduler) {
      console.warn('Scheduler not initialized when trying to get events');
      return [];
    }
    return this._currentScheduler.getEvents();
  }

  /**
   * Gets events matching the specified carer and start date
   */
  getMatchingEvents(carerString: string, startDate: Date): any[] {
    if (!this._currentScheduler) {
      console.warn('Scheduler not initialized when trying to get matching events');
      return [];
    }

    return this._currentScheduler.getEvents().filter(event =>
      String(event.carer_string) === String(carerString) &&
      this.helperService.areDatesEqual(event.start_date, startDate)
    );
  }

  /**
   * Checks if there are any events matching the specified carer and start date
   * excluding the specified event ID
   */
  hasMatchingEvents(carerString: string, startDate: Date, excludeEventId?: string): boolean {
    const matchingEvents = this.getMatchingEvents(carerString, startDate);
    
    if (excludeEventId) {
      return matchingEvents.some(event => String(event.id) !== String(excludeEventId));
    }
    
    return matchingEvents.length > 0;
  }

  /**
   * Checks if there is a conflict for the specified pending event.
   * A conflict occurs when another pending event is being moved to this event's original time slot.
   * 
   * @param eventId The ID of the pending event to check.
   * @returns The ID of the conflicting event if found; otherwise, null.
   */
  hasConflict(eventId: string): string | null {
    if (!this._currentScheduler) {
      console.warn('Scheduler not initialized while checking for conflict');
      return null;
    }
    
    // Find the event we are checking
    const thisEvent = this._currentScheduler.getEvent(eventId);
    if (!thisEvent || !thisEvent.pending_change) {
      return null;
    }
    
    // Get the target details (where this event is being moved to)
    const targetCarer = thisEvent.carer_string;
    const targetStartDate = new Date(thisEvent.start_date);
    
    // Find events that match the target carer and start date
    const matchingEvents = this._currentScheduler.getEvents().filter(event =>
      String(event.id) !== String(eventId) && // Not the same event
      String(event.original_carer_string) === String(targetCarer) && // Original carer matches target carer
      this.helperService.areDatesEqual(new Date(event.original_start_date || event.start_date), targetStartDate) // Original date matches target date
    );
    
    return matchingEvents.length > 0 ? matchingEvents[0].id : null;
  }
}
