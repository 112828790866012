import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PermissionsService } from '../../services/permissions.service';
import { UserSession } from '../../models/permissions.model';

@Component({
    selector: 'app-user-details',
    templateUrl: './user-details.component.html',
    styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit, OnDestroy {
    userSession: UserSession | null = null;
    private destroy$ = new Subject<void>();

    constructor(private permissionsService: PermissionsService) {
    }

    ngOnInit() {
        this.permissionsService.userSession$.pipe(
            takeUntil(this.destroy$)
        ).subscribe(session => {
            this.userSession = session;
        });

        this.permissionsService.initializeUserSession().pipe(
            takeUntil(this.destroy$)
        ).subscribe({
            next: (session) => {},
            error: (error) => {},
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
