import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class IconService {
    private readonly svgContent = {
        client: `<?xml version="1.0" encoding="utf-8"?>
        <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M26.9672 9.00181C26.9672 11.2119 25.1763 13.0036 22.9671 13.0036C20.7579 13.0036 18.967 11.2119 18.967 9.00181C18.967 6.79167 20.7579 5 22.9671 5C25.1763 5 26.9672 6.79167 26.9672 9.00181ZM18.6717 11.5049H17.5718C16.6728 11.5049 13.8967 13.1395 13.8967 16.3153C13.8967 20.5291 16.6728 22.2402 17.5718 22.2402V41.868C17.5718 42.767 18.3074 43.5026 19.2064 43.5026L19.216 43.5025L19.2256 43.5026C20.1246 43.5026 20.8602 42.767 20.8602 41.868V30.216H25.1101V41.868C25.1101 42.767 25.8457 43.5026 26.7447 43.5026L26.7543 43.5025L26.7639 43.5026C27.6629 43.5026 28.3985 42.767 28.3985 41.868V17.2311L30.5837 23.4942C30.8566 24.2763 31.712 24.6892 32.4942 24.4163C33.2763 24.1434 33.6892 23.288 33.4163 22.5058L30.1632 13.1823C29.8129 12.1784 28.866 11.5059 27.8027 11.5059H27.3277C26.4623 12.9971 24.8483 14 23 14C21.1513 14 19.537 12.9967 18.6717 11.5049ZM32 25C30.3431 25 29 26.3431 29 28V28.6667C29 29.219 29.4477 29.6667 30 29.6667C30.5523 29.6667 31 29.219 31 28.6667V28C31 27.4477 31.4477 27 32 27C32.5523 27 33 27.4477 33 28V42C33 42.5523 33.4477 43 34 43C34.5523 43 35 42.5523 35 42V28C35 26.3431 33.6569 25 32 25Z" />
        </svg>`,
        clientf: `<?xml version="1.0" encoding="utf-8"?>
        <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5718 12.5049H18.6717C19.537 13.9967 21.1513 15 23 15C24.8483 15 26.4623 13.9971 27.3277 12.5059H27.8657C28.8503 12.5059 29.7432 13.0837 30.1464 13.9819L34.3684 23.3856C34.7077 24.1414 34.3701 25.0291 33.6144 25.3684C32.8586 25.7077 31.9709 25.3701 31.6316 24.6144L28.3793 17.3704V22.8381L31.0454 34.215H28.3793V42.868C28.3793 43.767 27.6437 44.5026 26.7447 44.5026C25.8457 44.5026 25.1101 43.767 25.1101 42.868V34.216H20.841V42.868C20.841 43.767 20.1054 44.5026 19.2064 44.5026C18.3074 44.5026 17.5718 43.767 17.5718 42.868V34.215H14.9932L17.5651 23.2402C16.659 23.2317 13.8967 21.5186 13.8967 17.3153C13.8967 14.1395 16.6728 12.5049 17.5718 12.5049ZM34 26C32.3431 26 31 27.3431 31 29V29.6667C31 30.219 31.4477 30.6667 32 30.6667C32.5523 30.6667 33 30.219 33 29.6667V29C33 28.4477 33.4477 28 34 28C34.5523 28 35 28.4477 35 29V43C35 43.5523 35.4477 44 36 44C36.5523 44 37 43.5523 37 43V29C37 27.3431 35.6569 26 34 26Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.9329 6.51575C24.9767 6.35121 25 6.17833 25 6C25 4.89543 24.1046 4 23 4C21.8954 4 21 4.89543 21 6C21 6.16683 21.0204 6.3289 21.0589 6.48383C19.8128 7.16174 18.967 8.48296 18.967 10.0018C18.967 12.2119 20.7579 14.0036 22.9671 14.0036C25.1763 14.0036 26.9672 12.2119 26.9672 10.0018C26.9672 8.50646 26.1474 7.20267 24.9329 6.51575Z"/>
        </svg>`,
        clockSolid: `<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12 2c-5.51 0-10 4.49-10 10s4.49 10 10 10 10-4.49 10-10-4.49-10-10-10zm4.35 13.57c-.14.24-.39.37-.65.37-.13 0-.26-.03-.38-.11l-3.1-1.85c-.77-.46-1.34-1.47-1.34-2.36v-4.1c0-.41.34-.75.75-.75s.75.34.75.75v4.1c0 .36.3.89.61 1.07l3.1 1.85c.36.21.48.67.26 1.03z" fill="#292d32"/></svg>`,
        clock: `<svg viewBox=".22 .22 13.56 13.56" xmlns="http://www.w3.org/2000/svg"><g><path d="m7 13.781a6.782 6.782 0 1 1 6.781-6.781 6.79 6.79 0 0 1 -6.781 6.781zm0-12.25a5.469 5.469 0 1 0 0 10.939 5.469 5.469 0 0 0 0-10.94z"/><path d="m9.284 9.31-2.94-1.759v-4.051h1.312v3.308l2.31 1.382z"/></g></svg>`,
        service: `<?xml version="1.0" encoding="utf-8"?>
        <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="M28.7,18.8l-1.8,2.9,1.4,1.4,2.9-1.8,1,.4L33,25h2l.8-3.3,1-.4,2.9,1.8,1.4-1.4-1.8-2.9a4.2,4.2,0,0,0,.4-1L43,17V15l-3.3-.8a4.2,4.2,0,0,0-.4-1l1.8-2.9L39.7,8.9l-2.9,1.8-1-.4L35,7H33l-.8,3.3-1,.4L28.3,8.9l-1.4,1.4,1.8,2.9a4.2,4.2,0,0,0-.4,1L25,15v2l3.3.8A4.2,4.2,0,0,0,28.7,18.8ZM34,14a2,2,0,1,1-2,2A2,2,0,0,1,34,14Z"/>
                <path d="M42.2,28.7a5.2,5.2,0,0,0-4-1.1l-9.9,1.8a4.5,4.5,0,0,0-1.4-3.3L19.8,19H5a2,2,0,0,0,0,4H18.2l5.9,5.9a.8.8,0,0,1-1.2,1.2l-3.5-3.5a2,2,0,0,0-2.8,2.8l3.5,3.5a4.5,4.5,0,0,0,3.4,1.4,5.7,5.7,0,0,0,1.8-.3h0l13.6-2.4a1,1,0,0,1,.8.2.9.9,0,0,1,.3.7,1,1,0,0,1-.8,1L20.6,36.9,9.7,28H5a2,2,0,0,0,0,4H8.3l11.1,9.1,20.5-3.7a5,5,0,0,0,2.3-8.7Z"/>
            </g>
        </svg>`,
        user: `<svg viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg"><path d="m224 256c70.7 0 128-57.3 128-128s-57.3-128-128-128-128 57.3-128 128 57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7c-74.2 0-134.4 60.2-134.4 134.4v41.6c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"/></svg>`,
        users: `<svg viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg"><path d="m192 256c61.9 0 112-50.1 112-112s-50.1-112-112-112-112 50.1-112 112 50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3c-63.6 0-115.2 51.6-115.2 115.2v28.8c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm211.2-32c53 0 96-43 96-96s-43-96-96-96-96 43-96 96 43 96 96 96zm48 32h-3.8c-13.9 4.8-28.6 8-44.2 8s-30.3-3.2-44.2-8h-3.8c-20.4 0-39.2 5.9-55.7 15.4 24.4 26.3 39.7 61.2 39.7 99.8v38.4c0 2.2-.5 4.3-.6 6.4h176.6c26.5 0 48-21.5 48-48 0-61.9-50.1-112-112-112z"/></svg>`
    };

    constructor() { }

    private getColorizedSvg(svg: string, color: string): string {
        // Remove any existing fill attributes
        let modifiedSvg = svg.replace(/fill="[^"]*"/g, '');

        // Add fill attribute to the first svg tag
        modifiedSvg = modifiedSvg.replace('<svg', `<svg fill="${color}"`);

        return modifiedSvg;
    }

    private getSvgLightGrey(svg: string): string {
        return this.getColorizedSvg(svg, '#c1c1c1');
    }

    get client() {
        return {
            withColor: (color: string) => this.getColorizedSvg(this.svgContent.client, color),
            default: this.getSvgLightGrey(this.svgContent.client)
        };
    }

    get clientf() {
        return {
            withColor: (color: string) => this.getColorizedSvg(this.svgContent.clientf, color),
            default: this.getSvgLightGrey(this.svgContent.clientf)
        };
    }

    get clockSolid() {
        return {
            withColor: (color: string) => this.getColorizedSvg(this.svgContent.clockSolid, color),
            default: this.getSvgLightGrey(this.svgContent.clockSolid)
        };
    }

    get clock() {
        return {
            withColor: (color: string) => this.getColorizedSvg(this.svgContent.clock, color),
            default: this.getSvgLightGrey(this.svgContent.clock)
        };
    }

    get service() {
        return {
            withColor: (color: string) => this.getColorizedSvg(this.svgContent.service, color),
            default: this.getSvgLightGrey(this.svgContent.service)
        };
    }

    get user() {
        return {
            withColor: (color: string) => this.getColorizedSvg(this.svgContent.user, color),
            default: this.getSvgLightGrey(this.svgContent.user)
        };
    }

    get users() {
        return {
            withColor: (color: string) => this.getColorizedSvg(this.svgContent.users, color),
            default: this.getSvgLightGrey(this.svgContent.users)
        };
    }
}
