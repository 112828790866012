// event-bus.service.ts
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventBusService {
  private keyStaffUpdatedSubject = new Subject<{ eventId: string, newKeyStaff: string, staffLabel: string }>();

  keyStaffUpdated$ = this.keyStaffUpdatedSubject.asObservable();

  emitKeyStaffUpdated(eventData: { eventId: string, newKeyStaff: string, staffLabel: string }) {
    this.keyStaffUpdatedSubject.next(eventData);
  }
}
