import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from, forkJoin, Subject, of, concatMap, takeUntil, finalize, tap, map } from 'rxjs';
import { format } from 'date-fns';
import { TaskItem } from '../models/task.model';
import { EventService } from './event.service';
import { FilterService } from './filter.service';
import { NotificationService } from './notification.service';
import { SchedulerEventsService } from './scheduler-events.service';
import { ConflictManagementService } from './conflict-management.service';
import { PublishProgressService } from './publish-progress.service';
import { EventProcessingService } from './event-processing.service';

@Injectable({
  providedIn: 'root'
})
export class ChangeManagementService {
  private _pendingChangesCount = new BehaviorSubject<number>(0);
  private _conflictsCount = new BehaviorSubject<number>(0);
  private _simultaneousStartsCount = new BehaviorSubject<number>(0);
  private _publishComplete = new BehaviorSubject<boolean>(false);
  private cancelPublish = new Subject<void>();

  constructor(
    private eventService: EventService,
    private filterService: FilterService,
    private notificationService: NotificationService,
    private schedulerEventsService: SchedulerEventsService,
    private conflictService: ConflictManagementService,
    private progressService: PublishProgressService,
    private eventProcessingService: EventProcessingService
  ) { }

  get pendingChangesCount$(): Observable<number> {
    return this._pendingChangesCount.asObservable();
  }

  get conflictsCount$(): Observable<number> {
    return this._conflictsCount.asObservable();
  }

  get simultaneousStartsCount$(): Observable<number> {
    return this._simultaneousStartsCount.asObservable();
  }

  get publishComplete$(): Observable<boolean> {
    return this._publishComplete.asObservable();
  }

  updatePendingChangesCount(events: TaskItem[]): void {
    const pendingEvents = events.filter(event => event.pending_change);
    const renderedCarerIds = this.filterService.getRenderedCarerIds();
    const visiblePendingEvents = pendingEvents.filter(event =>
      this.filterService.isTaskVisible(event, renderedCarerIds)
    );
    this._pendingChangesCount.next(visiblePendingEvents.length);
    this.updateConflictsCount(events);
  }

  updateConflictsCount(events: TaskItem[]): void {
    const conflictEvents = events.filter(event => event.pending_change_conflicts_with);
    const renderedCarerIds = this.filterService.getRenderedCarerIds();
    const visibleConflictEvents = conflictEvents.filter(event =>
      this.filterService.isTaskVisible(event, renderedCarerIds)
    );
    this._conflictsCount.next(visibleConflictEvents.length);
  }

  /**
   * Updates the count of events with simultaneous starts (same carer, same start time)
   * This is different from conflicts in the change management system
   */
  updateSimultaneousStartsCount(count: number): void {
    this._simultaneousStartsCount.next(count);
  }

  cancelPublishing(): void {
    this.cancelPublish.next();
    this._publishComplete.next(false);
  }

  public unallocateConflicts(pendingEvents: TaskItem[]): Observable<string[]> {
    // Create a set of pending event IDs for quick lookup
    const pendingEventIds = new Set(pendingEvents.map(event => event.id));
    
    // Find all conflicts using the hasConflict method
    const conflictingEvents: TaskItem[] = [];
    
    // Check for conflicts that would leave services unallocated
    for (const event of pendingEvents) {
      const conflictId = this.schedulerEventsService.hasConflict(event.id);
      
      if (conflictId) {
        // Check if the conflicting event is also part of pending changes
        if (!pendingEventIds.has(conflictId)) {
          // If the conflicting event is not part of pending changes, we need to alert the user
          // and halt the publish process to avoid leaving services unallocated
          return this.progressService.openErrorDialog(
            `Event ${conflictId} would be left unallocated. Please include it in your changes.`
          );
        }
        
        conflictingEvents.push(event);
      }
    }

    if (conflictingEvents.length === 0) {
      return of([]);
    }

    // Log details of conflicting events before unallocation
    if (conflictingEvents.length > 0) {
      console.log('Events to be unallocated:');
      conflictingEvents.forEach(event => {
        console.log(`Event ID: ${event.id}, Start: ${event.start_date}, Original Carer: ${event.original_carer_string}, New Carer: ${event.carer_string}`);
      });
    }

    // Create an array of unallocation observables
    const unallocationObservables = conflictingEvents.map(event =>
      this.eventService.unallocateServiceInstance(event.id)
    );

    // Return an observable that completes when all unallocations are done and returns the unallocated event IDs
    return forkJoin(unallocationObservables).pipe(
      tap((responses: any[]) => {
        // Log responses from unallocation calls
        responses.forEach((response: any, index: number) => {
          if (response) {
            console.log(`Unallocation Response for event ${conflictingEvents[index].id}:`, {
              message: response.message,
              data: response.data,
              status: response.ecase_status_code
            });
          }
        });
      }),
      map(() => conflictingEvents.map(event => event.id))
    );
  }

  publishPendingChanges(events: TaskItem[], updateEvent: (eventId: string) => void, loadTasks?: (date: string, restoreScrollPosition: boolean) => void): void {
    // Reset publish complete state at the beginning of a new publish operation
    this._publishComplete.next(false);
    
    if (this.cancelPublish.closed) {
      this.cancelPublish = new Subject<void>();
    }

    const renderedCarerIds = this.filterService.getRenderedCarerIds();
    const pendingEvents = events.filter(event =>
      event.pending_change &&
      new Date(event.start_date) >= new Date() &&
      this.filterService.isTaskVisible(event, renderedCarerIds)
    );
    const totalPending = pendingEvents.length;
    this._pendingChangesCount.next(totalPending);

    // Identify conflict groups using ConflictManagementService
    const conflictData = this.conflictService.createConflictGroups(
      pendingEvents,
      (eventId) => this.schedulerEventsService.hasConflict(eventId)
    );
    
    const { nonConflictEvents, conflictGroupEvents, eventToGroupMap, dynamicEventIds } = conflictData;

    // Open the progress dialog
    const { progressDialog, dialogRef } = this.progressService.openProgressDialog(
      nonConflictEvents.length,
      conflictGroupEvents.reduce((count, group) => count + group.events.length, 0)
    );
    
    // Handle dialog close/cancel
    dialogRef.afterClosed().subscribe((result: { canceled?: boolean } | undefined) => {
      if (result?.canceled) {
        this.cancelPublish.next();
      }
    });

    // Add steps to the progress dialog
    // First add non-conflict events
    nonConflictEvents.forEach(event => {
      this.progressService.addEventToProgressDialog(event, progressDialog, false);
    });

    // Then add conflict group events
    conflictGroupEvents.forEach(group => {
      group.events.forEach(event => {
        this.progressService.addEventToProgressDialog(event, progressDialog, true, group.groupId);
      });
    });

    // Create an observable chain for processing events
    let publishChain = of(null as any);

    // 1. Process non-conflict events first
    if (nonConflictEvents.length > 0) {
      publishChain = publishChain.pipe(
        concatMap(() => {
          return new Observable((observer: { next: (val: any) => void, error: (err: any) => void, complete: () => void }) => {
            this.eventProcessingService.processEvents(
                nonConflictEvents, 
                updateEvent, 
                progressDialog, 
                eventToGroupMap,
                dynamicEventIds,
                this.cancelPublish
              ).pipe(
                takeUntil(this.cancelPublish),
                finalize(() => {
                  observer.next(null);
                  observer.complete();
                })
              )
              .subscribe({
                error: (err: any) => observer.error(err)
              });
          });
        })
      );
    }

    // 2. Process each conflict group sequentially
    // Sort the conflict groups to ensure consistent processing order
    // Dynamic swap group (which starts with 'z-') should be processed last
    const sortedGroups = [...conflictGroupEvents].sort((a, b) => {
      // Put the dynamic group last
      if (a.groupId.startsWith('z-')) return 1;
      if (b.groupId.startsWith('z-')) return -1;
      
      // Otherwise sort other groups normally
      return a.groupId.localeCompare(b.groupId);
    });
    
    // Process groups in the sorted order
    for (const group of sortedGroups) {
      publishChain = publishChain.pipe(
        concatMap(() => this.eventProcessingService.processConflictGroup(
          group,
          updateEvent,
          progressDialog,
          eventToGroupMap,
          dynamicEventIds,
          this.cancelPublish
        ))
      );
    }
    
    // 3. Process any dynamic conflict group that was created during standard event processing
    // This step is still needed because dynamicEventIds might be populated during runtime 
    // by events that weren't originally part of any conflict group
    publishChain = publishChain.pipe(
      concatMap(() => {
        return new Observable((observer: { next: (val: any) => void, error: (err: any) => void, complete: () => void }) => {
          // Check if any events were added to the dynamic group during processing
          const dynamicGroup = this.conflictService.createDynamicConflictGroup(events, dynamicEventIds);
          
          if (dynamicGroup && dynamicGroup.events.length > 0) {
            console.log(`Processing dynamic swap group with ${dynamicGroup.events.length} events`);
            
            this.eventProcessingService.processConflictGroup(
              dynamicGroup,
              updateEvent,
              progressDialog,
              eventToGroupMap,
              dynamicEventIds,
              this.cancelPublish
            ).pipe(
              takeUntil(this.cancelPublish),
              finalize(() => {
                observer.next(null);
                observer.complete();
              })
            ).subscribe({
              error: (err: any) => observer.error(err)
            });
          } else {
            // No dynamic group to process or it's already been processed
            console.log('No dynamic conflict group to process');
            observer.next(null);
            observer.complete();
          }
        });
      })
    );

    // Subscribe to the chain to start the publishing process
    publishChain.pipe(
      takeUntil(this.cancelPublish),
      finalize(() => {
        // Check if any steps failed
        const failedSteps = progressDialog.steps.filter(step => 
          step.completed && !step.success
        );
        
        // Finish the progress dialog
        this.progressService.finishProgress(
          progressDialog, 
          failedSteps.length === 0
        );
        
        // Signal that publishing is complete
        this._publishComplete.next(true);
        this.updatePendingChangesCount(events);
        
        // If loadTasks function was provided, call it to refresh the data
        if (loadTasks) {
          // Get the current date from the first event
          const currentDate = events.length > 0 
            ? format(new Date(events[0].start_date), 'yyyy-MM-dd')
            : format(new Date(), 'yyyy-MM-dd');
          
          // Reload tasks with the current date and restore scroll position
          loadTasks(currentDate, true);
        }
      })
    ).subscribe({
      error: (error: unknown) => {
        // Since API always returns 200, this handler is only for network/system failures
        const errorMessage = error instanceof Error ? error.message : String(error);
        progressDialog.setError(`A network error occurred: ${errorMessage}`);
        this.notificationService.addMessage('critical', 'A network error occurred while publishing changes: ' + errorMessage);
      }
    });
  }
}
