import { Injectable, ElementRef, OnDestroy, EventEmitter } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class DragDropService implements OnDestroy {
  private draggedCarerId: string | null = null;
  private elementRef: ElementRef | null = null;

  // Event emitter for bulk reallocation
  doubleClickOnCarer = new EventEmitter<{ carerId: string; cellText: string }>();
  dropOnCarer = new EventEmitter<{ sourceCarerId: string; targetCarerId: string; targetName: string }>();

  constructor() { }

  initializeDragAndDrop(elementRef: ElementRef): void {
    this.elementRef = elementRef;
    setTimeout(() => {
      this.setupDragHandlersForVisibleCells();
    }, 1000);
  }

  setupDragHandlersForVisibleCells(): void {
    if (!this.elementRef?.nativeElement) return;

    const labelCells = this.elementRef.nativeElement.querySelectorAll('.dhx_matrix_scell');
    labelCells.forEach((cell: Element) => {
      const htmlCell = cell as HTMLElement;
      this.setupDragHandlerForCell(htmlCell);
    });
  }

  private setupDragHandlerForCell(htmlCell: HTMLElement): void {
    if (htmlCell.getAttribute('data-drag-initialized')) return;

    const isLeafNode = !htmlCell.querySelector('.dhx_scell_level0') &&
      !htmlCell.classList.contains('folder');

    if (isLeafNode) {
      htmlCell.setAttribute('draggable', 'true');
      htmlCell.setAttribute('data-drag-initialized', 'true');

      // Set up drag handlers
      htmlCell.addEventListener('dragstart', (e: DragEvent) => this.handleDragStart(e, htmlCell));
      htmlCell.addEventListener('dragover', (e: DragEvent) => this.handleDragOver(e));
      htmlCell.addEventListener('drop', (e: DragEvent) => this.handleDrop(e, htmlCell));
      htmlCell.addEventListener('dragend', () => this.handleDragEnd());

      // Set up double-click handler
      htmlCell.addEventListener('dblclick', () => this.handleDoubleClick(htmlCell));
    }
  }

  private handleDoubleClick(cell: HTMLElement): void {
    const rowElement = cell.closest('.dhx_timeline_label_row');
    if (!rowElement) return;

    const carerId = rowElement.getAttribute('data-row-id');
    if (!carerId) return;

    this.doubleClickOnCarer.emit({
      carerId: carerId,
      cellText: cell.textContent || ''
    });
  }


  private handleDragStart(e: DragEvent, cell: HTMLElement): void {
    const rowElement = cell.closest('.dhx_timeline_label_row');
    if (!rowElement) return;

    const carerId = rowElement.getAttribute('data-row-id');
    if (carerId && e.dataTransfer) {
      this.draggedCarerId = carerId;
      e.dataTransfer.setData('text/plain', carerId);
      e.dataTransfer.effectAllowed = 'move';
      cell.classList.add('dragging');
    } else {
      e.preventDefault();
    }
  }

  private handleDragOver(e: DragEvent): void {
    const cell = (e.target as HTMLElement).closest('.dhx_matrix_scell');
    if (!cell) return;

    const isLeafNode = !cell.querySelector('.dhx_scell_level0') &&
      !cell.classList.contains('folder');

    if (isLeafNode) {
      e.preventDefault();
      e.stopPropagation();
      if (e.dataTransfer) {
        e.dataTransfer.dropEffect = 'move';
      }
    }
  }

  private handleDrop(e: DragEvent, cell: HTMLElement): void {
    e.preventDefault();
    e.stopPropagation();

    if (!this.draggedCarerId) return;

    const rowElement = cell.closest('.dhx_timeline_label_row');
    if (!rowElement) return;

    const targetCarerId = rowElement.getAttribute('data-row-id');
    if (targetCarerId && targetCarerId !== this.draggedCarerId) {
      this.dropOnCarer.emit({
        sourceCarerId: this.draggedCarerId,
        targetCarerId: targetCarerId,
        targetName: cell.textContent || ''
      });
    }

    this.clearDragState();
  }

  private handleDragEnd(): void {
    this.clearDragState();
  }

  private clearDragState(): void {
    document.querySelectorAll('.dhx_matrix_scell').forEach(cell => {
      cell.classList.remove('dragging');
    });
    this.draggedCarerId = null;
  }

  ngOnDestroy(): void {
    this.elementRef = null;
  }
}
