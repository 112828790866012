import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { BaseApiService } from './api-base.service';
import { HelperService } from './helper.service';
import { CarePlan } from '../models/careplan.model';
import { ServicePlanDetail } from '../models/serviceplandetail.model';

@Injectable({
  providedIn: 'root'
})
export class CarePlanService extends BaseApiService {
  private env: string;

  constructor(
    http: HttpClient,
    msalService: MsalService,
    private helperService: HelperService
  ) {
    super(http, msalService);
    this.env = this.helperService.getStoredValue('ecase_env', 'prod');
  }

  getClientCarePlan(clientId: string): Observable<CarePlan> {
    const url = `${this.apiUrl}/ecase/${this.env}/clientserviceplan/${clientId}`;
    return this.get<CarePlan>(url);
  }

  getServicePlanDetails(servicePlanId: string): Observable<ServicePlanDetail> {
    const url = `${this.apiUrl}/ecase/${this.env}/serviceplandetails/${servicePlanId}`;
    return this.get<ServicePlanDetail>(url);
  }

  getRateWindow(activityData: any): Observable<{start_time: string, end_time: string}> {
    const url = `${this.apiUrl}/ecase/${this.env}/rate-window/`;
    return this.post<{start_time: string, end_time: string}>(url, activityData);
  }

  updateKeyStaff(quote_detail_id: string, key_staff_id: string, resident_id: string): Observable<any> {
    const url = `${this.apiUrl}/ecase/${this.env}/careplan/keystaff/`;
    const payload = {
      quote_detail_id: String(quote_detail_id),
      key_staff_id: String(key_staff_id),
      resident_id: String(resident_id)
    };
    return this.post<any>(url, payload);
  }

  updateServicePlanTiming(client_id: string, service_details_id: string, new_start: string): Observable<any> {
    const url = `${this.apiUrl}/ecase/${this.env}/careplan/timing/`;
    const payload = {
      client_id: String(client_id),
      service_details_id: String(service_details_id),
      new_start: String(new_start)
    };
    return this.post<any>(url, payload);
  }

  refreshClientServicePlanCache(clientId: string): Observable<any> {
    // Ensure clientId is provided
    if (!clientId) {
      console.error("Client ID is required to refresh the service plan cache.");
      // Return an observable that emits an error or an empty value,
      // depending on how you want to handle this case.
      // For now, let's return an empty observable to avoid breaking the chain.
      // Consider adding proper error handling or notification.
      return new Observable(observer => {
        observer.error('Client ID is missing');
        observer.complete();
      });
      // Or perhaps: return throwError(() => new Error('Client ID is missing'));
    }
    const url = `${this.apiUrl}/ecase/${this.env}/refresh/clientserviceplan/${clientId}`;
    console.log(`[CarePlanService] Refreshing cache for client ID: ${clientId} at URL: ${url}`); // Optional: Add logging
    return this.get(url);
  }
}
