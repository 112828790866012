import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { timer, Subscription } from 'rxjs';

interface ProgressStep {
  text: string;
  completed: boolean;
}

@Component({
  selector: 'app-progress-dialog',
  templateUrl: './progress-dialog.component.html',
  styleUrls: ['./progress-dialog.component.css']
})
export class ProgressDialogComponent implements OnInit, OnDestroy {
  steps: ProgressStep[] = [
    { text: 'Getting list of care plans', completed: false },
    { text: 'Filtering for active care plans', completed: false },
    { text: 'Filtering for resident\'s active care plan', completed: false },
    { text: 'Loading full service plan details', completed: false },
    { text: 'Finding specific service', completed: false },
    { text: 'Updating key staff', completed: false }
  ];

  private currentStep = 0;
  private subscription?: Subscription;
  isComplete = false;
  status: 'progress' | 'success' | 'error' = 'progress';
  statusMessage = '';

  constructor(
    public dialogRef: MatDialogRef<ProgressDialogComponent>
  ) {}

  ngOnInit() {
    timer(1000).subscribe(() => this.startProgress());
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private getStepDelay(step: number): number {
    // Early steps (0-2) are relatively quick
    if (step <= 2) {
      return Math.random() * 800 + 400;
    }
    // Steps 3-4 gradually increase in delay
    else if (step <= 4) {
      const baseDelay = 600;
      const stepMultiplier = step - 2;
      return Math.random() * 800 + (baseDelay * stepMultiplier);
    }
    // Step 5 (final step) is handled separately in completeRemaining
    return 0;
  }

  private startProgress() {
    const checkNextStep = () => {
      if (this.currentStep < this.steps.length - 1) { // Don't process the last step
        this.steps[this.currentStep].completed = true;
        this.currentStep++;
        
        // Schedule next step with calculated delay
        const delay = this.getStepDelay(this.currentStep);
        this.subscription = timer(delay).subscribe(() => checkNextStep());
      }
    };

    // Start with first step
    const initialStepDelay = this.getStepDelay(0);
    this.subscription = timer(initialStepDelay).subscribe(() => checkNextStep());
  }

  completeRemaining() {
    // Clear any existing subscription
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    // Complete remaining steps quickly
    const completeNext = () => {
      if (this.currentStep < this.steps.length) {
        this.steps[this.currentStep].completed = true;
        this.currentStep++;
        if (this.currentStep < this.steps.length) {
          setTimeout(completeNext, 150); // Quick 150ms delay between remaining steps
        } else {
          this.isComplete = true;
        }
      }
    };

    completeNext();
  }

  setSuccess(message: string = 'Key staff updated successfully') {
    // Complete the final step first
    this.steps[this.steps.length - 1].completed = true;
    this.status = 'success';
    this.statusMessage = message;
    this.isComplete = true;
  }

  setError(message: string = 'Failed to update key staff. Please try again.') {
    this.status = 'error';
    this.statusMessage = message;
    this.isComplete = true;
  }

  onClose() {
    if (this.isComplete) {
      this.dialogRef.close();
    }
  }
}
