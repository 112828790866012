import { Component, Inject, OnDestroy, HostListener, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FilterGridService } from '../../services/filter-grid.service';
import { FilterService } from '../../services/filter.service';
import { ClientType, TimeOfDay, SchedulerFilter, GridCell, GridSection } from '../../models/filter.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-filter-grid-dialog',
  templateUrl: './filter-grid-dialog.component.html',
  styleUrls: ['./filter-grid-dialog.component.scss']
})
export class FilterGridDialogComponent implements OnDestroy, AfterViewInit {
  ClientType = ClientType;
  TimeOfDay = TimeOfDay;
  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    // Handle global keyboard shortcuts
    if (event.key === 'Escape') {
      this.dialogRef.close();
      return;
    }

    if (event.ctrlKey || event.metaKey) {
      switch (event.key) {
        case 'Enter':
          this.onClose();
          break;
        case 'Delete':
        case 'Backspace':
          this.clearFilters();
          break;
      }
      return;
    }

    // Handle grid navigation
    const activeElement = document.activeElement;
    if (!activeElement?.classList.contains('grid-cell')) return;

    const gridRow = activeElement.parentElement;
    const grid = gridRow?.parentElement;
    if (!gridRow || !grid) return;

    const cells = Array.from(gridRow.children) as HTMLElement[];
    const rows = Array.from(grid.children) as HTMLElement[];
    const currentIndex = cells.indexOf(activeElement as HTMLElement);
    const rowIndex = rows.indexOf(gridRow);

    switch (event.key) {
      case 'ArrowLeft':
        if (currentIndex > 0) {
          const prevCell = cells[currentIndex - 1];
          if (prevCell.classList.contains('clickable')) {
            prevCell.focus();
            event.preventDefault();
          }
        }
        break;
      case 'ArrowRight':
        if (currentIndex < cells.length - 1) {
          const nextCell = cells[currentIndex + 1];
          if (nextCell.classList.contains('clickable')) {
            nextCell.focus();
            event.preventDefault();
          }
        }
        break;
      case 'ArrowUp':
        if (rowIndex > 0) {
          const prevRow = rows[rowIndex - 1];
          const targetCell = prevRow.children[currentIndex] as HTMLElement;
          if (targetCell.classList.contains('clickable')) {
            targetCell.focus();
            event.preventDefault();
          }
        }
        break;
      case 'ArrowDown':
        if (rowIndex < rows.length - 1) {
          const nextRow = rows[rowIndex + 1];
          const targetCell = nextRow.children[currentIndex] as HTMLElement;
          if (targetCell.classList.contains('clickable')) {
            targetCell.focus();
            event.preventDefault();
          }
        }
        break;
      case 'Enter':
      case ' ':
        // Trigger click on focused cell
        (activeElement as HTMLElement).click();
        event.preventDefault();
        break;
    }
  }

  private subscription: Subscription;
  private initialFilter: SchedulerFilter;
  private pendingFilter: SchedulerFilter;
  
  sections: GridSection[] = [];
  gridData: {[key: number]: GridCell[]} = {};
  currentFilter$ = this.filterService.getFilterState();
  selectedCells: Set<string> = new Set();

  constructor(
    public dialogRef: MatDialogRef<FilterGridDialogComponent>,
    private filterGridService: FilterGridService,
    private filterService: FilterService,
    @Inject(MAT_DIALOG_DATA) public data: { currentClientType: ClientType; currentTimeOfDay: TimeOfDay }
  ) {
    // Configure dialog to restore focus properly when closing
    this.dialogRef.disableClose = true;
    this.dialogRef.backdropClick().subscribe(() => {
      this.onClose();
    });
    // Store initial filter state
    this.initialFilter = this.filterService.getCurrentFilterState();
    this.pendingFilter = { ...this.initialFilter };

    // Get sections from service
    this.sections = this.filterGridService.getSections();

    // Initialize selected cells based on current state
    this.selectedCells = this.filterGridService.getSelectedCells();

    // Subscribe to grid data updates
    this.subscription = this.filterGridService.getGridData().subscribe(data => {
      this.gridData = data;
    });

    // Initialize grid data
    this.filterGridService.updateCounts();
  }

  private lastClickTime = 0;
  private readonly DOUBLE_CLICK_DELAY = 300;

  onHeaderClick(cell: GridCell): void {
    if (!cell) return;
    
    const now = Date.now();
    const isDoubleClick = (now - this.lastClickTime) < this.DOUBLE_CLICK_DELAY;
    this.lastClickTime = now;

    if (isDoubleClick) {
      this.applyFilterAndClose(cell, TimeOfDay.ANY);
      return;
    }

    // Clear all selected cells first
    this.selectedCells.clear();
    
    const cellKey = `${cell.sectionKey}-${cell.clientType}-${TimeOfDay.ANY}`;
    this.selectedCells.add(cellKey);
    
    this.pendingFilter = {
      ...this.pendingFilter,
      timeOfDay: TimeOfDay.ANY,
      clientType: cell.clientType,
      sectionVisibility: {
        ...this.pendingFilter.sectionVisibility,
        [cell.sectionKey]: true
      }
    };
  }

  onCellClick(cell: GridCell): void {
    if (!cell) return;
    
    const now = Date.now();
    const isDoubleClick = (now - this.lastClickTime) < this.DOUBLE_CLICK_DELAY;
    this.lastClickTime = now;

    if (isDoubleClick) {
      this.applyFilterAndClose(cell, cell.timeOfDay);
      return;
    }

    // Clear all selected cells first
    this.selectedCells.clear();
    
    const cellKey = `${cell.sectionKey}-${cell.clientType}-${cell.timeOfDay}`;
    this.selectedCells.add(cellKey);
    
    this.pendingFilter = {
      ...this.pendingFilter,
      timeOfDay: cell.timeOfDay,
      clientType: cell.clientType,
      sectionVisibility: {
        ...this.pendingFilter.sectionVisibility,
        [cell.sectionKey]: true
      }
    };
  }

  private applyFilterAndClose(cell: GridCell, timeOfDay: TimeOfDay): void {
    const filter: Partial<SchedulerFilter> = {
      timeOfDay: timeOfDay,
      clientType: cell.clientType,
      sectionVisibility: {
        [cell.sectionKey]: true
      }
    };
    this.filterService.updateFilter(filter);
    this.dialogRef.close();
  }

  onTimeClick(timeOfDay: TimeOfDay, sectionKey: number): void {
    // Clear all selected cells first
    this.selectedCells.clear();
    
    // Create a key for this time selection
    const timeKey = `${sectionKey}-${ClientType.BOTH}-${timeOfDay}`;
    this.selectedCells.add(timeKey);
    
    this.pendingFilter = {
      ...this.pendingFilter,
      timeOfDay: timeOfDay,
      clientType: ClientType.BOTH,
      sectionVisibility: {
        ...this.pendingFilter.sectionVisibility,
        [sectionKey]: true
      }
    };
  }

  isTimeSelected(timeOfDay: TimeOfDay, sectionKey: number): boolean {
    const timeKey = `${sectionKey}-${ClientType.BOTH}-${timeOfDay}`;
    return this.selectedCells.has(timeKey);
  }

  clearFilters(): void {
    this.selectedCells.clear();
    this.pendingFilter = {
      ...this.initialFilter,
      timeOfDay: TimeOfDay.ANY,
      clientType: ClientType.BOTH,
      sectionVisibility: {}
    };
  }

  ngAfterViewInit(): void {
    // Set initial focus to the first clickable cell after dialog opens
    setTimeout(() => {
      const firstClickableCell = document.querySelector('.grid-cell.clickable') as HTMLElement;
      if (firstClickableCell) {
        firstClickableCell.focus();
      }
    });
  }

  onClose(): void {
    // Apply pending filter state and close
    this.filterService.updateFilter(this.pendingFilter);
    this.dialogRef.close();
  }

  isSectionActive(sectionKey: number): boolean {
    return this.filterGridService.isSectionActive(sectionKey);
  }

  isCellSelected(cell: GridCell): boolean {
    return this.filterGridService.isCellSelected(cell);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
