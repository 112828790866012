import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TaskService } from './task.service';
import { FilterService } from './filter.service';
import { GridCell, GridSection, TimeOfDay, ClientType } from '../models/filter.model';

@Injectable({
  providedIn: 'root'
})
export class FilterGridService {
  private sections: GridSection[] = [
    { key: 1000001, label: 'RN', cells: [] },
    { key: 1000002, label: 'PC', cells: [] },
    { key: 1000003, label: 'Support', cells: [] }
  ];

  private gridData = new BehaviorSubject<{[key: number]: GridCell[]}>({});

  constructor(
    private taskService: TaskService,
    private filterService: FilterService
  ) {
    // Subscribe to task counts updates
    this.taskService.counts$.subscribe(counts => {
      const newGridData: {[key: number]: GridCell[]} = {};
      
      this.sections.forEach(section => {
        newGridData[section.key] = this.buildSectionGrid(section.key, counts);
      });

      this.gridData.next(newGridData);
    });
  }

  private buildSectionGrid(sectionKey: number, counts: {[key: string]: number}): GridCell[] {
    const cells: GridCell[] = [];
    
    // Add cells for both HPAC and Independent
    [ClientType.INDEPENDENT_ONLY, ClientType.HPAC_ONLY].forEach(clientType => {
      // Title row (ANY time)
      cells.push({ 
        count: counts[`${sectionKey}-${clientType}-${TimeOfDay.ANY}`] || 0,
        sectionKey,
        clientType,
        timeOfDay: TimeOfDay.ANY
      });
      
      // AM row
      cells.push({
        count: counts[`${sectionKey}-${clientType}-${TimeOfDay.DAY}`] || 0,
        sectionKey,
        clientType,
        timeOfDay: TimeOfDay.DAY
      });
      
      // PM row
      cells.push({
        count: counts[`${sectionKey}-${clientType}-${TimeOfDay.NIGHT}`] || 0,
        sectionKey,
        clientType,
        timeOfDay: TimeOfDay.NIGHT
      });
    });
    
    return cells;
  }

  getSections(): GridSection[] {
    return this.sections;
  }

  getGridData(): Observable<{[key: number]: GridCell[]}> {
    return this.gridData.asObservable();
  }

  getCurrentGridData(): {[key: number]: GridCell[]} {
    return this.gridData.value;
  }

  updateCounts(): void {
    this.taskService.updateCounts();
  }

  isSectionActive(sectionKey: number): boolean {
    return this.filterService.getCurrentFilterState().sectionVisibility[sectionKey] || false;
  }

  getSelectedCells(): Set<string> {
    const currentState = this.filterService.getCurrentFilterState();
    const selectedCells = new Set<string>();

    this.sections.forEach(section => {
      if (currentState.sectionVisibility[section.key]) {
        if (currentState.timeOfDay === TimeOfDay.ANY) {
          // If time is ANY, add the header cell
          const key = `${section.key}-${currentState.clientType}-${TimeOfDay.ANY}`;
          selectedCells.add(key);
        } else {
          // Otherwise add the specific time cell
          const key = `${section.key}-${currentState.clientType}-${currentState.timeOfDay}`;
          selectedCells.add(key);
        }
      }
    });

    return selectedCells;
  }

  isCellSelected(cell: GridCell): boolean {
    if (!cell) return false;
    const key = `${cell.sectionKey}-${cell.clientType}-${cell.timeOfDay}`;
    return this.getSelectedCells().has(key);
  }
}
