import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap, switchMap } from 'rxjs/operators';
import { EventService } from './event.service';
import { PermissionsService } from './permissions.service';
import { AppointmentStatus } from "./appointmentStatus";
import { RoleObject, AllowedDestinations, Section, StaffItem } from '../models/ydata.model';
import { TaskItem, TaskMoveAllowedResult } from '../models/task.model';
import { EcaseEvent } from '../models/ecaseevent.model';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  private _ydata: RoleObject[] = [];
  private _allowedDestinations: AllowedDestinations = {};
  private _tasks: TaskItem[] = [];
  private _isLoading = new BehaviorSubject<boolean>(false);

  constructor(
    private eventService: EventService,
    private permissionsService: PermissionsService
  ) { }

  loadTasks(date: string): Observable<{ tasks: TaskItem[], ydata: RoleObject[] }> {
    this._isLoading.next(true);
    return this.eventService.getTimelineEvents(date).pipe(
      switchMap(response => {
        return this.permissionsService.hasFeatureAccess('reallocate').pipe(
          map(isReallocateOnly => {
            let { ecaseevents, ydata, allowedDestinations } = response;
            
            if (isReallocateOnly) {
              // Filter for reallocate users
              ecaseevents = this.filterAllocatedServices(ecaseevents);
              ydata.yunitData = this.filterYunitData(ydata.yunitData);
            }

            this._ydata = ydata.yunitData;
            this._allowedDestinations = allowedDestinations;

            if (ecaseevents.length === 0) {
              this._tasks = [];
            } else {
              this._tasks = ecaseevents.map(ecaseEvent => this.createTaskItem(ecaseEvent));
            }
            
            return { tasks: this._tasks, ydata: this._ydata };
          })
        );
      }),
      tap(() => this._isLoading.next(false))
    );
  }

  private filterAllocatedServices(events: EcaseEvent[]): EcaseEvent[] {
    return events.filter(event => event.carer_allocations.length > 0);
  }

  private filterYunitData(yunitData: RoleObject[]): RoleObject[] {
    // First pass: Deep clone and filter out unwanted items
    const filteredData = yunitData.map(item => this.filterYunitItem(item)).filter(Boolean) as RoleObject[];
    
    // Second pass: Remove empty parent sections
    return filteredData.filter(item => {
      if (item.key >= 1000000 && item.key < 2000000) {
        return item.children && item.children.length > 0;
      }
      return true;
    });
  }

  private filterYunitItem(item: RoleObject | Section | StaffItem): RoleObject | Section | StaffItem | null {
    // Remove only unallocated sections (2000000-2999999)
    if (item.key >= 2000000 && item.key < 3000000) {
      return null;
    }

    // Deep clone the item
    const clonedItem: any = { ...item };

    // Recursively filter children if they exist
    if ('children' in item && Array.isArray(item.children)) {
      clonedItem.children = item.children
        .map(child => this.filterYunitItem(child))
        .filter(Boolean);
    }

    return clonedItem;
  }

  createTaskItem = (ecaseEvent: EcaseEvent): TaskItem => {
    const preferredCarers = ecaseEvent.client.client_preferred_carers
      .filter(carer => carer.ClientPreferredCarerExcluded === 'N')
      .map(carer => carer.ClientPreferredCarerID);

    const excludedCarers = ecaseEvent.client.client_preferred_carers
      .filter(carer => carer.ClientPreferredCarerExcluded === 'Y')
      .map(carer => carer.ClientPreferredCarerID);

    let taskItem = {
      start_date: ecaseEvent.start_date,
      end_date: ecaseEvent.end_date,
      text: ecaseEvent.text,
      id: ecaseEvent.id,
      carer_string: ecaseEvent.carer_string || String(ecaseEvent.unallocated_key),
      carer_name: ecaseEvent.carer_allocations.length > 0 ? ecaseEvent.carer_allocations[0].carer_name : "",
      unallocated_key: ecaseEvent.unallocated_key || 0,
      CCQuoteDetailID: ecaseEvent.CCQuoteDetailID || "",
      css_ecase: this.getEcaseCss(ecaseEvent),
      css_custom: "",
      residentID: ecaseEvent.client.client_details[0].residentID,
      client_name: ecaseEvent.client.client_name,
      client_gender: ecaseEvent.client.client_details[0].gender,
      section_key: ecaseEvent.section_key || 0,
      section_label: ecaseEvent.section_label || "",
      suggested_carer_string: ecaseEvent.suggested_carer_string,
      suggested_carer_name: ecaseEvent.suggested_carer_name,
      preferred_carers: preferredCarers,
      excluded_carers: excludedCarers,
      pending_allocation_change: false,
      pending_timing_change: false,
      pending_change: false,
      original_start_date: ecaseEvent.start_date,
      original_end_date: ecaseEvent.end_date,
      original_carer_string: ecaseEvent.carer_string || String(ecaseEvent.unallocated_key)
    };
    this.autoAllocateKeyStaff(taskItem);
    return taskItem;
  }

  autoAllocateKeyStaff(task: TaskItem): void {
    if (this.isTaskUnallocated(task) && task.suggested_carer_string) {
      const isMoveAllowedResult = this.isMoveAllowed(task.section_key, task.section_label, task.suggested_carer_string, task.css_custom);

      if (!isMoveAllowedResult.disallowReason) {
        task.carer_string = task.suggested_carer_string;
        task.pending_allocation_change = true;
        task.pending_change = true;
        task.css_custom = isMoveAllowedResult.classString;
      } else {
        console.log(isMoveAllowedResult.disallowReason);
      }
    }
  }

  reapplyAutoAllocation(tasks: TaskItem[]): void {
    tasks.forEach(task => {
      if (this.isTaskUnallocated(task) || 
          (task.carer_string === task.original_carer_string && task.suggested_carer_string)) {
        this.autoAllocateKeyStaff(task);
      }
    });
  }

  revertAutoAllocation(tasks: TaskItem[]): void {
    tasks.forEach(task => {
      if (task.carer_string === task.suggested_carer_string && 
          task.carer_string !== task.original_carer_string) {
        task.carer_string = task.original_carer_string;
        task.pending_allocation_change = false;
        task.pending_change = task.pending_timing_change;
        task.css_custom = '';
      }
    });
  }

  isTaskUnallocated(task: TaskItem): boolean {
    const carerString = task.carer_string;
    if (!carerString) {
      return true;
    }
    const carerId = parseInt(carerString);
    return carerId >= 2000000 && carerId < 3000000;
  }

  isMoveAllowed(section_key: number, section_label: string, new_carer_string: string, css_custom: string): TaskMoveAllowedResult {
    const allowedDestinationsForEvent = this._allowedDestinations[section_key];
    const new_carer_string_parent_section_key = this.findParentSectionKey(parseInt(new_carer_string, 10));

    let returnCssClass = css_custom;
    if (css_custom.includes('relocated-section')) {
      returnCssClass = css_custom.replace(/relocated-section-[\w-]+/, '');
    }

    if (section_key === new_carer_string_parent_section_key) {
      returnCssClass = `${returnCssClass} relocated-section-same`;
      return { disallowReason: undefined, classString: returnCssClass.trim() };
    }

    const isAllowed = allowedDestinationsForEvent && allowedDestinationsForEvent.includes(new_carer_string_parent_section_key);
    if (isAllowed) {
      returnCssClass = `${returnCssClass} relocated-section-${section_label.replace(/\s/g, '-')}`;
      return { disallowReason: undefined, classString: returnCssClass.trim() };
    }
    return { disallowReason: `Cannot move ${section_label} service to ${this._ydata.find(section => section.key === new_carer_string_parent_section_key)?.label} section`, classString: returnCssClass.trim() };
  }

  findInvalidCarerStrings(events: any[]) {
    if (!this._ydata) {
      console.error('YData not available');
      return;
    }

    const carerKeys = this.getAllCarerKeys(this._ydata);
    const invalidEvents = events.filter(event => {
      const carerString = String(event.carer_string);
      return !carerKeys.includes(carerString);
    });

    if (invalidEvents.length > 0) {
      console.log('Unmatched carer strings found:');
      console.table(invalidEvents.map(event => ({
        eventId: event.id,
        carerString: event.carer_string
      })));
    }
  }

  private getAllCarerKeys(data: any[]): string[] {
    let keys: string[] = [];
    for (const item of data) {
      if ('key' in item) {
        keys.push(String(item.key));
      }
      if ('children' in item && Array.isArray(item.children)) {
        keys = keys.concat(this.getAllCarerKeys(item.children));
      }
    }
    return keys;
  }

  get isLoading$(): Observable<boolean> {
    return this._isLoading.asObservable();
  }

  get ydata(): RoleObject[] {
    return this._ydata;
  }

  get allowedDestinations(): AllowedDestinations {
    return this._allowedDestinations;
  }

  getEcaseCss = (appointment: any): string => {
    let classNames = [];
    const statusClass = AppointmentStatus.getAppointmentClass(appointment);
    if (statusClass) {
      classNames.push(statusClass);
    }
    if (appointment.isStaffEvent) {
      classNames.push('staff-event');
    }
    if (appointment.readonly) {
      classNames.push('readonly');
    }
    if (appointment.activity?.activityType === 'Leave') {
      classNames.push('leave');
    }
    if (appointment.activity?.activityType === 'Unavailability') {
      classNames.push('unavailable');
    }
    return classNames.join(' ');
  }

  findParentSectionKey(targetKey: number): number {
    for (const roleObject of this._ydata) {
      const result = this.searchSections(roleObject, targetKey, null);
      if (result !== null) {
        return result;
      }
    }
    return 1000010;
  }

  searchSections(
    item: RoleObject | Section | StaffItem,
    targetKey: number,
    currentParentKey: number | null
  ): number | null {
    if (item.key >= 1000000 && item.key < 2000000) {
      currentParentKey = item.key;
    }

    if (item.key === targetKey) {
      return currentParentKey;
    }

    if ('children' in item && item.children) {
      for (const child of item.children) {
        const result = this.searchSections(child, targetKey, currentParentKey);
        if (result !== null) {
          return result;
        }
      }
    }

    return null;
  }

  hasPendingAllocationChange(ev: any): boolean {
    const carerString = Number(ev.carer_string);
    const isUnallocatedCarerString = carerString >= 1000000 && carerString < 2000000;
    const matchesCarerString = String(ev.carer_string) === String(ev.original_carer_string);

    if (isUnallocatedCarerString || matchesCarerString) {
      return false;
    }
    return true;
  }
}
