import { NgModule, inject } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { ProfileComponent } from 'src/app/pages/profile/profile.component';
import { HomeComponent } from 'src/app/home/home.component';
import { FailedComponent } from 'src/app/pages/failed/failed.component';
import { RosteringGuard } from 'src/app/guards/rostering.guard';
import { RosterComponent } from 'src/app/components/roster/roster.component';
import { ThemePreviewComponent } from 'src/app/components/theme-preview/theme-preview';

const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'roster',
    component: RosterComponent,
    canActivate: [MsalGuard, () => inject(RosteringGuard).canActivate()]
  },
  {
    path: 'theme-preview',
    component: ThemePreviewComponent,
    canActivate: [MsalGuard]
  },
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'login-failed',
    component: FailedComponent
  },
  // Catch-all route for any unmatched routes
  {
    path: '**',
    component: HomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Don't perform initial navigation in iframes or popups
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled', // Set to enabledBlocking to use Angular Universal
    useHash: true // Enable hash-based routing for better refresh handling
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
