// event-bus.service.ts
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventBusService {
  private keyStaffUpdatedSubject = new Subject<{ eventId: string, newKeyStaff: string, staffLabel: string }>();
  private staffMetadataSubject = new Subject<any[]>();
  private dateChangedSubject = new Subject<void>();

  keyStaffUpdated$ = this.keyStaffUpdatedSubject.asObservable();
  staffMetadata$ = this.staffMetadataSubject.asObservable();
  dateChanged$ = this.dateChangedSubject.asObservable();

  emitKeyStaffUpdated(eventData: { eventId: string, newKeyStaff: string, staffLabel: string }) {
    this.keyStaffUpdatedSubject.next(eventData);
  }

  emitStaffMetadata(ydata: any[]) {
    this.staffMetadataSubject.next(ydata);
  }

  emitDateChanged() {
    this.dateChangedSubject.next();
  }
}
