import { Injectable, ElementRef } from '@angular/core';
import { NotificationService } from './notification.service';
import { Observable } from 'rxjs';
import { SchedulerStatic } from "@dhx/scheduler";
import { ThemeService } from './theme.service';
import { format } from 'date-fns';
import { HelperService } from './helper.service';
import { SchedulerConfigService } from './scheduler-config.service';
import { TaskService } from './task.service';
import { ChangeManagementService } from './change-management.service';
import { StaffService } from './staff.service';
import { CarePlanDialogComponent } from '../components/care-plan-dialog/care-plan-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TaskItem } from '../models/task.model';
import { DragDropService } from './drag-drop.service';
import { ReallocateDialogComponent } from '../components/reallocate-dialog/reallocate-dialog.component';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { EventBusService } from './event-bus.service';
import { FilterService } from './filter.service';
import { YDataBuilderService } from './ydata-builder.service';
import { CarerStateService } from './carer-state.service';
import { SchedulerEventsService } from './scheduler-events.service';

@Injectable({
  providedIn: 'root'
})
export class RosterService {
  private _scheduler?: SchedulerStatic;
  private _schedulerContainer?: ElementRef;
  private lastLoadedDate: string | null = null;

  constructor(
    private helperService: HelperService,
    private schedulerConfigService: SchedulerConfigService,
    private taskService: TaskService,
    private changeManagementService: ChangeManagementService,
    private staffService: StaffService,
    private dialog: MatDialog,
    private eventBusService: EventBusService,
    private dragDropService: DragDropService,
    private filterService: FilterService,
    private ydataBuilderService: YDataBuilderService,
    private themeService: ThemeService,
    private carerStateService: CarerStateService,
    private notificationService: NotificationService,
    private schedulerEventsService: SchedulerEventsService
  ) {
    // Subscribe to theme changes
    this.themeService.isDark$.subscribe(isDark => {
      if (this._scheduler) {
        this._scheduler.setSkin(isDark ? "dark" : "material");
      }
    });

    // Subscribe to bulk reallocation events
    this.dragDropService.doubleClickOnCarer.subscribe(event => {
      this.handleDoubleClickEvent(event);
    });

    this.dragDropService.dropOnCarer.subscribe(event => {
      this.handleDropEvent(event);
    });

    this.eventBusService.keyStaffUpdated$.subscribe(eventData => {
      this.updateEventAfterKeyStaffChange(eventData.eventId, eventData.newKeyStaff, eventData.staffLabel);
    });

    // Subscribe to filter changes
    this.filterService.getFilterState().subscribe(() => {
      this.rebuildYData();
    });

    // Subscribe to view state changes
    this.carerStateService.viewState$.subscribe(() => {
      this.rebuildYData();
    });

    // Only subscribe to carer state changes when not in show hidden view
    this.carerStateService.carerStateChanged$.subscribe(() => {
      const viewState = this.carerStateService.getCurrentViewState();
      if (viewState !== 'showHidden') {
        this.rebuildYData();
      }
    });

    // Initial rebuild after a short delay to ensure everything is loaded
    setTimeout(() => {
      this.rebuildYData();
    }, 100);
  }

  setColumnWidth(width: string): void {
    if (this._scheduler) {
      this._scheduler.getView('timeline').column_width = width === 'wide' ? 80 : 40;
      this._scheduler.setCurrentView();
    }
  }

  private rebuildYData(): void {
    if (this._scheduler && this.lastLoadedDate) {
      // Get filtered ydata using YDataBuilderService to ensure section states are preserved
      const filteredYData = this.ydataBuilderService.buildFilteredYData(
        this.filterService.getCurrentFilterState()
      );

      // Store current events
      const events = this._scheduler.getEvents();

      // Clear scheduler and update with new filtered data
      this._scheduler.clearAll();
      this.schedulerConfigService.configureTimelineView(this._scheduler, filteredYData);

      // Re-parse events and update view
      this._scheduler.parse(events);
      this._scheduler.updateView();

      // Update pending changes count after rebuild
      const renderedCarerIds = this.filterService.getRenderedCarerIds();
      const visibleEvents = events.filter(event =>
        this.filterService.isTaskVisible(event, renderedCarerIds)
      );
      this.changeManagementService.updatePendingChangesCount(visibleEvents);
      
      // Check for simultaneous starts and update publish button
      this.schedulerConfigService.checkForConflictsAndUpdatePublishButton(events);
    }
  }

  setSchedulerContainer(container: ElementRef) {
    this._schedulerContainer = container;
  }

  /**
   * Updates the scheduler events service with the current scheduler instance
   * This ensures other services can access the current scheduler events
   */
  private updateSchedulerEventsService(): void {
    this.schedulerEventsService.setCurrentScheduler(this._scheduler);
  }

  initScheduler(): void {
    if (!this._schedulerContainer) {
      throw new Error('Scheduler container is not set');
    }

    this._scheduler = this.schedulerConfigService.initScheduler(this._schedulerContainer);
    this.updateSchedulerEventsService();
    this.schedulerConfigService.setupSchedulerEvents(this._scheduler);
    this.schedulerConfigService.setupSchedulerTemplates(this._scheduler);
    this.setupBusinessEvents(this._scheduler);
  }

  private setupBusinessEvents(scheduler: SchedulerStatic): void {
    // Handle date changes and task loading
    scheduler.attachEvent("onViewChange", (newView: string, newDate: Date | string) => {
      if (!(newDate instanceof Date)) {
        newDate = new Date(newDate);
      }
      if (isNaN(newDate.getTime())) {
        newDate = new Date();
      }

      const formattedDate = format(newDate, 'yyyy-MM-dd');
      if (formattedDate !== this.lastLoadedDate) {
        this.lastLoadedDate = formattedDate;
        this.loadTasks(formattedDate);
      }
    });

    // Handle lightbox events
    scheduler.attachEvent("onBeforeLightbox", (id: string) => {
      const event = scheduler.getEvent(id) as TaskItem;
      
      // Skip opening care plan dialog for staff events
      if (!event.residentID) {
        this.helperService.openSnackBar('Care plan not available for staff events', 'OK');
        return false;
      }
      
      const dialogRef = this.dialog.open(CarePlanDialogComponent, {
        width: '800px',
        data: { event }
      });
      
      // Handle dialog result
      dialogRef.afterClosed().subscribe(result => {
        if (result?.success) {
          // Check if reload is needed (triggered by care plan changes like key staff or timing updates)
          if (result.needsReload && this.lastLoadedDate) {
            // Reload tasks for the current date and preserve scroll position
            this.loadTasks(this.lastLoadedDate, true);
          } 
          else if (result?.event) {
            // Only update the specific event if provided (e.g., from 2PA changes)
            const updatedEvent = result.event as TaskItem;
            this.updateEventDetails(updatedEvent.id, updatedEvent);
            
            // Update pending changes count
            const events = scheduler.getEvents();
            const renderedCarerIds = this.filterService.getRenderedCarerIds();
            const visibleEvents = events.filter(event =>
              this.filterService.isTaskVisible(event, renderedCarerIds)
            );
            this.changeManagementService.updatePendingChangesCount(visibleEvents);
            
            // Check for conflicts and update publish button
            this.schedulerConfigService.checkForConflictsAndUpdatePublishButton(visibleEvents);
          }
        }
      });
      
      return false;
    });
  }

  /**
   * Updates an event's details in the scheduler.
   * @param eventId The ID of the event to update
   * @param updates Partial TaskItem containing the properties to update and their new values
   * @returns true if the update was successful, false otherwise
   */
  updateEventDetails(eventId: string, updates: Partial<TaskItem>): boolean {
    if (!this._scheduler) return false;

    const event = this._scheduler.getEvent(eventId);
    if (!event) return false;

    // Create a new event with the updated properties
    const newEvent = { ...event };
    Object.assign(newEvent, updates);

    // Delete the old event and add the new one to ensure proper update
    this._scheduler.deleteEvent(eventId);
    this._scheduler.addEvent(newEvent);

    // Update pending changes count
    const events = this._scheduler.getEvents();
    const renderedCarerIds = this.filterService.getRenderedCarerIds();
    const visibleEvents = events.filter(event =>
      this.filterService.isTaskVisible(event, renderedCarerIds)
    );
    this.changeManagementService.updatePendingChangesCount(visibleEvents);

    return true;
  }

  checkForUnpublishableChanges(): boolean {
    if (!this._scheduler) return false;

    const events = this._scheduler.getEvents();
    const unpublishableEvents = events.filter(event =>
      event.pending_change && new Date(event.start_date) < new Date()
    );

    if (unpublishableEvents.length > 0) {
      this.helperService.openSnackBar(
        `${unpublishableEvents.length} event(s) with past start dates will be ignored during publishing`,
        'OK'
      );
      return true;
    }
    return false;
  }

  loadTasks(date: string, restoreCurrentScrollPosition: boolean = false): void {
    // Save scroll position if requested
    const scrollPosition = restoreCurrentScrollPosition ? this.getScrollPosition() : null;

    this.taskService.loadTasks(date).subscribe(
      ({ tasks, ydata }) => {
        this._scheduler?.clearAll();
        this.staffService.setYData(ydata);

        // Get filtered ydata using YDataBuilderService to ensure section states are preserved
        const currentState = this.filterService.getCurrentFilterState();
        const filteredYData = this.ydataBuilderService.buildFilteredYData(currentState);

        this.schedulerConfigService.configureTimelineView(this._scheduler!, filteredYData);
        this._scheduler?.parse(tasks);
        
        // Update the scheduler events service after loading tasks
        this.updateSchedulerEventsService();

        // Apply auto-allocation based on persisted state
        if (currentState.autoAllocateEnabled) {
          this.taskService.reapplyAutoAllocation(this._scheduler!.getEvents());
        }

        const events = this._scheduler!.getEvents();
        const renderedCarerIds = this.filterService.getRenderedCarerIds();
        const visibleEvents = events.filter(event =>
          this.filterService.isTaskVisible(event, renderedCarerIds)
        );
        this.changeManagementService.updatePendingChangesCount(visibleEvents);
        this.taskService.findInvalidCarerStrings(this._scheduler!.getEvents());
        
        // Check for conflicts and update publish button
        this.schedulerConfigService.checkForConflictsAndUpdatePublishButton(visibleEvents);

        // Restore scroll position if it was saved
        if (scrollPosition) {
          this.setScrollPosition(scrollPosition);
        }
      },
      error => { this.notificationService.addMessage('critical', 'Error loading tasks: ' + error, error); }
    );
  }

  show_minical() {
    if (this._scheduler) {
      this.schedulerConfigService.show_minical(this._scheduler);
    }
  }

  get isLoading$(): Observable<boolean> {
    return this.taskService.isLoading$;
  }

  get scheduler(): SchedulerStatic | undefined {
    return this._scheduler;
  }

  get pendingChangesCount$(): Observable<number> {
    return this.changeManagementService.pendingChangesCount$;
  }

  get conflictsCount$(): Observable<number> {
    return this.changeManagementService.conflictsCount$;
  }

  get simultaneousStartsCount$(): Observable<number> {
    return this.changeManagementService.simultaneousStartsCount$;
  }

  get publishComplete$(): Observable<boolean> {
    return this.changeManagementService.publishComplete$;
  }

  getScrollPosition(): { left: number; top: number } {
    if (!this._scheduler) return { left: 0, top: 0 };
    const timeline = this._scheduler.getView();
    return timeline.getScrollPosition();
  }

  /**
   * Sets the scroll position of the timeline view
   * @param position Object containing either pixel coordinates or date/section information
   * Example usages:
   * - setScrollPosition({ left: 300, top: 500 }) // Scroll to specific pixel coordinates
   * - setScrollPosition({ date: new Date(), section: 4 }) // Scroll to specific date and section
   * - setScrollPosition(new Date()) // Scroll to specific date
   * - setScrollPosition(500) // Scroll to specific horizontal position
   */
  setScrollPosition(position: Date | number | { left?: number; top?: number; date?: Date; section?: number }): void {
    if (!this._scheduler) return;
    const timeline = this._scheduler.getView();
    timeline.scrollTo(position);
  }

  updateAutoAllocation(): void {
    if (!this._scheduler) return;

    const currentState = this.filterService.getCurrentFilterState();
    const events = this._scheduler.getEvents();
    if (currentState.autoAllocateEnabled) {
      // Reapply auto-allocation to tasks that are unallocated,
      // or that still match their original allocation
      const tasksToAutoAllocate = events.filter(event =>
        this.taskService.isTaskUnallocated(event) ||
        event.carer_string === event.original_carer_string
      );
      this.taskService.reapplyAutoAllocation(tasksToAutoAllocate);
    } else {
      this.taskService.revertAutoAllocation(events);
    }

    // Update each event in the scheduler
    events.forEach(event => this._scheduler!.updateEvent(event.id));

    // Update pending changes count
    const renderedCarerIds = this.filterService.getRenderedCarerIds();
    const visibleEvents = events.filter(event =>
      this.filterService.isTaskVisible(event, renderedCarerIds)
    );
    this.changeManagementService.updatePendingChangesCount(visibleEvents);

    // Check for conflicts and update publish button
    this.schedulerConfigService.checkForConflictsAndUpdatePublishButton(visibleEvents);

    // Refresh the scheduler view
    this._scheduler.updateView();
  }

  publishPendingChanges() {
    const allEvents = this._scheduler?.getEvents();
    if (!allEvents) return;

    const renderedCarerIds = this.filterService.getRenderedCarerIds();
    const visibleEvents = allEvents.filter(event =>
      this.filterService.isTaskVisible(event, renderedCarerIds)
    );
    
    // Pass the loadTasks function to the ChangeManagementService
    // so it can reload tasks after all changes are complete
    this.changeManagementService.publishPendingChanges(
      visibleEvents, 
      (eventId: string) => {
        this._scheduler?.updateEvent(eventId);
      },
      // Pass the loadTasks method as a callback
      (date: string, restoreScrollPosition: boolean) => {
        this.loadTasks(date, restoreScrollPosition);
      }
    );
  }

  cancelPublishing(): void {
    this.changeManagementService.cancelPublishing();
  }

  updateEventAfterKeyStaffChange(eventId: string, newKeyStaff: string, staffLabel: string) {
    const event = this._scheduler?.getEvent(eventId);
    if (event) {
      event.suggested_carer_string = String(newKeyStaff);
      event.suggested_carer_name = staffLabel;
      event.carer_string = String(newKeyStaff);
      event.pending_allocation_change = true;
      event.pending_change = true;
      const events = this._scheduler!.getEvents();
      const renderedCarerIds = this.filterService.getRenderedCarerIds();
      const visibleEvents = events.filter(event =>
        this.filterService.isTaskVisible(event, renderedCarerIds)
      );
      this.changeManagementService.updatePendingChangesCount(visibleEvents);
      
      // Check for conflicts and update publish button
      this.schedulerConfigService.checkForConflictsAndUpdatePublishButton(visibleEvents);
    }
  }

  revertChanges(eventId: string) {
    const scheduler = this._scheduler;
    if (!scheduler) return;

    const originalEvent: TaskItem = scheduler.getEvent(eventId);
    if (!originalEvent) return;
    const newEvent: TaskItem = { ...originalEvent };
    scheduler.deleteEvent(eventId);
    newEvent.carer_string = newEvent.original_carer_string;
    newEvent.start_date = newEvent.original_start_date;
    newEvent.end_date = newEvent.original_end_date;
    newEvent.pending_change = false;
    newEvent.pending_allocation_change = false;
    newEvent.pending_timing_change = false;
    newEvent.css_custom = '';
    scheduler.addEvent(newEvent);

    const events = scheduler.getEvents();
    const renderedCarerIds = this.filterService.getRenderedCarerIds();
    const visibleEvents = events.filter(event =>
      this.filterService.isTaskVisible(event, renderedCarerIds)
    );
    this.changeManagementService.updatePendingChangesCount(visibleEvents);
    
    // Check for conflicts and update publish button
    this.schedulerConfigService.checkForConflictsAndUpdatePublishButton(visibleEvents);
  }

  private handleDropEvent(eventData: { sourceCarerId: string; targetCarerId: string; targetName: string }): void {
    const sourceCarerId = eventData.sourceCarerId;
    const targetCarerId = eventData.targetCarerId;
    const targetName = eventData.targetName;

    this.showConfirmationDialog(sourceCarerId, targetCarerId, targetName);
  }

  private handleDoubleClickEvent(eventData: { carerId: string; cellText: string }): void {
    const carerId = eventData.carerId;
    const cellText = eventData.cellText;

    const scheduler = this.scheduler;
    if (!carerId || !scheduler) return;

    const events = scheduler.getEvents();
    const sourceEvents = events.filter(event =>
      String(event.carer_string) === String(carerId) &&
      new Date(event.start_date) >= new Date() &&
      this.filterService.isTaskVisible(event, new Set([Number(carerId)]))
    );

    if (sourceEvents.length === 0) {
      this.helperService.openSnackBar('No future events to reallocate', 'OK');
      return;
    }

    // Get siblings using the StaffService
    const siblings = this.staffService.getSiblings(sourceEvents[0]);

    const dialogRef = this.dialog.open(ReallocateDialogComponent, {
      width: '400px',
      data: {
        sourceStaffName: cellText,
        serviceCount: sourceEvents.length,
        siblings: siblings
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.confirmed) {
        this.bulkReallocateEvents(
          sourceEvents,
          carerId,
          result.targetStaffId
        );
      }
    });
  }

  private showConfirmationDialog(sourceCarerId: string, targetCarerId: string, targetName: string): void {
    const scheduler = this.scheduler;
    if (!scheduler) return;

    const events = scheduler.getEvents();
    const sourceEvents = events.filter(event =>
      String(event.carer_string) === String(sourceCarerId) &&
      new Date(event.start_date) >= new Date() &&
      this.filterService.isTaskVisible(event, new Set([Number(sourceCarerId)]))
    );

    if (sourceEvents.length === 0) {
      this.helperService.openSnackBar('No future events to reallocate', 'OK');
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: {
        title: 'Confirm Bulk Reallocation',
        message: `Are you sure you want to reallocate ${sourceEvents.length} events to ${targetName}?`,
        confirmText: 'Reallocate',
        cancelText: 'Cancel'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.bulkReallocateEvents(
          sourceEvents,
          sourceCarerId,
          targetCarerId
        );
      }
    });
  }

  bulkReallocateEvents(events: TaskItem[], sourceCarerId: string, targetCarerId: string): void {
    const scheduler = this._scheduler;
    if (!scheduler) return;

    let successCount = 0;
    let failCount = 0;

    events.forEach(event => {
      // Get the event directly from the scheduler
      const schedulerEvent = scheduler.getEvent(event.id);
      if (!schedulerEvent) return;

      // Apply the same validation as onBeforeEventChanged
      const isMoveAllowedResult = this.taskService.isMoveAllowed(
        schedulerEvent.section_key,
        schedulerEvent.section_label,
        targetCarerId,
        schedulerEvent.css_custom
      );

      if (!isMoveAllowedResult.disallowReason) {
        // Update the event using RosterService's updateEventDetails
        const updates: Partial<TaskItem> = {
          carer_string: targetCarerId,
          pending_allocation_change: true,
          pending_change: true,
          css_custom: isMoveAllowedResult.classString || ''
        };

        if (this.updateEventDetails(schedulerEvent.id, updates)) {
          successCount++;
        } else {
          failCount++;
        }
      } else {
        failCount++;
      }
    });

    const allEvents = scheduler.getEvents();
    const renderedCarerIds = this.filterService.getRenderedCarerIds();
    const visibleEvents = allEvents.filter(event =>
      this.filterService.isTaskVisible(event, renderedCarerIds)
    );
    this.changeManagementService.updatePendingChangesCount(visibleEvents);
    
    // Check for conflicts and update publish button
    this.schedulerConfigService.checkForConflictsAndUpdatePublishButton(visibleEvents);

    const message = successCount > 0
      ? `Successfully moved ${successCount} events, ready to be published${failCount > 0 ? ` (${failCount} failed)` : ''}`
      : 'No events could be reallocated';

    this.helperService.openSnackBar(message, 'OK');
  }
}
