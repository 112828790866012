import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { BaseApiService } from './api-base.service';
import { map } from 'rxjs/operators';
import { HelperService } from './helper.service';

export interface CorporateLabels {
  rn_label: string;
  pcw_label: string;
  support_label: string;
  hpac_label: string;
  independent_label: string;
}

@Injectable({
  providedIn: 'root'
})
export class CorporateService extends BaseApiService {
  private readonly STORAGE_KEY = 'corporate_labels';
  private readonly EXPIRY_DAYS = 7;

  constructor(http: HttpClient, msalService: MsalService, private helperService: HelperService) {
    super(http, msalService);
  }

  getLabels(): Observable<CorporateLabels> {
    // Check local storage first
    const stored = localStorage.getItem(this.STORAGE_KEY);
    if (stored) {
      const { data, timestamp } = JSON.parse(stored);
      const age = Date.now() - timestamp;
      const expiryMs = this.EXPIRY_DAYS * 24 * 60 * 60 * 1000;
      
      if (age < expiryMs) {
        return new Observable(observer => {
          observer.next(data);
          observer.complete();
        });
      }
    }

    // Fetch fresh data if not in storage or expired
    return this.get<CorporateLabels>(`${this.apiUrl}/corporate/labels/`).pipe(
      map(labels => {
        // Store in localStorage with timestamp
        localStorage.setItem(this.STORAGE_KEY, JSON.stringify({
          data: labels,
          timestamp: Date.now()
        }));
        return labels;
      })
    );
  }

  /**
   * Calls the backend endpoint to refresh meta cache.
   * Triggers the GET request to "/api/ecase/{env}/refresh/meta".
   */
  refreshMetaCache(env: string): Observable<any> {
    return this.get(`${this.apiUrl}/ecase/${env}/refresh/meta`);
  }

  /**
   * Calls the backend endpoint to refresh images cache.
   * Triggers the GET request to "/api/ecase/${env}/refresh/images".
   */
  refreshImages(env: string): Observable<any> {
    return this.get(`${this.apiUrl}/ecase/${env}/refresh/images`);
  }
}
