import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationService, NotificationMessage } from '../../services/notification.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HelperService } from '../../services/helper.service';
import { Subscription } from 'rxjs';
import { TaskItem } from '../../models/task.model';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent implements OnInit {
  messages: (NotificationMessage & { tooltipHtml?: SafeHtml })[] = [];
  private subscription?: Subscription;
  
  constructor(
    public dialogRef: MatDialogRef<NotificationDialogComponent>,
    private notificationService: NotificationService,
    private sanitizer: DomSanitizer,
    private helperService: HelperService
  ) {
    // Removed custom panel class to avoid interfering with Angular Material's focus management.

    // Subscribe to messages and pre-render tooltips
    this.subscription = this.notificationService.getMessages().subscribe((messages: NotificationMessage[]) => {
      this.messages = [...messages]
        .map(msg => ({
          ...msg,
          tooltipHtml: msg.taskItem ? this.renderTaskTooltip(msg.taskItem) : undefined
        }))
        .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
    });

    // Clean up subscription when dialog closes
    this.dialogRef.afterClosed().subscribe(() => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    });
    // After the dialog opens, shift focus to the Close button to prevent focus remaining on the triggering element.
    this.dialogRef.afterOpened().subscribe(() => {
      const closeButton = document.querySelector('.notification-dialog button[mat-raised-button]');
      if (closeButton) {
        (closeButton as HTMLElement).focus();
      }
    });
  }
  
  private renderTaskTooltip(task: TaskItem): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      this.helperService.get_tooltip_text(
        new Date(task.start_date),
        new Date(task.end_date),
        task
      )
    );
  }

  getLevelIcon(level: string): string {
    switch (level) {
      case 'critical': return 'error';
      case 'warning': return 'warning';
      case 'info': return 'info';
      default: return 'info';
    }
  }

  getLevelClass(level: string): string {
    return `level-${level}`;
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  ngOnInit(): void {
    const activeElement = document.activeElement as HTMLElement;
    if (activeElement) {
      activeElement.blur();
    }
  }

  dismissAll() {
    this.notificationService.clearMessages();
    this.dialogRef.close();
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
