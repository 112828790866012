import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap, catchError, switchMap } from 'rxjs/operators';
import { StaffService } from './staff.service';
import { GraphService } from './graph.service';
import { Feature, UserSession, FEATURE_PERMISSIONS } from '../models/permissions.model';
import { ParsedStaffDetails } from '../models/staff.model';

@Injectable({
    providedIn: 'root'
})
export class PermissionsService {
    private readonly CACHE_DURATION = 4 * 60 * 60 * 1000; // 4 hours in milliseconds
    private userSessionSubject = new BehaviorSubject<UserSession | null>(null);
    public userSession$ = this.userSessionSubject.asObservable();

    constructor(
        private staffService: StaffService,
        private graphService: GraphService
    ) {}

    public initializeUserSession(): Observable<UserSession | null> {
        const cachedSession = this.getCachedSession();
        if (cachedSession) {
            this.userSessionSubject.next(cachedSession);
            return of(cachedSession);
        }

        return this.loadUserSession();
    }

    public loadUserSession(): Observable<UserSession | null> {
        return this.graphService.getUserProfile().pipe(
            map(profile => profile.mail),
            tap(email => {
                if (!email) throw new Error('No email found in profile');
            }),
            switchMap((email: string) => this.staffService.getStaffDetails(email)),
            map((staffDetails: ParsedStaffDetails) => {
                const session: UserSession = {
                    firstName: staffDetails.FirstName,
                    lastName: staffDetails.LastName,
                    roles: staffDetails.roles,
                    lastUpdated: Date.now()
                };
                this.cacheSession(session);
                this.userSessionSubject.next(session);
                return session;
            }),
            catchError(error => {
                return of(null);
            })
        );
    }

    public hasFeatureAccess(feature: Feature['name']): Observable<boolean> {
        return this.userSession$.pipe(
            map(session => {
                if (!session) return false;
                
                const featureConfig = FEATURE_PERMISSIONS.find(f => f.name === feature);
                if (!featureConfig) return false;

                return session.roles.some(role => featureConfig.allowedRoles.includes(role));
            })
        );
    }

    public hasAnyFeatureAccess(features: Feature['name'][]): Observable<boolean> {
        return this.userSession$.pipe(
            map(session => {
                if (!session) return false;
                
                return features.some(featureName => {
                    const featureConfig = FEATURE_PERMISSIONS.find(f => f.name === featureName);
                    if (!featureConfig) return false;
                    return session.roles.some(role => featureConfig.allowedRoles.includes(role));
                });
            })
        );
    }

    private getCachedSession(): UserSession | null {
        const cached = localStorage.getItem('userSession');
        if (!cached) return null;

        const session: UserSession = JSON.parse(cached);
        const now = Date.now();

        if (now - session.lastUpdated > this.CACHE_DURATION) {
            localStorage.removeItem('userSession');
            return null;
        }

        return session;
    }

    private cacheSession(session: UserSession): void {
        localStorage.setItem('userSession', JSON.stringify(session));
    }

    public getTimeBasedGreeting(firstName: string): string {
        const hour = new Date().getHours();
        if (hour < 12) return `Good morning, ${firstName}`;
        if (hour < 17) return `Good afternoon, ${firstName}`;
        return `Good evening, ${firstName}`;
    }
}
