<mat-toolbar [ngClass]="isUAT ? 'uat-env' : 'prod-env'" *ngIf="!isRosterPage">
  <a href="/" (click)="reloadPage($event)" style="text-decoration: none; color: inherit;">
    <img src="assets/symbol/symbol-shadow-500.png" alt="logo" class="logo" />
  </a>
  <a href="/" (click)="reloadPage($event)" style="text-decoration: none; color: inherit;">
    <span class="header-title"><span class="libre-franklin-regular">care</span><span class="libre-franklin-extralight">hub systems</span></span>
  </a>
  <button mat-button 
    *ngIf="loginDisplay"
    class="env-toggle"
    [ngClass]="isUAT ? 'uat-button' : 'prod-button'"
    (click)="toggleEnvironment()">
    {{isUAT ? 'UAT' : 'PRODUCTION'}}
  </button>
  <app-user-details></app-user-details>
  <div class="toolbar-spacer"></div>
  <button *ngIf="hasSchedulerAccess$ | async" mat-raised-button [routerLink]="['roster']">
    {{schedulerButtonText$ | async}}
  </button>
  <button mat-raised-button (click)="loginRedirect()" *ngIf="!loginDisplay">Login</button>
  <button mat-raised-button (click)="logout()" *ngIf="loginDisplay">Logout</button>
</mat-toolbar>

<div class="container">
  <router-outlet *ngIf="!isIframe"></router-outlet>
</div>
