import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { format } from 'date-fns';
import { RosterService } from 'src/app/services/roster.service';

interface StaffOption {
  key: number;
  label: string;
  level: number;
  $parent: number;
}

@Component({
  selector: 'app-custom-lightbox',
  templateUrl: './custom-lightbox.component.html',
  styleUrls: ['./custom-lightbox.component.css']
})
export class CustomLightboxComponent implements OnInit {
  currentKeyStaffCarerId: number | null = null;
  selectedKeyStaff: number | null = null;
  staffOptions: StaffOption[] = [];
  formattedTime: string = '';
  originalTime: string = '';
  hasPendingChange: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<CustomLightboxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private rosterService: RosterService
  ) { }

  ngOnInit() {
    if (this.data.siblings && Array.isArray(this.data.siblings) && this.data.event) {
      this.staffOptions = this.data.siblings;
      
      this.currentKeyStaffCarerId = this.data.event.suggested_carer_string
        ? parseInt(this.data.event.suggested_carer_string, 10)
        : null;
      
      if (this.currentKeyStaffCarerId !== null) {
        const matchingStaff = this.staffOptions.find(staff => staff.key === this.currentKeyStaffCarerId);
        if (matchingStaff) {
          this.selectedKeyStaff = matchingStaff.key;
        }
      }

      this.hasPendingChange = this.data.event.pending_change;
      this.updateTimeDisplay();
    }
  }

  updateTimeDisplay() {
    const event = this.data.event;
    if (event.pending_timing_change) {
      const currentTimeRange = `${format(new Date(event.start_date), 'HH:mm')} - ${format(new Date(event.end_date), 'HH:mm')}`;
      const originalTimeRange = `${format(new Date(event.original_start_date), 'HH:mm')} - ${format(new Date(event.original_end_date), 'HH:mm')}`;
      this.formattedTime = `${currentTimeRange} (was ${originalTimeRange})`;
    } else {
      this.formattedTime = `${format(new Date(event.start_date), 'HH:mm')} - ${format(new Date(event.end_date), 'HH:mm')}`;
    }
  }

  onSave() {
    if (this.selectedKeyStaff !== null) {
      if (this.selectedKeyStaff !== this.currentKeyStaffCarerId) {
        const confirmMessage = "This change will affect all future occurrences of this recurring event. Do you want to proceed?";

        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '300px',
          data: {
            title: 'Confirm Changes',
            message: confirmMessage
          }
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            const selectedStaff = this.staffOptions.find(staff => staff.key === this.selectedKeyStaff);
            if (selectedStaff) {
              this.dialogRef.close({ 
                action: 'save', 
                newKeyStaff: selectedStaff.key.toString(),
                staffLabel: selectedStaff.label
              });
            }
          }
        });

      } else {
        this.dialogRef.close();
      }
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  onRevert() {
    this.rosterService.revertChanges(this.data.event.id);
    this.dialogRef.close();
  }
}
