import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Section, StaffItem } from '../models/ydata.model';
import { TaskItem } from '../models/task.model';
import { BaseApiService } from './api-base.service';
import { HelperService } from './helper.service';
import { StaffDetails, ParsedStaffDetails } from '../models/staff.model';

@Injectable({
  providedIn: 'root'
})
export class StaffService extends BaseApiService {
  private _ydata: any[] = [];
  private env: string;

  constructor(
    http: HttpClient,
    msalService: MsalService,
    private helperService: HelperService
  ) {
    super(http, msalService);
    this.env = this.helperService.getStoredValue('ecase_env', 'prod');
  }

  // Get staff details by email
  public getStaffDetails(email: string): Observable<ParsedStaffDetails> {
    return this.get<StaffDetails>(`${this.apiUrl}/ecase/${this.env}/staff-details/?email=${encodeURIComponent(email)}`).pipe(
      map(details => this.parseStaffDetails(details))
    );
  }

  // Refresh staff details
  public refreshStaffCache(): Observable<any> {
    return this.get(`${this.apiUrl}/ecase/${this.env}/refresh/staff-details`);
  }

  // Parse staff details to split roles into array
  private parseStaffDetails(details: StaffDetails): ParsedStaffDetails {
    const { Role, ...rest } = details;
    return {
      ...rest,
      roles_string: Role || '',
      roles: Role ? Role.split(',').map(role => role.trim()).filter(role => role !== '') : []
    };
  }

  setYData(ydata: any[]) {
    this._ydata = ydata;
  }

  getSiblings(event: TaskItem): StaffItem[] {
    if (!event || !this._ydata || !event.carer_string) {
      return [];
    }

    const carerKey = parseInt(event.carer_string, 10);
    const findParentSection = (sections: (Section | StaffItem)[]): Section | null => {
      for (const item of sections) {
        if ('children' in item) {
          const section = item as Section;
          if (section.children) {
            const foundStaff = section.children.find((child: StaffItem | Section) => 'key' in child && child.key === carerKey);
            if (foundStaff) {
              return section;
            }
            const nestedResult = findParentSection(section.children);
            if (nestedResult) {
              return nestedResult;
            }
          }
        }
      }
      return null;
    };

    for (const roleObject of this._ydata) {
      const parentSection = findParentSection(roleObject.children);
      if (parentSection && parentSection.children) {
        return parentSection.children as StaffItem[];
      }
    }

    return [];
  }

  getStaffLabel(carer_string: string): string | undefined {
    if (!this._ydata || !carer_string) {
      return undefined;
    }

    const carerKey = parseInt(carer_string, 10);
    
    const findStaffItem = (items: (Section | StaffItem)[]): string | undefined => {
      for (const item of items) {
        if ('children' in item) {
          // This is a Section
          const section = item as Section;
          if (section.children) {
            const result = findStaffItem(section.children);
            if (result) {
              return result;
            }
          }
        } else {
          // This is a StaffItem
          const staffItem = item as StaffItem;
          if (staffItem.key === carerKey) {
            return staffItem.label;
          }
        }
      }
      return undefined;
    };

    for (const roleObject of this._ydata) {
      const result = findStaffItem(roleObject.children);
      if (result) {
        return result;
      }
    }

    return undefined;
  }
}
