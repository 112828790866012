export interface Feature {
    name: 'roster' | 'reallocate';
    allowedRoles: string[];
}

export interface UserSession {
    firstName: string;
    lastName: string;
    roles: string[];
    lastUpdated: number; // timestamp
}

export const FEATURE_PERMISSIONS: Feature[] = [
    {
        name: 'roster',
        allowedRoles: ['Technology Manager', 'Scheduler', 'Case Manager', 'Managing Director', 'ICT HelpDesk', 'Community Manager']
    },
    {
        name: 'reallocate',
        allowedRoles: ['Care Business Manager', 'RN', 'Resort Manager']
    }
];
