export interface EcaseEvent {
    id: string;
    text: string;
    ActivityColorCode: string;
    start_date: string;
    end_date: string;
    isGroupActivity: boolean;
    isGroupHomeService: boolean;
    gpHeaderID: string;
    gpHeaderName: string;
    epServiceID: string;
    isECH: boolean;
    isStaffEvent: boolean;
    isRostered: boolean;
    isCancelled: boolean;
    activity_instance: ActivityInstance;
    activity: Activity;
    client: Client;
    client_string: string;
    carer_allocations: any[];
    carer_string: string;
    y_axis_keys: string;
    vendor: Vendor;
    groupHome: GroupHome;
    CCQuoteDetailID: string | null;
    ModifiedDate: string;
    ModifiedUser: string;
    Instruction: string | null;
    IsUsingFleetVehicle: boolean;
    AdHoc: boolean;
    Sleepover: string | null;
    RejectDate: string | null;
    RejectStaff: string | null;
    RejectClient: string | null;
    PreviousRescheduleReason: string;
    PreviousRescheduleReasonNote: string;
    NewRescheduleStartTime: string | null;
    NewRescheduleEndTime: string | null;
    VehicleRegistration: string | null;
    VehicleMake: string | null;
    VehicleModel: string | null;
    EstimatedTravelTime: string | null;
    suggested_carer_string: string | null;
    suggested_carer_name: string | null;
    activity_roles: number[];
    unallocated_key: number | null;
    section_key: number | null;
    section_label: string | null;
}

export interface ActivityInstance {
    activity_instance_id: string;
    activity_instance_status_code: string;
    activity_instance_status: string;
    activity_instance_staff_minimum: number;
    activity_instance_staff_maximum: number;
    activity_instance_priority_name: string;
    activity_instance_start_date_time: string;
    activity_instance_end_date_time: string;
    activity_instance_is_floating: boolean;
    activity_instance_duration: number | null;
    activity_instance_is_locked: boolean;
    activity_instance_location: string | null;
    activity_instance_instructions: string | null;
    activity_instance_cancelled_status: string;
    activity_instance_alertsNum: string;
    activity_instance_cancel_charge_client: boolean;
    activity_instance_cancel_pay_staff: boolean;
    activity_instance_cancel_pay_vendor: boolean | null;
    activity_instance_cancel_reason: string | null;
    activity_instance_cancel_description: string | null;
}

export interface Activity {
    services: Service[];
    location: string | null;
    outletID: string | null;
    activityType: string | null;
    activity_sell_price: number | null;
}

export interface Service {
    ActivityID: string;
    ActivityName: string;
}

export interface ClientDetails {
    residentID: string;
    customer_Code: string;
    firstName: string;
    lastName: string;
    residentFullName: string;
    dob: string
    gender: string;
    aliasName: string | null;
    aliasCheck: string;
    StreetAddress: string;
}

export interface ClientPreferredCarer {
    ClientPreferredCarerID: string;
    ClientPreferredCarerName: string;
    ClientPreferredCarerExcluded: string;
}

export interface Client {
    client_id: string;
    client_code: string | null;
    client_name: string;
    client_region_code: string | null;
    client_region_description: string;
    client_mandatory_language: string | null;
    client_preferred_language: string;
    client_preferred_gender: string;
    client_excluded_gender: string;
    client_preferred_carers: ClientPreferredCarer[];
    client_excluded_carers: any[] | null;
    client_traits: any[];
    client_address: any[];
    client_details: ClientDetails[];
}

export interface Vendor {
    vendor_id: string;
    vendor_name: string;
    vendor_is_main_vendor: boolean;
}

export interface GroupHome {
    groupHomeId: string | null;
    Name: string | null;
}

export const parseEcaseEvent = (data: any): EcaseEvent => {
    return {
        id: data.id,
        text: data.text,
        ActivityColorCode: data.ActivityColorCode,
        start_date: data.start_date,
        end_date: data.end_date,
        isGroupActivity: data.isGroupActivity,
        isGroupHomeService: data.isGroupHomeService,
        gpHeaderID: data.gpHeaderID,
        gpHeaderName: data.gpHeaderName,
        epServiceID: data.epServiceID,
        isECH: data.isECH,
        isStaffEvent: data.isStaffEvent,
        isRostered: data.isRostered,
        isCancelled: data.isCancelled,
        activity_instance: {
            activity_instance_id: data.activity_instance.activity_instance_id,
            activity_instance_status_code: data.activity_instance.activity_instance_status_code,
            activity_instance_status: data.activity_instance.activity_instance_status,
            activity_instance_staff_minimum: data.activity_instance.activity_instance_staff_minimum,
            activity_instance_staff_maximum: data.activity_instance.activity_instance_staff_maximum,
            activity_instance_priority_name: data.activity_instance.activity_instance_priority_name,
            activity_instance_start_date_time: data.activity_instance.activity_instance_start_date_time,
            activity_instance_end_date_time: data.activity_instance.activity_instance_end_date_time,
            activity_instance_is_floating: data.activity_instance.activity_instance_is_floating,
            activity_instance_duration: data.activity_instance.activity_instance_duration,
            activity_instance_is_locked: data.activity_instance.activity_instance_is_locked,
            activity_instance_location: data.activity_instance.activity_instance_location,
            activity_instance_instructions: data.activity_instance.activity_instance_instructions,
            activity_instance_cancelled_status: data.activity_instance.activity_instance_cancelled_status,
            activity_instance_alertsNum: data.activity_instance.activity_instance_alertsNum,
            activity_instance_cancel_charge_client: data.activity_instance.activity_instance_cancel_charge_client,
            activity_instance_cancel_pay_staff: data.activity_instance.activity_instance_cancel_pay_staff,
            activity_instance_cancel_pay_vendor: data.activity_instance.activity_instance_cancel_pay_vendor,
            activity_instance_cancel_reason: data.activity_instance.activity_instance_cancel_reason,
            activity_instance_cancel_description: data.activity_instance.activity_instance_cancel_description,
        },
        activity: {
            services: data.activity.services.map((service: any) => ({
                ActivityID: service.ActivityID,
                ActivityName: service.ActivityName,
            })),
            location: data.activity.location,
            outletID: data.activity.outletID,
            activityType: data.activity.activityType,
            activity_sell_price: data.activity.activity_sell_price,
        },
        client: {
            client_id: data.client.client_id,
            client_code: data.client.client_code,
            client_name: data.client.client_name,
            client_region_code: data.client.client_region_code,
            client_region_description: data.client.client_region_description,
            client_mandatory_language: data.client.client_mandatory_language,
            client_preferred_language: data.client.client_preferred_language,
            client_preferred_gender: data.client.client_preferred_gender,
            client_excluded_gender: data.client.client_excluded_gender,
            client_preferred_carers: data.client.client_preferred_carers.map((carer: any) => ({
                ClientPreferredCarerID: carer.ClientPreferredCarerID,
                ClientPreferredCarerName: carer.ClientPreferredCarerName,
                ClientPreferredCarerExcluded: carer.ClientPreferredCarerExcluded
            })),
            client_excluded_carers: data.client.client_excluded_carers,
            client_traits: data.client.client_traits,
            client_address: data.client.client_address,
            client_details: data.client.client_details.map((clientDetails: any) => ({
                residentID: clientDetails.residentID,
                customer_Code: clientDetails.customer_Code,
                firstName: clientDetails.firstName,
                lastName: clientDetails.lastName,
                residentFullName: clientDetails.residentFullName,
                dob: clientDetails.dob,
                gender: clientDetails.gender,
                aliasName: clientDetails.aliasName,
                aliasCheck: clientDetails.aliasCheck,
                StreetAddress: clientDetails.StreetAddress
            })),
        },
        client_string: data.client_string,
        carer_allocations: data.carer_allocations,
        carer_string: data.carer_string,
        y_axis_keys: data.y_axis_keys,
        vendor: {
            vendor_id: data.vendor.vendor_id,
            vendor_name: data.vendor.vendor_name,
            vendor_is_main_vendor: data.vendor.vendor_is_main_vendor,
        },
        groupHome: {
            groupHomeId: data.groupHome.groupHomeId,
            Name: data.groupHome.Name,
        },
        CCQuoteDetailID: data.CCQuoteDetailID,
        ModifiedDate: data.ModifiedDate,
        ModifiedUser: data.ModifiedUser,
        Instruction: data.Instruction,
        IsUsingFleetVehicle: data.IsUsingFleetVehicle,
        AdHoc: data.AdHoc,
        Sleepover: data.Sleepover,
        RejectDate: data.RejectDate,
        RejectStaff: data.RejectStaff,
        RejectClient: data.RejectClient,
        PreviousRescheduleReason: data.PreviousRescheduleReason,
        PreviousRescheduleReasonNote: data.PreviousRescheduleReasonNote,
        NewRescheduleStartTime: data.NewRescheduleStartTime,
        NewRescheduleEndTime: data.NewRescheduleEndTime,
        VehicleRegistration: data.VehicleRegistration,
        VehicleMake: data.VehicleMake,
        VehicleModel: data.VehicleModel,
        EstimatedTravelTime: data.EstimatedTravelTime,
        suggested_carer_string: data.suggested_carer_string,
        suggested_carer_name: data.suggested_carer_name,
        activity_roles: data.activity_roles,
        unallocated_key: data.unallocated_key,
        section_key: data.section_key,
        section_label: data.section_label
    };
};
