import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, tap, switchMap } from 'rxjs/operators';
import { EventService } from './event.service';
import { PermissionsService } from './permissions.service';
import { NotificationService } from './notification.service';
import { AppointmentStatus } from "./appointmentStatus";
import { RoleObject, AllowedDestinations, Section, StaffItem } from '../models/ydata.model';
import { TaskItem, TaskMoveAllowedResult } from '../models/task.model';
import { EcaseEvent } from '../models/ecaseevent.model';
import { ClientType, TimeOfDay } from '../models/filter.model';
import { FilterService } from './filter.service';
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  private _ydata: RoleObject[] = [];
  private _allowedDestinations: AllowedDestinations = {};
  private _tasks: TaskItem[] = [];
  private _isLoading = new BehaviorSubject<boolean>(false);
  private _counts = new BehaviorSubject<{ [key: string]: number }>({});
  public counts$ = this._counts.asObservable();

  constructor(
    private eventService: EventService,
    private permissionsService: PermissionsService,
    private notificationService: NotificationService,
    private filterService: FilterService
  ) { }

  loadTasks(date: string): Observable<{ tasks: TaskItem[], ydata: RoleObject[] }> {
    this._isLoading.next(true);
    // Clear notifications before loading new data
    this.notificationService.clearMessages();
    
    return this.eventService.getTimelineEvents(date).pipe(
      switchMap(response => {
        return this.permissionsService.hasFeatureAccess('reallocate').pipe(
          map(isReallocateOnly => {
            let { ecaseevents, ydata, allowedDestinations } = response;
            
            if (isReallocateOnly) {
              // Filter for reallocate users
              ecaseevents = this.filterAllocatedServices(ecaseevents);
              ydata.yunitData = this.filterYunitData(ydata.yunitData);
            }

            this._ydata = ydata.yunitData;
            this._allowedDestinations = allowedDestinations;

            if (ecaseevents.length === 0) {
              this._tasks = [];
            } else {
              this._tasks = ecaseevents.map(ecaseEvent => this.createTaskItem(ecaseEvent));
            }
            
            this.updateCounts();
            return { tasks: this._tasks, ydata: this._ydata };
          })
        );
      }),
      tap(() => this._isLoading.next(false))
    );
  }

  private filterAllocatedServices(events: EcaseEvent[]): EcaseEvent[] {
    return events.filter(event => event.carer_allocations.length > 0);
  }

  private filterYunitData(yunitData: RoleObject[]): RoleObject[] {
    // First pass: Deep clone and filter out unwanted items
    const filteredData = yunitData.map(item => this.filterYunitItem(item)).filter(Boolean) as RoleObject[];
    
    // Second pass: Remove empty parent sections
    return filteredData.filter(item => {
      if (item.key >= 1000000 && item.key < 2000000) {
        return item.children && item.children.length > 0;
      }
      return true;
    });
  }

  private filterYunitItem(item: RoleObject | Section | StaffItem): RoleObject | Section | StaffItem | null {
    // Remove only unallocated sections (2000000-2999999)
    if (item.key >= 2000000 && item.key < 3000000) {
      return null;
    }

    // Deep clone the item
    const clonedItem: any = { ...item };

    // Recursively filter children if they exist
    if ('children' in item && Array.isArray(item.children)) {
      clonedItem.children = item.children
        .map(child => this.filterYunitItem(child))
        .filter(Boolean);
    }

    return clonedItem;
  }

  createTaskItem = (ecaseEvent: EcaseEvent): TaskItem => {
    // Handle client preferred carers safely for staff events
    const preferredCarers = ecaseEvent.client.client_preferred_carers
      .filter(carer => carer.ClientPreferredCarerExcluded === 'N')
      .map(carer => carer.ClientPreferredCarerID);

    const excludedCarers = ecaseEvent.client.client_preferred_carers
      .filter(carer => carer.ClientPreferredCarerExcluded === 'Y')
      .map(carer => carer.ClientPreferredCarerID);

    // Get client details safely for staff events
    const clientDetails = ecaseEvent.client.client_details && ecaseEvent.client.client_details.length > 0 
      ? ecaseEvent.client.client_details[0] 
      : null;
      
    // Determine if this is a two-person assist service
    const is2pa = ecaseEvent.activity_instance.activity_instance_staff_maximum > 1;
    
    // Determine if this is a partially allocated 2PA service
    // A 2PA is partially allocated if:
    // 1. It is a 2PA service (is_2pa = true)
    // 2. It has a carer_string (not unallocated)
    // 3. The carer_string doesn't contain a comma (only one carer allocated)
    // 4. The carer_string is not an unallocated carer ID (2000000-2999999)
    const carerString = ecaseEvent.carer_string || String(ecaseEvent.unallocated_key);
    const isUnallocated = !ecaseEvent.carer_string || this.isCarerIdUnallocated(carerString);
    const hasMultipleCarers = carerString.includes(',');
    const partiallyAllocated = is2pa && !isUnallocated && !hasMultipleCarers;
    
    // Determine the CSS class based on allocation status
    let cssEcase = this.getEcaseCss(ecaseEvent);
    if (partiallyAllocated) {
      cssEcase = 'appointment-partially-allocated';
    }

    let taskItem = {
      start_date: ecaseEvent.start_date,
      end_date: ecaseEvent.end_date,
      text: ecaseEvent.text,
      id: ecaseEvent.id,
      carer_string: carerString,
      carer_name: ecaseEvent.carer_allocations.length > 0 ? ecaseEvent.carer_allocations[0].carer_name : "",
      unallocated_key: ecaseEvent.unallocated_key || 0,
      CCQuoteDetailID: ecaseEvent.CCQuoteDetailID || "",
      css_ecase: cssEcase,
      css_custom: "",
      residentID: clientDetails ? clientDetails.residentID : null,
      client_name: ecaseEvent.client.client_name,
      client_gender: clientDetails ? clientDetails.gender : null,
      is_staff_event: ecaseEvent.isStaffEvent,
      section_key: ecaseEvent.section_key || 0,
      section_label: ecaseEvent.section_label || "",
      is_hpac: ecaseEvent.is_hpac,
      is_pm_service: ecaseEvent.is_pm_service,
      suggested_carer_string: ecaseEvent.suggested_carer_string,
      suggested_carer_name: ecaseEvent.suggested_carer_name,
      preferred_carers: preferredCarers,
      excluded_carers: excludedCarers,
      is_2pa: is2pa,
      partially_allocated: partiallyAllocated,
      pending_allocation_change: false,
      pending_timing_change: false,
      pending_change: false,
      pending_change_conflicts_with: null,
      original_start_date: ecaseEvent.start_date,
      original_end_date: ecaseEvent.end_date,
      original_carer_string: carerString,
      conflict_key: `${carerString}-${format(new Date(ecaseEvent.start_date), 'yyyy-MM-dd HH:mm:ss')}`
    };
    this.autoAllocateKeyStaff(taskItem);
    return taskItem;
  }
  
  // Helper method to check if a carer ID is in the unallocated range
  private isCarerIdUnallocated(carerId: string): boolean {
    const carerIdNum = parseInt(carerId);
    return !isNaN(carerIdNum) && carerIdNum >= 2000000 && carerIdNum < 3000000;
  }

  getConflictingEventId(event: TaskItem, events: TaskItem[]): string | null {
    const formattedDate = format(new Date(event.start_date), 'yyyy-MM-dd HH:mm:ss');
    
    // Ensure carer_string is a string
    const eventCarerString = String(event.carer_string);
    
    // For multisection events, we need to check each carer ID separately
    if (eventCarerString.includes(',')) {
      const carerIds = eventCarerString.split(',').map(id => id.trim());
      
      // Check each event for conflicts with any of our carers
      for (const e of events) {
        // Skip the current event
        if (e.id === event.id) continue;
        
        // Skip events at different times
        if (format(new Date(e.start_date), 'yyyy-MM-dd HH:mm:ss') !== formattedDate) continue;
        
        // Ensure e.carer_string is a string
        const eCarerString = String(e.carer_string);
        
        // Check if there's any overlap in carer IDs
        if (eCarerString.includes(',')) {
          // Both are multisection events
          const eCarerIds = eCarerString.split(',').map(id => id.trim());
          // Check for any common carer IDs
          if (carerIds.some(id => eCarerIds.includes(id))) {
            return e.id;
          }
        } else {
          // Other event is single section
          if (carerIds.includes(eCarerString)) {
            return e.id;
          }
        }
      }
      return null;
    } else {
      // For single section events
      // Check each event for conflicts
      for (const e of events) {
        // Skip the current event
        if (e.id === event.id) continue;
        
        // Skip events at different times
        if (format(new Date(e.start_date), 'yyyy-MM-dd HH:mm:ss') !== formattedDate) continue;
        
        // Ensure e.carer_string is a string
        const eCarerString = String(e.carer_string);
        
        if (eCarerString.includes(',')) {
          // Other event is multisection
          const eCarerIds = eCarerString.split(',').map(id => id.trim());
          if (eCarerIds.includes(eventCarerString)) {
            return e.id;
          }
        } else {
          // Both are single section
          if (eCarerString === eventCarerString) {
            return e.id;
          }
        }
      }
      return null;
    }
  }

  autoAllocateKeyStaff(task: TaskItem): void {
    // Check if auto-allocation is enabled in filter state
    const currentState = this.filterService.getCurrentFilterState();
    if (!currentState.autoAllocateEnabled) {
      return;
    }

    if (this.isTaskUnallocated(task) && task.suggested_carer_string) {
      const isMoveAllowedResult = this.isMoveAllowed(task.section_key, task.section_label, task.suggested_carer_string, task.css_custom);

      if (!isMoveAllowedResult.disallowReason) {
      task.carer_string = task.suggested_carer_string;
        task.pending_allocation_change = true;
        task.pending_change = true;
        task.css_custom = isMoveAllowedResult.classString;
      } else {
        const message = `The key staff for this ${task.section_label} service is ${task.suggested_carer_string} ${task.suggested_carer_name}.  Their staff profile in ecase does not have the role required for ${task.section_label} services.  This service could not be allocated to them, and has been left unallocated.`;
        this.notificationService.addMessage('warning', message, task);
      }
    }
  }

  reapplyAutoAllocation(tasks: TaskItem[]): void {
    tasks.forEach(task => {
      if (this.isTaskUnallocated(task) || 
          (task.carer_string === task.original_carer_string && task.suggested_carer_string)) {
        this.autoAllocateKeyStaff(task);
      }
    });
  }

  revertAutoAllocation(tasks: TaskItem[]): void {
    tasks.forEach(task => {
      if (task.carer_string === task.suggested_carer_string && 
          task.carer_string !== task.original_carer_string) {
        task.carer_string = task.original_carer_string;
        task.pending_allocation_change = false;
        task.pending_change = task.pending_timing_change;
        task.css_custom = '';
      }
    });
  }

  isTaskUnallocated(task: TaskItem): boolean {
    const carerString = task.carer_string;
    if (!carerString) {
      return true;
    }
    const carerId = parseInt(carerString);
    return carerId >= 2000000 && carerId < 3000000;
  }

  isMoveAllowed(section_key: number, section_label: string, new_carer_string: string, css_custom: string): TaskMoveAllowedResult {
    const allowedDestinationsForEvent = this._allowedDestinations[section_key];
    const new_carer_string_parent_section_key = this.findParentSectionKey(parseInt(new_carer_string, 10));

    let returnCssClass = css_custom;
    if (css_custom.includes('relocated-section')) {
      returnCssClass = css_custom.replace(/relocated-section-[\w-]+/, '');
    }

    if (section_key === new_carer_string_parent_section_key) {
      returnCssClass = `${returnCssClass} relocated-section-same`;
      return { disallowReason: undefined, classString: returnCssClass.trim() };
    }

    const isAllowed = allowedDestinationsForEvent && allowedDestinationsForEvent.includes(new_carer_string_parent_section_key);
    if (isAllowed) {
      returnCssClass = `${returnCssClass} relocated-section-${section_key}`;
      return { disallowReason: undefined, classString: returnCssClass.trim() };
    }
    return { disallowReason: `Cannot move ${section_label} service to ${this._ydata.find(section => section.key === new_carer_string_parent_section_key)?.label} section`, classString: returnCssClass.trim() };
  }

  findInvalidCarerStrings(events: any[]) {
    if (!this._ydata) {
      return;
    }

    const carerKeys = this.getAllCarerKeys(this._ydata);
    const invalidEvents = events.filter(event => {
      const carerString = String(event.carer_string);
      
      // For multisection events (comma-separated carer strings)
      if (carerString.includes(',')) {
        const carerIds = carerString.split(',');
        // Check if any of the individual carer IDs are invalid
        return carerIds.some(carerId => !carerKeys.includes(carerId.trim()));
      }
      
      // For single carer events
      return !carerKeys.includes(carerString);
    });

    if (invalidEvents.length > 0) {
      invalidEvents.forEach(event => {
        const carerString = String(event.carer_string);
        let message;
        
        if (carerString.includes(',')) {
          const carerIds = carerString.split(',');
          const invalidCarerIds = carerIds.filter(carerId => !carerKeys.includes(carerId.trim()));
          message = `Some carers in the multisection allocation are invalid: ${invalidCarerIds.join(', ')}. They may be inactive staff members.`;
        } else {
          message = `Invalid carer: ${event.carer_string} ${event.carer_name}. This may be an inactive staff member.`;
        }
        
        this.notificationService.addMessage(
          'critical',
          message,
          event as TaskItem
        );
      });
    }
  }

  private getAllCarerKeys(data: any[]): string[] {
    let keys: string[] = [];
    for (const item of data) {
      if ('key' in item) {
        keys.push(String(item.key));
      }
      if ('children' in item && Array.isArray(item.children)) {
        keys = keys.concat(this.getAllCarerKeys(item.children));
      }
    }
    return keys;
  }

  get isLoading$(): Observable<boolean> {
    return this._isLoading.asObservable();
  }

  get ydata(): RoleObject[] {
    return this._ydata;
  }

  get allowedDestinations(): AllowedDestinations {
    return this._allowedDestinations;
  }

  getEcaseCss = (appointment: any): string => {
    let classNames = [];
    const statusClass = AppointmentStatus.getAppointmentClass(appointment);
    if (statusClass) {
      classNames.push(statusClass);
    }
    if (appointment.isStaffEvent) {
      classNames.push('staff-event');
    }
    if (appointment.readonly) {
      classNames.push('readonly');
    }
    if (appointment.activity?.activityType === 'Leave') {
      classNames.push('leave');
    }
    if (appointment.activity?.activityType === 'Unavailability') {
      classNames.push('unavailable');
    }
    return classNames.join(' ');
  }

  findParentSectionKey(targetKey: number): number {
    for (const roleObject of this._ydata) {
      const result = this.searchSections(roleObject, targetKey, null);
      if (result !== null) {
        return result;
      }
    }
    return 1000010;
  }

  searchSections(
    item: RoleObject | Section | StaffItem,
    targetKey: number,
    currentParentKey: number | null
  ): number | null {
    if (item.key >= 1000000 && item.key < 2000000) {
      currentParentKey = item.key;
    }

    if (item.key === targetKey) {
      return currentParentKey;
    }

    if ('children' in item && item.children) {
      for (const child of item.children) {
        const result = this.searchSections(child, targetKey, currentParentKey);
        if (result !== null) {
          return result;
        }
      }
    }

    return null;
  }

  hasPendingAllocationChange(ev: any): boolean {
    const carerString = Number(ev.carer_string);
    const isUnallocatedCarerString = carerString >= 2000000 && carerString < 3000000;
    const matchesCarerString = String(ev.carer_string) === String(ev.original_carer_string);

    if (isUnallocatedCarerString || matchesCarerString) {
      return false;
    }
    return true;
  }

  updateCounts(): void {
    const counts: { [key: string]: number } = {};

    this._tasks.forEach(task => {
      const sectionKey = task.section_key;
      const clientType = task.is_hpac ? ClientType.HPAC_ONLY : ClientType.INDEPENDENT_ONLY;
      const timeOfDay = task.is_pm_service ? TimeOfDay.NIGHT : TimeOfDay.DAY;

      const key = `${sectionKey}-${clientType}-${timeOfDay}`;
      if (counts[key]) {
        counts[key]++;
      } else {
        counts[key] = 1;
      }
    });

    this._counts.next(counts);
  }
}
