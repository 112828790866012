import { Injectable, ElementRef } from '@angular/core';
import { Scheduler, SchedulerStatic } from "@dhx/scheduler";
import { HelperService } from './helper.service';
import { NotificationService } from './notification.service';
import { ThemeService } from './theme.service';
import { TaskService } from './task.service';
import { ChangeManagementService } from './change-management.service';
import { DragDropService } from './drag-drop.service';
import { FilterService } from './filter.service';
import { TaskItem } from '../models/task.model';
import { YDataBuilderService } from './ydata-builder.service';
import { CarerStateService } from './carer-state.service';
import { SchedulerEventsService } from './scheduler-events.service';
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class SchedulerConfigService {
  private hideTimeout: ReturnType<typeof setTimeout> | null = null;
  private readonly HIDE_DELAY = 2000; // 2 seconds

  constructor(
    private helperService: HelperService,
    private taskService: TaskService,
    private changeManagementService: ChangeManagementService,
    private dragDropService: DragDropService,
    private filterService: FilterService,
    private ydataBuilderService: YDataBuilderService,
    private themeService: ThemeService,
    private carerStateService: CarerStateService,
    private notificationService: NotificationService,
    private schedulerEventsService: SchedulerEventsService
  ) { }

  configureTimelineView(scheduler: SchedulerStatic, y_unit: { key: number | string; label: string; children?: unknown[] }[]): void {
    const currentDate = scheduler.getState().date;
    const scrollDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      6, 0, 0
    );

    scheduler.createTimelineView({
      section_autoheight: false,
      name: "timeline",
      x_unit: "minute",
      x_date: "%i",
      x_step: 10,
      x_size: 144,
      x_start: 0,
      x_length: 144,
      y_unit: y_unit,
      folder_dy: 28,
      event_dy: "full",
      event_min_dy: 50,
      dy: 62,
      dx: 250,
      y_property: "carer_string",
      render: "tree",
      scrollable: true,
      scroll_position: scrollDate,
      column_width: localStorage.getItem('roster_column_width') === 'wide' ? 80 : 40,
      second_scale: {
        x_unit: "hour",
        x_date: "%H:%i"
      }
    });
  }

  initScheduler(schedulerContainer: ElementRef): SchedulerStatic {
    const scheduler = Scheduler.getSchedulerInstance();
    this.configureSchedulerPlugins(scheduler);
    this.configureSchedulerSettings(scheduler);
    this.configureTimelineView(scheduler, []);
    this.setupSchedulerFilters(scheduler);
    scheduler.init(schedulerContainer.nativeElement, new Date(), "timeline");
    return scheduler;
  }

  private configureSchedulerPlugins(scheduler: SchedulerStatic): void {
    scheduler.plugins({
      multisection: true,
      minical: true,
      timeline: true,
      tooltip: true,
      cookie: true,
      treetimeline: true
    });
  }

  /**
   * Overrides the scheduler's message display functionality to prevent unwanted notifications
   */
  private configureMessageOverride(scheduler: SchedulerStatic): void {
    const originalMessage = (scheduler as any).message;

    scheduler['message'] = (message: unknown) => {
      // Check if message is an object with a text property
      if (typeof message === 'object' && message !== null && 'text' in message) {
        const msgObj = message as { text: string; type?: string };

        // Block messages containing 'Event deleted' or 'Undo', or of type 'popup_after_delete'
        if (
          (typeof msgObj.text === 'string' && (msgObj.text.includes('Event deleted') || msgObj.text.includes('Undo'))) ||
          msgObj.type === 'popup_after_delete'
        ) {
          return null;
        }
      }

      return originalMessage?.(message);
    };
  }

  private configureSchedulerSettings(scheduler: SchedulerStatic): void {
    this.configureMessageOverride(scheduler);
    scheduler.config.all_timed = true;
    scheduler.config.ajax_error = "console";
    scheduler.config.date_format = '%Y-%m-%d %H:%i:%s';
    scheduler.config.default_date = '%l, %j %F';
    scheduler.config.details_on_dblclick = true;
    scheduler.config.drag_create = false;
    scheduler.config.drag_resize = false;
    scheduler.config.drag_move = true;
    scheduler.config.dblclick_create = false;
    scheduler.config.edit_on_create = false;
    scheduler.config.icons_select = [];
    scheduler.config.multisection = true;
    scheduler.config.show_loading = true;
    scheduler.locale.labels['timeline_tab'] = "Timeline";
    scheduler.locale.labels.section_description = "Section";
    scheduler.setSkin(this.themeService.getCurrentTheme() ? "dark" : "material");
  }

  private setupSchedulerFilters(scheduler: SchedulerStatic): void {
    scheduler['filter_timeline'] = (id: string, event: TaskItem) => {
      return this.filterService.isTaskVisible(event, this.filterService.getRenderedCarerIds());
    };

    this.filterService.getFilterState().subscribe(() => {
      if (scheduler.getState().mode) {
        scheduler.updateView();
      }
    });
  }

  setupSchedulerTemplates(scheduler: SchedulerStatic): void {
    scheduler.templates.event_bar_text = (start: Date, end: Date, appointment: TaskItem) =>
      this.helperService.getAppointmentHtml(start, end, appointment);

    scheduler.templates.tooltip_text = (start: Date, end: Date, appointment: TaskItem) =>
      this.helperService.get_tooltip_text(start, end, appointment);

    scheduler.templates.event_class = (event_start_date, event_end_date, appointment) => {
      let cssClass = (appointment.css_ecase + " " + appointment.css_custom).trim();
      if (appointment.pending_change) {
        cssClass += " pending-change";
      }
      return cssClass.trim();
    };

    scheduler.templates.tooltip_date_format = function (date: Date) {
      const formatFunc = scheduler.date.date_to_str("%d %M %Y %H:%i:%s");
      return formatFunc(date);
    }

    scheduler.templates.timeline_row_class = (section: { children?: unknown[] }) => {
      if (section.children) {
        return "folder children";
      }
      return "";
    };
  }

  private isUnallocatedCarer(carerString: string | number): boolean {
    const carerNum = Number(carerString);
    return !isNaN(carerNum) && carerNum >= 2000000;
  }

  private setupCarerIcons(): void {
    const rows = document.querySelectorAll('.dhx_timeline_label_row');
    rows.forEach(row => {
      const carerId = row.getAttribute('data-row-id');
      // Skip system carers (2000* IDs)
      if (!carerId || carerId.startsWith('2000')) {
        return;
      }

      const cell = row.querySelector('.dhx_matrix_scell') as HTMLElement;
      if (cell) {
        this.addCarerIcon(cell, carerId);
      }
    });
  }

  private addCarerIcon(cell: HTMLElement, carerId: string): void {
    if (cell.querySelector('.carer-icon-container')) return;

    const container = document.createElement('span');
    container.classList.add('carer-icon-container');

    const icon = document.createElement('span');
    icon.classList.add('carer-icon', 'material-icons');
    icon.setAttribute('data-carer-id', carerId);

    // Set initial state from CarerStateService
    const { icon: initialIcon, classes } = this.carerStateService.getIconState(carerId);
    icon.textContent = initialIcon;
    if (classes) {
      container.classList.add(classes);
    }

    container.addEventListener('click', (event) => {
      this.carerStateService.handleIconClick(event, carerId, (isDoubleClick) => {
        // Clear any existing timeout
        if (this.hideTimeout) {
          clearTimeout(this.hideTimeout);
          this.hideTimeout = null;
        }

        // Get the current view state
        const viewState = this.carerStateService.getCurrentViewState();

        // Toggle the state
        this.carerStateService.toggleCarerState(carerId, isDoubleClick);

        // Get the updated icon data
        const newState = this.carerStateService.getIconState(carerId);

        // Reset the icon to its base classes, then add the new classes
        icon.className = 'carer-icon material-icons';
        icon.textContent = newState.icon;

        // Also reset the container to 'carer-icon-container'
        container.className = 'carer-icon-container';

        // Add classes to both if you still want container styling
        if (newState.classes) {
          icon.classList.add(newState.classes);
          container.classList.add(newState.classes);
        }

        // Only trigger rebuilds if we're not in show hidden view
        if (viewState !== 'showHidden') {
          // If we're in default view and any staff member was just hidden,
          // wait 2 seconds before triggering a rebuild
          if (viewState === 'default' && newState.icon === 'visibility_off') {
            this.hideTimeout = setTimeout(() => {
              // Only emit if we're still in default view
              if (this.carerStateService.getCurrentViewState() === 'default') {
                this.carerStateService.emitStateChange();
              }
              this.hideTimeout = null;
            }, this.HIDE_DELAY);
          } else {
            // For all other state changes (except in show hidden view), emit immediately
            this.carerStateService.emitStateChange();
          }
        }
      });
    });


    // Stop propagation on the container to prevent cell double-click
    container.addEventListener('dblclick', (event) => {
      event.preventDefault();
      event.stopPropagation();
    });

    container.appendChild(icon);
    cell.appendChild(container);
  }

  private highlightPreferredExcludedCarers(task: TaskItem): void {
    const cells = document.querySelectorAll('.dhx_matrix_scell');
    cells.forEach(cell => {
      const row = cell.closest('.dhx_timeline_label_row');
      const carerId = row?.getAttribute('data-row-id');

      if (carerId) {
        if (task.preferred_carers?.includes(carerId)) {
          cell.classList.add('preferred-carer');
        }
        if (task.excluded_carers?.includes(carerId)) {
          cell.classList.add('excluded-carer');
        }
      }
    });
  }

  private clearPreferredExcludedHighlights(): void {
    const cells = document.querySelectorAll('.dhx_matrix_scell');
    cells.forEach(cell => {
      cell.classList.remove('preferred-carer', 'excluded-carer');
    });
  }

  setupSchedulerEvents(scheduler: SchedulerStatic): void {
    // Variables to track drag state
    let initialCarerId = '';
    
    // Handle drag start
    scheduler.attachEvent("onBeforeDrag", (id: string) => {
      const task = scheduler.getEvent(id) as TaskItem;
      
      // Store the initial carer ID to prevent vertical movement
      initialCarerId = task.carer_string?.toString() || '';
      
      if (task.preferred_carers?.length || task.excluded_carers?.length) {
        this.highlightPreferredExcludedCarers(task);
      }
      return true;
    });

    // Handle drag end
    scheduler.attachEvent("onDragEnd", () => {
      // Reset drag tracking variables
      initialCarerId = '';
      this.clearPreferredExcludedHighlights();
      return true;
    });
    
    // Handle event dragging - allow horizontal movement only for events with comma in carer_string
    scheduler.attachEvent("onEventDrag", (id: string, mode: string, event: any) => {
      if (mode === 'move') {
        // Get the current event
        const eventData = scheduler.getEvent(id);
        if (!eventData) return true;
        
        // Check if this is an event with a comma in carer_string (indicating it should be restricted)
        const hasCommaInCarerId = initialCarerId && initialCarerId.includes(',');
        
        // Only apply horizontal-only restriction to events with comma in carer_string
        if (hasCommaInCarerId && initialCarerId && eventData.carer_string?.toString() !== initialCarerId) {
          // Enforce the original carer_string during drag
          // This ensures the event stays on its original row regardless of mouse movement
          eventData.carer_string = initialCarerId;
          scheduler.updateEvent(id);
          
          // Force a redraw of the entire scheduler to ensure the UI reflects the correct position
          scheduler.render();
          
          return false; // Prevent default handling to avoid flickering
        }
      }
      return true; // Allow default handling for horizontal movement or non-restricted events
    });

    // Handle event validation
    scheduler.attachEvent("onBeforeEventChanged", (ev, e, is_new, original) => {
      if (new Date(ev.start_date) < new Date()) {
        this.helperService.openSnackBar('Cannot change past events', 'Dismiss');
        return false;
      }
      
      // Handle two-person assist events with shift key
      if (ev.is_2pa === true && e instanceof MouseEvent) {
        const originalCarerString = original.carer_string?.toString() || '';
        const newCarerString = ev.carer_string?.toString() || '';
        
        // Check if this is a single-allocated 2PA event (not empty, not unallocated, no comma)
        const isSingleAllocated = originalCarerString && 
                                 !this.isUnallocatedCarer(originalCarerString) && 
                                 !originalCarerString.includes(',');
        
        // If shift is pressed and it's a single-allocated 2PA, combine the carer strings
        if (e.shiftKey && isSingleAllocated && newCarerString && newCarerString !== originalCarerString) {
          console.log('Shift key pressed on 2PA event - combining allocations');
          
          // Combine the original and new carer strings
          ev.carer_string = `${originalCarerString},${newCarerString}`;
          console.log(`Combined carer_string: ${ev.carer_string}`);
          
          // Update partially_allocated flag - now it's fully allocated
          ev.partially_allocated = false;
        } else if (ev.is_2pa) {
          // Update partially_allocated flag based on current carer_string
          const hasMultipleCarers = ev.carer_string && String(ev.carer_string).includes(',');
          const isUnallocated = !ev.carer_string || this.isUnallocatedCarer(ev.carer_string);
          ev.partially_allocated = !isUnallocated && !hasMultipleCarers;
        }
      }

      const isMoveAllowedResult = this.taskService.isMoveAllowed(
        ev.section_key,
        ev.section_label,
        ev.carer_string,
        ev.css_custom
      );

      if (isMoveAllowedResult.disallowReason) {
        const message = isMoveAllowedResult.disallowReason || 'This action is not allowed';
        this.helperService.openSnackBar(message, 'Dismiss');
        this.notificationService.addMessage('warning', message, ev);
        return false;
      }

      const isTimingChange = !this.helperService.areDatesEqual(
        ev.start_date,
        new Date(original.original_start_date)
      );
      const isAllocationChange = this.taskService.hasPendingAllocationChange(ev);

      ev.pending_allocation_change = isAllocationChange;
      ev.pending_timing_change = isTimingChange;
      ev.pending_change = ev.pending_timing_change || ev.pending_allocation_change;
      ev.pending_change_conflicts_with = this.taskService.getConflictingEventId(ev, scheduler.getEvents());
      ev.css_custom = isMoveAllowedResult.classString || '';

      scheduler.updateEvent(ev.id);
      this.changeManagementService.updatePendingChangesCount(scheduler.getEvents());
      this.changeManagementService.updateConflictsCount(scheduler.getEvents());
      
      this.checkForConflictsAndUpdatePublishButton(scheduler.getEvents());
      
      return true;
    });

    scheduler.attachEvent("onAfterFolderToggle", (section: { key?: number }, isOpen: boolean) => {
      const sectionKey = section?.key;
      if (typeof sectionKey === 'number') {
        this.ydataBuilderService.saveSectionState(sectionKey, isOpen);
      }

      setTimeout(() => {
        this.setupCarerIcons(); 
        this.dragDropService.setupDragHandlersForVisibleCells();
      }, 100);
    });

    scheduler.attachEvent("onScaleAdd", () => {
      setTimeout(() => {
        this.setupCarerIcons(); 
        this.dragDropService.setupDragHandlersForVisibleCells();
      }, 100);
    });


    scheduler.attachEvent("onBeforeEventDelete", () => {
      // Here to prevent the default confirmation dialog
      return true;
    });
  }

  show_minical(scheduler: SchedulerStatic): void {
    if (!scheduler) return;

    if (scheduler.isCalendarVisible()) {
      scheduler.destroyCalendar();
    } else {
      scheduler.renderCalendar({
        position: "dhx_minical_icon",
        date: scheduler.getState().date,
        navigation: true,
        handler: (date: Date) => {
          scheduler.setCurrentView(date, 'timeline');
          scheduler.destroyCalendar();
        }
      });
    }
  }

  /**
   * Checks for simultaneous starts in the visible events and updates the publish button state
   * A simultaneous start exists when a carer has two events with the same start time
   * Unallocated carers (system carers) are excluded from these checks
   */
  checkForConflictsAndUpdatePublishButton(events: TaskItem[]): void {
    // Get only visible events that are not unallocated
    const renderedCarerIds = this.filterService.getRenderedCarerIds();
    const visibleEvents = events.filter(event => 
      this.filterService.isTaskVisible(event, renderedCarerIds) &&
      !this.isUnallocatedCarer(event.carer_string)
    );

    // Group events by carer and start time
    const eventsByCarerAndTime = new Map<string, TaskItem[]>();
    
    visibleEvents.forEach(event => {
      // Ensure carer_string is a string
      const carerString = String(event.carer_string);
      
      // For multisection events, we need to check each carer ID separately
      if (carerString.includes(',')) {
        const carerIds = carerString.split(',').map(id => id.trim());
        const formattedDate = format(new Date(event.start_date), 'yyyy-MM-dd HH:mm:ss');
        
        carerIds.forEach(carerId => {
          const key = `${carerId}-${formattedDate}`;
          if (!eventsByCarerAndTime.has(key)) {
            eventsByCarerAndTime.set(key, []);
          }
          eventsByCarerAndTime.get(key)?.push(event);
        });
      } else {
        // For single section events
        const key = `${carerString}-${format(new Date(event.start_date), 'yyyy-MM-dd HH:mm:ss')}`;
        if (!eventsByCarerAndTime.has(key)) {
          eventsByCarerAndTime.set(key, []);
        }
        eventsByCarerAndTime.get(key)?.push(event);
      }
    });

    // Check for simultaneous starts (more than one event per carer at the same time)
    let hasSimultaneousStarts = false;
    let simultaneousStartsCount = 0;
    
    for (const [key, eventsAtTime] of eventsByCarerAndTime.entries()) {
      if (eventsAtTime.length > 1) {
        hasSimultaneousStarts = true;
        // Count the number of conflict groups, not the total number of events
        simultaneousStartsCount++;
        
        // Mark all events in this conflict group
        eventsAtTime.forEach(event => {
          // Find the first event that's not this one to use as the conflict reference
          const conflictEvent = eventsAtTime.find(e => e.id !== event.id);
          if (conflictEvent) {
            event.pending_change_conflicts_with = conflictEvent.id;
          }
        });
      }
    }

    // Update the conflicts count in the change management service
    this.changeManagementService.updateConflictsCount(events);
    
    // Update the simultaneous starts count
    this.changeManagementService.updateSimultaneousStartsCount(simultaneousStartsCount);
    
    // We don't need to manually update the button state anymore since we're using Angular binding
    // Just update the counts in the change management service
  }
}
