import { Injectable, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { SchedulerStatic } from "@dhx/scheduler";
import { format } from 'date-fns';
import { HelperService } from './helper.service';
import { SchedulerConfigService } from './scheduler-config.service';
import { TaskService } from './task.service';
import { ChangeManagementService } from './change-management.service';
import { StaffService } from './staff.service';
import { CarePlanDialogComponent } from '../components/care-plan-dialog/care-plan-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TaskItem } from '../models/task.model';
import { DragDropService } from './drag-drop.service';
import { ReallocateDialogComponent } from '../components/reallocate-dialog/reallocate-dialog.component';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { Section, StaffItem } from '../models/ydata.model';
import { EventBusService } from './event-bus.service';

@Injectable({
  providedIn: 'root'
})
export class RosterService {
  private _scheduler?: SchedulerStatic;
  private _schedulerContainer?: ElementRef;
  private lastLoadedDate: string | null = null;
  private showAllStaff: boolean = true;

  constructor(
    private helperService: HelperService,
    private schedulerConfigService: SchedulerConfigService,
    private taskService: TaskService,
    private changeManagementService: ChangeManagementService,
    private staffService: StaffService,
    private dialog: MatDialog,
    private eventBusService: EventBusService,
    private dragDropService: DragDropService
  ) {
    // Subscribe to bulk reallocation events
    this.dragDropService.doubleClickOnCarer.subscribe(event => {
      this.handleDoubleClickEvent(event);
    });

    this.dragDropService.dropOnCarer.subscribe(event => {
      this.handleDropEvent(event);
    });

    this.eventBusService.keyStaffUpdated$.subscribe(eventData => {
      this.updateEventAfterKeyStaffChange(eventData.eventId, eventData.newKeyStaff, eventData.staffLabel);
    });
  }

  setSchedulerContainer(container: ElementRef) {
    this._schedulerContainer = container;
  }

  initScheduler(): void {
    if (!this._schedulerContainer) {
      throw new Error('Scheduler container is not set');
    }

    this._scheduler = this.schedulerConfigService.initScheduler(this._schedulerContainer);
    this.schedulerConfigService.setupSchedulerEvents(this._scheduler);
    this.schedulerConfigService.setupSchedulerTemplates(this._scheduler);
    this.setupBusinessEvents(this._scheduler);
  }

  private setupBusinessEvents(scheduler: SchedulerStatic): void {
    // Handle date changes and task loading
    scheduler.attachEvent("onViewChange", (newView: string, newDate: any) => {
      if (!(newDate instanceof Date)) {
        newDate = new Date(newDate);
      }
      if (isNaN(newDate.getTime())) {
        newDate = new Date();
      }

      const formattedDate = format(newDate, 'yyyy-MM-dd');
      if (formattedDate !== this.lastLoadedDate) {
        this.lastLoadedDate = formattedDate;
        this.loadTasks(formattedDate);
      }
    });

    // Handle lightbox events
    scheduler.attachEvent("onBeforeLightbox", (id: string) => {
      const event = scheduler.getEvent(id) as TaskItem;
      this.dialog.open(CarePlanDialogComponent, {
        width: '800px',
        data: { event }
      });
      return false;
    });
  }

  /**
   * Updates an event's details in the scheduler.
   * @param eventId The ID of the event to update
   * @param updates Partial TaskItem containing the properties to update and their new values
   * @returns true if the update was successful, false otherwise
   */
  updateEventDetails(eventId: string, updates: Partial<TaskItem>): boolean {
    if (!this._scheduler) return false;

    const event = this._scheduler.getEvent(eventId);
    if (!event) return false;

    // Create a new event with the updated properties
    const newEvent = { ...event };
    Object.assign(newEvent, updates);

    // Delete the old event and add the new one to ensure proper update
    this._scheduler.deleteEvent(eventId);
    this._scheduler.addEvent(newEvent);

    // Update pending changes count
    this.changeManagementService.updatePendingChangesCount(this._scheduler.getEvents());

    return true;
  }

  checkForUnpublishableChanges(): boolean {
    if (!this._scheduler) return false;

    const events = this._scheduler.getEvents();
    const unpublishableEvents = events.filter(event =>
      event.pending_change && new Date(event.start_date) < new Date()
    );

    if (unpublishableEvents.length > 0) {
      console.log('Unpublishable events found:', unpublishableEvents);
      this.helperService.openSnackBar(
        `${unpublishableEvents.length} event(s) with past start dates will be ignored during publishing`,
        'OK'
      );
      return true;
    }
    return false;
  }

  private findEmptyStaffRows(ydata: (Section | StaffItem)[]): string[] {
    if (!this.showAllStaff && this._scheduler) {
      const emptyRows: string[] = [];

      const checkSection = (section: Section | StaffItem) => {
        if ('children' in section && section.children) {
          section.children.forEach((child: Section | StaffItem) => {
            if ('children' in child) {
              // If this section has key >= 4000000, process its staff members
              if ('key' in child && child.key >= 4000000) {
                child.children?.forEach((staffMember: Section | StaffItem) => {
                  if ('key' in staffMember && !('children' in staffMember)) {
                    const events = this._scheduler!.getEvents().filter(event =>
                      event.carer_string === String(staffMember.key)
                    );
                    if (events.length === 0) {
                      emptyRows.push(String(staffMember.key));
                    }
                  }
                });
              } else {
                // If not a 4000000+ section, continue searching deeper
                checkSection(child);
              }
            }
          });
        }
      };

      ydata.forEach(section => checkSection(section));
      return emptyRows;
    }
    return [];
  }

  loadTasks(date: string): void {
    this.taskService.loadTasks(date).subscribe(
      ({ tasks, ydata }) => {
        this._scheduler?.clearAll();
        this.staffService.setYData(ydata);
        this.schedulerConfigService.configureTimelineView(this._scheduler!, ydata);
        this._scheduler?.parse(tasks);

        // After parsing tasks, remove empty rows if needed
        if (!this.showAllStaff) {
          const emptyRows = this.findEmptyStaffRows(ydata);
          emptyRows.forEach(key => {
            this._scheduler?.deleteSection(key);
          });
        }

        this.changeManagementService.updatePendingChangesCount(this._scheduler!.getEvents());
        this.taskService.findInvalidCarerStrings(this._scheduler!.getEvents());
      },
      error => console.error('Error loading tasks:', error)
    );
  }

  show_minical() {
    if (this._scheduler) {
      this.schedulerConfigService.show_minical(this._scheduler);
    }
  }

  get isLoading$(): Observable<boolean> {
    return this.taskService.isLoading$;
  }

  get scheduler(): SchedulerStatic | undefined {
    return this._scheduler;
  }

  get pendingChangesCount$(): Observable<number> {
    return this.changeManagementService.pendingChangesCount$;
  }

  get publishComplete$(): Observable<boolean> {
    return this.changeManagementService.publishComplete$;
  }

  setAutoAllocateKeyStaff(value: boolean) {
    if (!this._scheduler) return;

    const events = this._scheduler.getEvents();
    if (value) {
      this.taskService.reapplyAutoAllocation(events);
    } else {
      this.taskService.revertAutoAllocation(events);
    }

    // Update the scheduler display
    events.forEach(event => {
      this._scheduler!.updateEvent(event.id);
    });

    // Update pending changes count
    this.changeManagementService.updatePendingChangesCount(events);

    // Refresh the scheduler view
    this._scheduler.updateView();
  }

  setShowAllStaff(value: boolean) {
    this.showAllStaff = value;
    if (this.lastLoadedDate) {
      this.loadTasks(this.lastLoadedDate);
    }
  }

  publishPendingChanges() {
    const events = this._scheduler?.getEvents();
    if (!events) return;

    this.changeManagementService.publishPendingChanges(events, (eventId: string) => {
      this._scheduler?.updateEvent(eventId);
    });
  }

  cancelPublishing(): void {
    this.changeManagementService.cancelPublishing();
  }

  updateEventAfterKeyStaffChange(eventId: string, newKeyStaff: string, staffLabel: string) {
    const event = this._scheduler?.getEvent(eventId);
    if (event) {
      event.suggested_carer_string = String(newKeyStaff);
      event.suggested_carer_name = staffLabel;
      event.carer_string = String(newKeyStaff);
      event.pending_allocation_change = true;
      event.pending_change = true;
      this.changeManagementService.updatePendingChangesCount(this._scheduler!.getEvents());
    }
  }

  revertChanges(eventId: string) {
    const scheduler = this._scheduler;
    if (!scheduler) return;

    const originalEvent: TaskItem = scheduler.getEvent(eventId);
    if (!originalEvent) return;
    const newEvent: TaskItem = { ...originalEvent };
    scheduler.deleteEvent(eventId);
    newEvent.carer_string = newEvent.original_carer_string;
    newEvent.start_date = newEvent.original_start_date;
    newEvent.end_date = newEvent.original_end_date;
    newEvent.pending_change = false;
    newEvent.pending_allocation_change = false;
    newEvent.pending_timing_change = false;
    newEvent.css_custom = '';
    scheduler.addEvent(newEvent);

    this.changeManagementService.updatePendingChangesCount(scheduler.getEvents());
  }

  private handleDropEvent(eventData: { sourceCarerId: string; targetCarerId: string; targetName: string }): void {
    const sourceCarerId = eventData.sourceCarerId;
    const targetCarerId = eventData.targetCarerId;
    const targetName = eventData.targetName;

    this.showConfirmationDialog(sourceCarerId, targetCarerId, targetName);
  }

  private handleDoubleClickEvent(eventData: { carerId: string; cellText: string }): void {
    const carerId = eventData.carerId;
    const cellText = eventData.cellText;

    const scheduler = this.scheduler;
    if (!carerId || !scheduler) return;

    const events = scheduler.getEvents();
    const sourceEvents = events.filter(event =>
      String(event.carer_string) === String(carerId) &&
      new Date(event.start_date) >= new Date()
    );

    if (sourceEvents.length === 0) {
      this.helperService.openSnackBar('No future events to reallocate', 'OK');
      return;
    }

    // Get siblings using the StaffService
    const siblings = this.staffService.getSiblings(sourceEvents[0]);

    const dialogRef = this.dialog.open(ReallocateDialogComponent, {
      width: '400px',
      data: {
        sourceStaffName: cellText,
        serviceCount: sourceEvents.length,
        siblings: siblings
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.confirmed) {
        this.bulkReallocateEvents(
          sourceEvents,
          carerId,
          result.targetStaffId
        );
      }
    });
  }

  private showConfirmationDialog(sourceCarerId: string, targetCarerId: string, targetName: string): void {
    const scheduler = this.scheduler;
    if (!scheduler) return;

    const events = scheduler.getEvents();
    const sourceEvents = events.filter(event =>
      String(event.carer_string) === String(sourceCarerId) &&
      new Date(event.start_date) >= new Date()
    );

    if (sourceEvents.length === 0) {
      this.helperService.openSnackBar('No future events to reallocate', 'OK');
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: {
        title: 'Confirm Bulk Reallocation',
        message: `Are you sure you want to reallocate ${sourceEvents.length} events to ${targetName}?`,
        confirmText: 'Reallocate',
        cancelText: 'Cancel'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.bulkReallocateEvents(
          sourceEvents,
          sourceCarerId,
          targetCarerId
        );
      }
    });
  }

  bulkReallocateEvents(events: TaskItem[], sourceCarerId: string, targetCarerId: string): void {
    const scheduler = this._scheduler;
    if (!scheduler) return;

    let successCount = 0;
    let failCount = 0;

    events.forEach(event => {
      // Get the event directly from the scheduler
      const schedulerEvent = scheduler.getEvent(event.id);
      if (!schedulerEvent) return;

      // Apply the same validation as onBeforeEventChanged
      const isMoveAllowedResult = this.taskService.isMoveAllowed(
        schedulerEvent.section_key,
        schedulerEvent.section_label,
        targetCarerId,
        schedulerEvent.css_custom
      );

      if (!isMoveAllowedResult.disallowReason) {
        // Update the event using RosterService's updateEventDetails
        const updates: Partial<TaskItem> = {
          carer_string: targetCarerId,
          pending_allocation_change: true,
          pending_change: true,
          css_custom: isMoveAllowedResult.classString || ''
        };

        if (this.updateEventDetails(schedulerEvent.id, updates)) {
          successCount++;
        } else {
          failCount++;
        }
      } else {
        failCount++;
      }
    });

    this.changeManagementService.updatePendingChangesCount(scheduler.getEvents());

    const message = successCount > 0
      ? `Successfully moved ${successCount} events, ready to be published${failCount > 0 ? ` (${failCount} failed)` : ''}`
      : 'No events could be reallocated';

    this.helperService.openSnackBar(message, 'OK');
  }
}
