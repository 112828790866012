import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take, switchMap, tap, startWith } from 'rxjs/operators';
import { TaskItem } from '../models/task.model';
import { PermissionsService } from './permissions.service';

export interface NotificationMessage {
  id: string;
  level: 'info' | 'warning' | 'critical';
  message: string;
  timestamp: Date;
  expiresAt: Date;
  taskItem?: TaskItem;
  count: number;
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private messages = new BehaviorSubject<NotificationMessage[]>([]);
  private hasUnreadMessages = new BehaviorSubject<boolean>(false);

  constructor(private permissionsService: PermissionsService) {
    // Auto-purge expired messages every minute
    setInterval(() => this.purgeExpiredMessages(), 60000);
  }

  getHasUnreadMessages(): Observable<boolean> {
    return this.hasUnreadMessages.asObservable();
  }

  getMessages(): Observable<NotificationMessage[]> {
    this.purgeExpiredMessages();
    return this.messages.asObservable();
  }

  addMessage(level: 'info' | 'warning' | 'critical', message: string, taskItem?: TaskItem): void {
    const now = new Date();
    const currentMessages = this.messages.getValue();
    
    // Check for duplicate message
    const existingMessage = currentMessages.find(msg => {
      const sameMessage = msg.message === message;
      const sameLevel = msg.level === level;
      const sameTask = msg.taskItem && taskItem ? 
        msg.taskItem.id === taskItem.id : 
        !msg.taskItem && !taskItem;
      return sameMessage && sameLevel && sameTask;
    });

    if (existingMessage) {
      // Update count and timestamp of existing message
      const updatedMessages = currentMessages.map(msg =>
        msg.id === existingMessage.id
          ? { 
              ...msg, 
              count: msg.count + 1,
              timestamp: now,
              expiresAt: new Date(now.getTime() + 60 * 60 * 1000)
            }
          : msg
      );
      this.messages.next(updatedMessages);
    } else {
      // Add new message
      const newMessage: NotificationMessage = {
        id: Date.now().toString(),
        level,
        message,
        timestamp: now,
        expiresAt: new Date(now.getTime() + 60 * 60 * 1000), // 1 hour expiry
        taskItem,
        count: 1
      };
      this.messages.next([...currentMessages, newMessage]);
    }
    this.updateUnreadState();
  }

  clearMessages(): void {
    this.messages.next([]);
    this.updateUnreadState();
  }

  private purgeExpiredMessages(): void {
    const currentMessages = this.messages.getValue();
    const now = new Date();
    const validMessages = currentMessages.filter(msg => msg.expiresAt > now);
    
    if (validMessages.length !== currentMessages.length) {
      this.messages.next(validMessages);
      this.updateUnreadState();
    }
  }

  private updateUnreadState(): void {
    this.getMessages().pipe(take(1)).subscribe(messages => {
      const hasMessages = messages.length > 0;
      this.hasUnreadMessages.next(hasMessages);
    });
  }

  getUnreadCount(): Observable<number> {
    return this.getMessages().pipe(
      map(messages => messages.length)
    );
  }

  getHasCriticalMessages(): Observable<boolean> {
    return this.getMessages().pipe(
      map(messages => messages.some(msg => msg.level === 'critical'))
    );
  }

  getHasWarningOrCritical(): Observable<boolean> {
    return this.getMessages().pipe(
      map(messages => messages.some(msg => 
        msg.level === 'warning' || msg.level === 'critical'
      ))
    );
  }
}
