<div class="custom-dialog notification-dialog">
    <mat-dialog-content>
        <div class="dialog-content">
            <div class="header-section">
                <div class="dialog-header">
                    <h3>Notifications</h3>
                </div>
            </div>

            <!-- Messages List -->
            <div class="content-window-l1">
                <div *ngIf="messages.length === 0" class="no-messages">
                    <mat-icon>notifications_none</mat-icon>
                    <p>No notifications</p>
                </div>

                <div *ngFor="let message of messages" class="message-item" [class]="getLevelClass(message.level)">
                    <div class="message-content">
                        <!-- Message Content -->
                        <div class="message-content-wrapper">
                            <div class="icon-container">
                                <span *ngIf="message.count > 1" class="count-badge">{{message.count}}</span>
                                <mat-icon [class]="getLevelClass(message.level)">{{getLevelIcon(message.level)}}</mat-icon>
                            </div>
                            
                            <!-- Message Section -->
                            <div class="message-main">
                                <div class="message-text">{{message.message}}</div>
                            </div>

                            <!-- Event Details Section -->
                            <div *ngIf="message.tooltipHtml" class="event-details" 
                                [innerHTML]="message.tooltipHtml">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button *ngIf="messages.length > 0" (click)="dismissAll()">Dismiss All</button>
        <button mat-raised-button (click)="onClose()">Close</button>
    </mat-dialog-actions>
</div>
