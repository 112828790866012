import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface StaffOption {
  key: number;
  label: string;
  level: number;
  $parent: number;
}

@Component({
  selector: 'app-reallocate-dialog',
  templateUrl: './reallocate-dialog.component.html',
  styleUrls: ['./reallocate-dialog.component.css']
})
export class ReallocateDialogComponent implements OnInit {
  staffOptions: StaffOption[] = [];
  selectedStaff: number | null = null;
  sourceStaffName: string = '';
  serviceCount: number = 0;

  constructor(
    public dialogRef: MatDialogRef<ReallocateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      sourceStaffName: string;
      serviceCount: number;
      siblings: StaffOption[];
    }
  ) {}

  ngOnInit() {
    this.staffOptions = this.data.siblings;
    this.sourceStaffName = this.data.sourceStaffName;
    this.serviceCount = this.data.serviceCount;
  }

  onConfirm() {
    if (this.selectedStaff !== null) {
      const selectedStaffMember = this.staffOptions.find(staff => staff.key === this.selectedStaff);
      if (selectedStaffMember) {
        this.dialogRef.close({
          confirmed: true,
          targetStaffId: selectedStaffMember.key.toString(),
          targetStaffName: selectedStaffMember.label
        });
      }
    }
  }

  onCancel() {
    this.dialogRef.close({ confirmed: false });
  }
}
