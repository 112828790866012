import { Injectable } from '@angular/core';
import { Observable, from, of, timer, forkJoin, Subject } from 'rxjs';
import { concatMap, tap, takeUntil, catchError, finalize } from 'rxjs/operators';
import { TaskItem } from '../models/task.model';
import { EventService } from './event.service';
import { ConflictManagementService } from './conflict-management.service';
import { PublishProgressDialogComponent } from '../components/publish/publish-progress-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class EventProcessingService {
  constructor(
    private eventService: EventService,
    private conflictService: ConflictManagementService
  ) { }

  /**
   * Process a list of events sequentially
   */
  processEvents(
    events: TaskItem[], 
    updateEvent: (eventId: string) => void, 
    progressDialog: PublishProgressDialogComponent,
    eventToGroupMap: Map<string, string>,
    dynamicEventIds: Set<string>,
    cancelPublish: Subject<void>
  ): Observable<any> {
    // Get the group ID if this is a conflict group
    const firstEvent = events.length > 0 ? events[0] : null;
    const step = firstEvent ? progressDialog.steps.find(s => s.id === firstEvent.id) : null;
    const groupId = step?.groupId;
    let processedCount = 0;
    const totalCount = events.length;
    
    // Update progress indicator
    if (groupId) {
      progressDialog.updateGroupProgress(groupId, 0, totalCount, 'Publishing');
    } else if (totalCount > 0) {
      progressDialog.updateRegularProgress(0, totalCount);
    }
    
    return from(events).pipe(
      concatMap((event: TaskItem, index: number) => {
        // Update progress indicator
        processedCount = index + 1;
        if (groupId) {
          progressDialog.updateGroupProgress(groupId, processedCount, totalCount, 'Publishing');
        } else {
          progressDialog.updateRegularProgress(processedCount, totalCount);
        }
        // Add a small delay between each event to avoid overwhelming the server
        return timer(200).pipe(
          concatMap(() => {
            // If there's a timing change, handle it with runtime conflict detection
            const timingObservable = event.pending_timing_change
              ? this.createTimingObservable(
                  event, 
                  updateEvent, 
                  progressDialog, 
                  eventToGroupMap,
                  dynamicEventIds,
                  cancelPublish
                )
              : of(null);

            // Always process allocation change if needed, regardless of timing change
            const allocationObservable = event.pending_allocation_change
              ? (this.conflictService.isEventUnallocated(event)
                ? this.eventService.unallocateServiceInstance(event.id)
                : this.eventService.allocateServiceInstance(event.id, event.carer_string)
              ).pipe(
                takeUntil(cancelPublish),
                // Don't throw errors, just pass them through
                tap(
                  response => {
                    if (response && response.ecase_status_code === 200) {
                      event.original_carer_string = event.carer_string;
                      event.pending_allocation_change = false;
                      event.css_custom = event.css_custom.replace('relocated-section-same', '').trim();
                      
                      // Update CSS class based on allocation status
                      if (this.conflictService.isEventUnallocated(event)) {
                        event.css_ecase = 'appointment-unallocated';
                      } else if (event.partially_allocated) {
                        // For 2PA events with only one carer allocated
                        event.css_ecase = 'appointment-partially-allocated';
                      } else if (response?.data?.[event.carer_string]?.IsAllocated) {
                        event.css_ecase = 'appointment-approved';
                      }
                      
                      // Update progress dialog
                      progressDialog.completeStep(event.id, true);
                    } else {
                      // Log the error but don't halt the process
                      const errorMsg = `Allocation failed: ${response?.message || 'Unknown error'}`;
                      
                      // Update progress dialog with error
                      progressDialog.completeStep(event.id, false, errorMsg);
                    }
                    
                    // Update UI regardless of success/failure
                    updateEvent(event.id);
                  },
                  error => {
                    console.error(`Error allocating event ${event.id}:`, error);
                    progressDialog.completeStep(event.id, false, `Error: ${error.message || 'Unknown error'}`);
                    updateEvent(event.id);
                  }
                )
              )
              : of(null);

            return forkJoin([timingObservable, allocationObservable]).pipe(
              takeUntil(cancelPublish),
              tap(() => {
                // Update pending change status
                event.pending_change = event.pending_allocation_change || event.pending_timing_change;
                
                // Update UI
                updateEvent(event.id);
              })
            );
          })
        );
      })
    );
  }

  /**
   * Creates an observable for updating the timing of an event, checking for conflict errors
   */
  private createTimingObservable(
    event: TaskItem,
    updateEvent: (eventId: string) => void,
    progressDialog: PublishProgressDialogComponent,
    eventToGroupMap: Map<string, string>,
    dynamicEventIds: Set<string>,
    cancelPublish: Subject<void>
  ): Observable<any> {
    // Make the timing update call
    return this.eventService.updateActivityTiming(
      event.id,
      event.carer_string,
      event.start_date,
      event.end_date
    ).pipe(
      takeUntil(cancelPublish),
      concatMap(response => {
        // Log any non-200 response for debugging
          // Process non-200 responses without logging

        if (response && response.ecase_status_code === 200) {
          // Success
          event.original_start_date = event.start_date;
          event.original_end_date = event.end_date;
          event.pending_timing_change = false;
          
          // Update progress dialog
          progressDialog.completeStep(event.id, true);
          
          // Update UI
          updateEvent(event.id);
          
          // Return the successful response
          return of(response);
        } else if (response && response.ecase_status_code === 400) {
          // Check if the error message contains "conflict"
          const hasConflict = response.message && 
                              response.message.toLowerCase().includes('conflict');
          
          if (hasConflict) {
            // Check if this event is already in the dynamic group
            if (eventToGroupMap.has(event.id) && eventToGroupMap.get(event.id) === 'z-dynamic-swap-group') {
              // Event is already in the dynamic group and still has conflicts
              // Mark it as failed with the error message and set a flag to prevent overriding
              const errorMsg = `eCase error message: ${response.message || 'Unknown error'}`;
              
              // Find the step and directly modify it to ensure it remains marked as failed
              const step = progressDialog.steps.find(s => s.id === event.id);
              if (step) {
                step.completed = true;
                step.success = false;
                step.error = errorMsg;
                
                // Set a special flag to prevent this step from being overridden
                step['finalFailure'] = true;
              }
              
              // Update UI
              updateEvent(event.id);
              
              // Return the response
              return of(response);
            } else {
              
              // This is a conflict - add to the dynamic conflicts group if not already in a group
              this.conflictService.addToDynamicConflictGroup(
                event,
                eventToGroupMap,
                dynamicEventIds,
                progressDialog,
                response.message
              );
              
              // Return the response - we'll process this event again later as part of the dynamic conflict group
              return of(response);
            }
          } else {
            // This is some other kind of 400 error, not a conflict
            const errorMsg = `Timing update failed: ${response.message || 'Unknown error'}`;
            
            // Update progress dialog with error
            progressDialog.completeStep(event.id, false, errorMsg);
            
            // Update UI
            updateEvent(event.id);
            
            // Return the error response
            return of(response);
          }
        } else {
          // Other error
          const errorMsg = `Timing update failed: ${response?.message || 'Unknown error'}`;
          
          // Update progress dialog with error
          progressDialog.completeStep(event.id, false, errorMsg);
          
          // Update UI
          updateEvent(event.id);
          
          // Return the error response
          return of(response);
        }
      }),
      catchError(error => {
        console.error(`Error updating timing for event ${event.id}:`, error);
        progressDialog.completeStep(event.id, false, `Error: ${error.message || 'Unknown error'}`);
        updateEvent(event.id);
        return of(null); // Return an observable to continue the chain
      })
    );
  }

  /**
   * Process a conflict group - unallocate all events, wait, then process all events
   */
  processConflictGroup(
    group: { groupId: string, events: TaskItem[] },
    updateEvent: (eventId: string) => void,
    progressDialog: PublishProgressDialogComponent,
    eventToGroupMap: Map<string, string>,
    dynamicEventIds: Set<string>,
    cancelPublish: Subject<void>
  ): Observable<any> {
    return new Observable(observer => {
      // a. Unallocate all events in the group that need unallocation
      // We need to unallocate ALL events in the group that are currently allocated
      const eventsToUnallocate = group.events.filter(event => 
        !this.conflictService.isEventUnallocated(event)
      );
      
      // Create a function to process this group
      const processGroup = async () => {
        try {
          // Only do unallocation if needed
          if (eventsToUnallocate.length > 0) {
            // Unallocate events in this swap group
            
            // Update progress indicator
            progressDialog.updateGroupProgress(group.groupId, 0, eventsToUnallocate.length, 'Unallocating');
            
            // Process unallocations one by one
            for (let i = 0; i < eventsToUnallocate.length; i++) {
              const event = eventsToUnallocate[i];
              
              // Update progress for each unallocation
              progressDialog.updateGroupProgress(group.groupId, i + 1, eventsToUnallocate.length, 'Unallocating');
              
              // Unallocate the event
              await new Promise<void>((resolve, reject) => {
                this.eventService.unallocateServiceInstance(event.id)
                  .pipe(
                    takeUntil(cancelPublish),
                    tap(response => {
                      if (response && response.ecase_status_code === 200) {
                        event.pending_allocation_change = true;
                        
                        // Mark the step as unallocated
                        const step = progressDialog.steps.find(s => s.id === event.id);
                        if (step) {
                          step.isUnallocated = true;
                          progressDialog.markStepUnallocated(event.id);
                        }
                      }
                    }),
                    catchError(err => {
                      console.error(`Error unallocating event ${event.id}:`, err);
                      reject(err);
                      return of(null);
                    })
                  )
                  .subscribe({
                    complete: () => resolve()
                  });
              });
              
              // Add a small delay between unallocations
              await new Promise(resolve => setTimeout(resolve, 100));
            }
            
            // b. Wait 1 second after all unallocations are done
            await new Promise(resolve => setTimeout(resolve, 1000));
          }
          
          // c. Process all events in the group
          let groupHasErrors = false;
          
          await new Promise<void>((resolve, reject) => {
            this.processEvents(
                group.events, 
                updateEvent, 
                progressDialog,
                eventToGroupMap,
                dynamicEventIds,
                cancelPublish
              ).pipe(
                takeUntil(cancelPublish),
                catchError(err => {
                  console.error(`Error in processEvents for group ${group.groupId}:`, err);
                  groupHasErrors = true;
                  reject(err);
                  return of(null);
                }),
                finalize(() => {
                  // Check if any steps in this group failed
                  const groupSteps = progressDialog.steps.filter(s => s.groupId === group.groupId);
                  const failedSteps = groupSteps.filter(s => s.completed && !s.success);
                  
                  if (failedSteps.length > 0) {
                    groupHasErrors = true;
                  }
                })
              )
              .subscribe({
                complete: () => resolve()
              });
          }).catch(err => {
            // We catch errors here so we can still mark the group as complete with errors
            console.error(`Error occurred processing group ${group.groupId}:`, err);
            groupHasErrors = true;
          });
          
          // Get all steps in this group for status checking
          const groupSteps = progressDialog.steps.filter(s => s.groupId === group.groupId);
          
          // Mark the group as complete, this will now properly handle errors
          progressDialog.completeGroupProgress(group.groupId);
          
          // Swap group processing complete
          observer.next(null);
          observer.complete();
        } catch (error) {
          console.error(`Error processing swap group ${group.groupId}:`, error);
          observer.error(error);
        }
      };
      
      // Start processing this group
      processGroup();
      
      // Return a function to handle cancellation
      return () => {
        // Using takeUntil(cancelPublish) in the inner observables
      };
    });
  }
}
