<div class="custom-dialog">
  <mat-dialog-content>
    <div class="dialog-content">
      <!-- Header Section -->
      <div class="section-header">
        <h3>Service Filter Grid</h3>
        <button mat-icon-button
                type="button"
                (click)="dialogRef.close()">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <!-- Grid Sections -->
      <div class="content-window-l1">
        <div class="grid-sections">
          <div *ngFor="let section of sections; let last = last" class="content-window-l2" [class.active]="isSectionActive(section.key)">
            <div class="grid" role="grid" [attr.aria-label]="section.label + ' service filters'">
              <!-- Header row -->
              <div class="grid-row header" role="row">
                <div class="grid-cell header-cell" role="gridcell">{{section.label}}</div>
                <div class="grid-cell clickable" 
                     *ngFor="let headerCell of gridData[section.key] | slice:0:1"
                     role="columnheader"
                     [attr.aria-label]="'Independent ' + section.label + ' services'"
                     [attr.aria-selected]="isCellSelected(headerCell)"
                     [class.selected]="isCellSelected(headerCell)"
                     (click)="onHeaderClick(headerCell)"
                     tabindex="0"
                     (keydown.enter)="onHeaderClick(headerCell)"
                     (keydown.space)="onHeaderClick(headerCell); $event.preventDefault()">
                  <div class="header-content">
                    <span>Independent</span>
                  </div>
                </div>
                <div class="grid-cell clickable"
                     *ngFor="let headerCell of gridData[section.key] | slice:3:4"
                     role="columnheader"
                     [attr.aria-label]="'HPAC ' + section.label + ' services'"
                     [attr.aria-selected]="isCellSelected(headerCell)"
                     [class.selected]="isCellSelected(headerCell)"
                     (click)="onHeaderClick(headerCell)"
                     tabindex="0"
                     (keydown.enter)="onHeaderClick(headerCell)"
                     (keydown.space)="onHeaderClick(headerCell); $event.preventDefault()">
                  <div class="header-content">
                    <span>HPAC</span>
                  </div>
                </div>
              </div>
              
              <!-- AM row -->
              <div class="grid-row" role="row">
                <div class="grid-cell time-cell clickable" 
                     role="rowheader"
                     [class.selected]="isTimeSelected(TimeOfDay.DAY, section.key)"
                     (click)="onTimeClick(TimeOfDay.DAY, section.key)"
                     tabindex="0"
                     (keydown.enter)="onTimeClick(TimeOfDay.DAY, section.key)"
                     (keydown.space)="onTimeClick(TimeOfDay.DAY, section.key); $event.preventDefault()">AM</div>
                <!-- Independent AM -->
                <div class="grid-cell clickable" 
                     *ngFor="let cell of gridData[section.key] | slice:1:2"
                     role="gridcell"
                     [attr.aria-label]="'AM Independent ' + section.label + ' services'"
                     [attr.aria-selected]="isCellSelected(cell)"
                     [class.selected]="isCellSelected(cell)"
                     [class.independent]="true"
                     (click)="onCellClick(cell)"
                     tabindex="0"
                     (keydown.enter)="onCellClick(cell)"
                     (keydown.space)="onCellClick(cell); $event.preventDefault()">
                  <span class="cell-count">{{cell?.count || 0}}</span>
                </div>
                <!-- HPAC AM -->
                <div class="grid-cell clickable" 
                     *ngFor="let cell of gridData[section.key] | slice:4:5"
                     role="gridcell"
                     [attr.aria-label]="'AM HPAC ' + section.label + ' services'"
                     [attr.aria-selected]="isCellSelected(cell)"
                     [class.selected]="isCellSelected(cell)"
                     [class.hpac]="true"
                     (click)="onCellClick(cell)"
                     tabindex="0"
                     (keydown.enter)="onCellClick(cell)"
                     (keydown.space)="onCellClick(cell); $event.preventDefault()">
                  <span class="cell-count">{{cell?.count || 0}}</span>
                </div>
              </div>
              
              <!-- PM row -->
              <div class="grid-row" role="row">
                <div class="grid-cell time-cell clickable" 
                     role="rowheader"
                     [class.selected]="isTimeSelected(TimeOfDay.NIGHT, section.key)"
                     (click)="onTimeClick(TimeOfDay.NIGHT, section.key)"
                     tabindex="0"
                     (keydown.enter)="onTimeClick(TimeOfDay.NIGHT, section.key)"
                     (keydown.space)="onTimeClick(TimeOfDay.NIGHT, section.key); $event.preventDefault()">PM</div>
                <!-- Independent PM -->
                <div class="grid-cell clickable" 
                     *ngFor="let cell of gridData[section.key] | slice:2:3"
                     role="gridcell"
                     [attr.aria-label]="'PM Independent ' + section.label + ' services'"
                     [attr.aria-selected]="isCellSelected(cell)"
                     [class.selected]="isCellSelected(cell)"
                     [class.independent]="true"
                     (click)="onCellClick(cell)"
                     tabindex="0"
                     (keydown.enter)="onCellClick(cell)"
                     (keydown.space)="onCellClick(cell); $event.preventDefault()">
                  <span class="cell-count">{{cell?.count || 0}}</span>
                </div>
                <!-- HPAC PM -->
                <div class="grid-cell clickable" 
                     *ngFor="let cell of gridData[section.key] | slice:5:6"
                     role="gridcell"
                     [attr.aria-label]="'PM HPAC ' + section.label + ' services'"
                     [attr.aria-selected]="isCellSelected(cell)"
                     [class.selected]="isCellSelected(cell)"
                     [class.hpac]="true"
                     (click)="onCellClick(cell)"
                     tabindex="0"
                     (keydown.enter)="onCellClick(cell)"
                     (keydown.space)="onCellClick(cell); $event.preventDefault()">
                  <span class="cell-count">{{cell?.count || 0}}</span>
                </div>
              </div>
            </div>
            <mat-divider *ngIf="!last"></mat-divider>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-stroked-button 
            type="button"
            (click)="clearFilters()" 
            aria-label="Clear all filters">
      <mat-icon>clear_all</mat-icon>
      Clear Filters
    </button>
    <button mat-flat-button 
            type="button"
            (click)="onClose()" 
            color="primary" 
            aria-label="Close dialog">
      <mat-icon>close</mat-icon>
      Close
    </button>
  </mat-dialog-actions>
</div>
