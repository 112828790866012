import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { format } from "date-fns";
import { BaseApiService } from './api-base.service';
import { YData, AllowedDestinations } from "../models/ydata.model";
import { EcaseEvent, parseEcaseEvent } from 'src/app/models/ecaseevent.model';
import { HelperService } from './helper.service';

@Injectable()
export class EventService extends BaseApiService {
    private env: string;

    constructor(
        http: HttpClient,
        msalService: MsalService,
        private helperService: HelperService
    ) {
        super(http, msalService);
        this.env = this.helperService.getStoredValue('ecase_env', 'prod');
    }

    // API call to get the timeline events
    public getTimelineEvents(start_date: string): Observable<{ ecaseevents: EcaseEvent[], ydata: YData, allowedDestinations: AllowedDestinations }> {
        return this.get<any>(`${this.apiUrl}/ecase/${this.env}/timeline/?start_date=${start_date}`).pipe(
            map(response => {
                const ecaseevents = response && response.gridData
                    ? response.gridData.map((item: any) => parseEcaseEvent(item))
                    : [];

                const ydata: YData = {
                    yunitData: response.yunitData || []
                };
                const allowedDestinations: AllowedDestinations = response.allowedDestinations || {};
                return { ecaseevents, ydata, allowedDestinations };
            })
        );
    }

    // API call to allocate the service to the carer
    public allocateServiceInstance(ActivityInstanceId: string, rsAuthenId: string): Observable<any> {
        return this.get<any>(`${this.apiUrl}/ecase/${this.env}/allocate/?careActivityInstanceId=${ActivityInstanceId}&rsAuthenId=${rsAuthenId}`);
    }

    public unallocateServiceInstance(ActivityInstanceId: string): Observable<any> {
        return this.get<any>(`${this.apiUrl}/ecase/${this.env}/unallocate/?careActivityInstanceId=${ActivityInstanceId}`);
    }

    public updateKeyStaff(quote_detail_id: string, key_staff_id: string, resident_id: string): Observable<any> {
        return this.get<any>(`${this.apiUrl}/ecase/${this.env}/update-keystaff/?quote_detail_id=${quote_detail_id}&key_staff_id=${key_staff_id}&resident_id=${resident_id}`);
    }

    public updateActivityTiming(activityInstanceId: string, carerString: string, start: string, end: string): Observable<any> {
        const start_date = encodeURIComponent(format(new Date(start), 'yyyy-MM-dd HH:mm:ss'));
        const end_date = encodeURIComponent(format(new Date(end), 'yyyy-MM-dd HH:mm:ss'));
        return this.get<any>(`${this.apiUrl}/ecase/${this.env}/update-timing/?activityInstanceId=${activityInstanceId}&carerString=${carerString}&start=${start_date}&end=${end_date}`);
    }
}
