<div [ngClass]="theme" class="custom-dialog">
    <mat-dialog-content>
        <div class="dialog-content">
            <!-- Header Section -->
            <div class="header-section">
                <div class="dialog-header">
                    <div class="photo-container">
                        <mat-icon class="placeholder-icon">error_outline</mat-icon>
                    </div>
                    <h3>Email Address Mismatch</h3>
                </div>
            </div>

            <!-- Level 1 Content Window -->
            <div class="content-window-l1">
                <div class="content-window-l2">
                    <p>You authenticated with the email address:</p>
                    <div class="email-copy">
                        <mat-form-field appearance="outline" class="full-width">
                            <input matInput [value]="data.email" readonly #emailInput>
                        </mat-form-field>
                        <button mat-icon-button (click)="copyEmail(emailInput)" matTooltip="Copy email address">
                            <mat-icon>content_copy</mat-icon>
                        </button>
                    </div>
                    <p>We cannot find this email address for an eCase user.</p>
                    <p>Please follow these steps:</p>
                    <ol>
                        <li>Open eCase and go to your user profile</li>
                        <li>Clear your current email address</li>
                        <li>Click the copy button above to copy your Microsoft email</li>
                        <li>Paste the email into eCase and click update</li>
                        <li>Click the "Refresh and Try Again" button below</li>
                    </ol>
                </div>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-button (click)="close()">Close</button>
        <button mat-raised-button color="primary" (click)="refreshAndRetry()">
            Refresh and Try Again
        </button>
    </mat-dialog-actions>
</div>
