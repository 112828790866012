import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CarePlanService } from 'src/app/services/careplan.service';
import { StaffService } from 'src/app/services/staff.service';
import { ClientService } from 'src/app/services/client.service';
import { CarePlan, ServiceDetail } from 'src/app/models/careplan.model';
import { ServicePlanDetail, ServiceDetail as ServicePlanServiceDetail } from 'src/app/models/serviceplandetail.model';
import { TaskItem } from 'src/app/models/task.model';
import { Options } from '@angular-slider/ngx-slider';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ProgressDialogComponent } from '../progress-dialog/progress-dialog.component';
import { EventBusService } from 'src/app/services/event-bus.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-care-plan-dialog',
  templateUrl: './care-plan-dialog.component.html',
  styleUrls: ['../../templates/dialog-template.css', './care-plan-dialog.component.css']
})
export class CarePlanDialogComponent implements OnInit {
  carePlan: CarePlan | null = null;
  servicePlanDetail: ServicePlanDetail | null = null;
  loading = true;
  error: string | null = null;
  selectedService: ServiceDetail | null = null;
  selectedServiceDetails: ServicePlanServiceDetail | null = null;
  initialStartTime: string = '';
  initialEndTime: string = '';
  timeChanged: boolean = true;
  rateWindow: { start_time: string, end_time: string } | null = null;
  taskItem: TaskItem;
  staffLabel: string | undefined;
  private progressDialogRef: MatDialogRef<ProgressDialogComponent> | null = null;
  profilePhotoUrl: string | null = null;
  loadingPhoto = true;
  
  // Two-person assist properties
  is2pa = false;
  hasMultipleCarers = false;
  allocatedCarers: { id: string, name: string }[] = [];
  originalCarerString = '';
  pendingChanges = false;

  sliderValue: number = 0;
  endValue: number = 0;
  sliderOptions: Options = {
    floor: 0,
    ceil: 1440,
    step: 5,
  };

  constructor(
    public dialogRef: MatDialogRef<CarePlanDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { event: TaskItem },
    private carePlanService: CarePlanService,
    private staffService: StaffService,
    private eventBusService: EventBusService, private notificationService: NotificationService,
    private clientService: ClientService,
    private dialog: MatDialog
  ) {
    this.taskItem = data.event;
  }

  ngOnInit() {
    if (this.taskItem.carer_string) {
      this.staffLabel = this.staffService.getStaffLabel(this.taskItem.carer_string);
    }
    this.loadCarePlan();
    this.loadProfilePhoto();
    this.initializeTwoPersonAssistData();
  }
  
  private initializeTwoPersonAssistData() {
    // Check if this is a two-person assist event
    this.is2pa = this.taskItem.is_2pa === true;
    
    // Store the original carer string for comparison
    this.originalCarerString = this.taskItem.carer_string?.toString() || '';
    
    // Check if this event has multiple carers allocated
    this.hasMultipleCarers = this.originalCarerString.includes(',');
    
    // Only process if this is a 2PA with multiple carers
    if (this.is2pa && this.hasMultipleCarers) {
      // Split the carer string into individual carer IDs
      const carerIds = this.originalCarerString.split(',').map(id => id.trim());
      
      // Get the name for each carer ID
      this.allocatedCarers = carerIds.map(id => {
        const name = this.staffService.getStaffLabel(id) || 'Unknown Staff';
        return { id, name };
      });
    }
  }
  
  removeCarer(carerId: string) {
    // Find the index of the carer to remove
    const index = this.allocatedCarers.findIndex(carer => carer.id === carerId);
    
    // Only proceed if the carer was found and we have more than one carer
    if (index !== -1 && this.allocatedCarers.length > 1) {
      // Remove the carer from the array
      this.allocatedCarers.splice(index, 1);
      
      // Mark that we have pending changes
      this.pendingChanges = true;
    }
  }
  
  applyChanges() {
    // Only proceed if we have pending changes
    if (!this.pendingChanges) {
      this.onClose();
      return;
    }
    
    // Create the new carer string from the remaining allocated carers
    const newCarerString = this.allocatedCarers.map(carer => carer.id).join(',');
    
    // Update the task item with the new carer string
    this.taskItem.carer_string = newCarerString;
    
    // Update the partially_allocated flag based on the number of carers
    this.taskItem.partially_allocated = this.allocatedCarers.length === 1;
    
    // Mark the event as having a pending allocation change
    this.taskItem.pending_allocation_change = true;
    this.taskItem.pending_change = true;
    
    // Close the dialog and return the updated task item
    this.dialogRef.close({ 
      success: true, 
      event: this.taskItem 
    });
  }

  private loadProfilePhoto() {
    this.loadingPhoto = true;
    // Only attempt to load photo if this is not a staff event and residentID exists
    if (this.taskItem.residentID) {
      this.clientService.getClientPhoto(this.taskItem.residentID).subscribe({
        next: (blob) => {
          this.profilePhotoUrl = URL.createObjectURL(blob);
          this.loadingPhoto = false;
        },
        error: (error) => {
          this.loadingPhoto = false;
          this.profilePhotoUrl = null;
        }
      });
    } else {
      this.loadingPhoto = false;
      this.profilePhotoUrl = null;
    }
  }

  setCurrentStaffToKey() {
    const confirmMessage = "This change will affect all future occurrences of this recurring event. Do you want to proceed?";

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: {
        title: 'Confirm Changes',
        message: confirmMessage
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Show progress dialog
        this.progressDialogRef = this.dialog.open(ProgressDialogComponent, {
          disableClose: true,
          data: {
            title: 'Updating Key Staff',
            finalStep: 'Updating staff assignment'
          }
        });

        // Skip for staff events that don't have a client
        if (!this.taskItem.residentID) {
          if (this.progressDialogRef) {
            const progressComponent = this.progressDialogRef.componentInstance;
            progressComponent.setError('Cannot update key staff for staff events without clients');
          }
          return;
        }

        // Call the careplan service to update key staff
        this.carePlanService.updateKeyStaff(
          this.taskItem.CCQuoteDetailID as string,
          this.taskItem.carer_string as string,
          this.taskItem.residentID
        ).subscribe({
          next: (response) => {
            if (this.progressDialogRef) {
              const progressComponent = this.progressDialogRef.componentInstance;
              progressComponent.completeRemaining();

              if (response.status === 'success') {
                progressComponent.setSuccess();
                this.eventBusService.emitKeyStaffUpdated({
                  eventId: this.taskItem.id,
                  newKeyStaff: this.taskItem.carer_string as string,
                  staffLabel: this.staffLabel || ''
                });

                // Trigger background refresh after successful update, ensuring residentID exists
                if (this.taskItem.residentID) {
                  // We subscribe here but don't wait for it to close the dialog
                  this.carePlanService.refreshClientServicePlanCache(this.taskItem.residentID).subscribe(); 
                } else {
                  console.warn("Cannot refresh cache: residentID is missing from taskItem.");
                }
                // Close dialog and signal reload needed
                this.dialogRef.close({ success: true, needsReload: true });
              } else {
                const errorMessage = response.message || 'Failed to update key staff. Please try again or contact support.';
                progressComponent.setError(errorMessage);
              }
            }
          },
          error: (error) => {
            if (this.progressDialogRef) {
              const progressComponent = this.progressDialogRef.componentInstance;
              progressComponent.setError('Failed to update key staff. Please try again or contact support.');
            }
            this.notificationService.addMessage('critical', 'Error updating staff assignment: ' + error, error);
          }
        });
      }
    });
  }

  private loadCarePlan() {
    // Skip loading care plan for staff events without clients
    if (!this.taskItem.residentID) {
      this.error = 'Care plan not available for staff events';
      this.loading = false;
      return;
    }
    
    this.carePlanService.getClientCarePlan(this.taskItem.residentID)
      .subscribe({
        next: (response) => {
          this.carePlan = response;
          this.findSelectedService();
          this.loading = false;
        },
        error: (error) => {
          this.error = 'Failed to load care plan';
          this.loading = false;
          this.notificationService.addMessage('critical', 'Error loading care plan: ' + error, error);
        }
      });
  }

  private findSelectedService() {
    if (this.carePlan) {
      this.selectedService = this.carePlan.servicesDetails.find(service =>
        service.serviceFundingSources.some(source => source.CCQuoteDetailID === this.taskItem.CCQuoteDetailID)
      ) || null;

      if (this.selectedService) {
        this.initialStartTime = this.selectedService.StartTime;
        this.initialEndTime = this.formatTimeLabel(this.timeStringToMinutes(this.selectedService.StartTime) + Number(this.selectedService.Duration));
        this.loadRateWindow();
      }
    }
  }

  private loadRateWindow() {
    if (this.selectedService) {
      this.carePlanService.getRateWindow(this.selectedService)
        .subscribe({
          next: (response) => {
            this.rateWindow = response;
            this.initializeSlider();
          },
          error: (error) => {
            this.notificationService.addMessage('critical', 'Error loading rate window: ' + error, error);
          }
        });
    }
  }

  private initializeSlider() {
    if (this.rateWindow) {
      let rateWindowStart = this.timeStringToMinutes(this.rateWindow.start_time);
      let rateWindowEnd = this.timeStringToMinutes(this.rateWindow.end_time);

      if (rateWindowEnd <= rateWindowStart) {
        rateWindowEnd += 1440;
      }

      const serviceDuration = Number(this.selectedService!.Duration);

      this.sliderOptions = {
        floor: rateWindowStart,
        ceil: rateWindowEnd,
        step: 5,
        showTicks: true,
        tickStep: 60,
        showTicksValues: false,
        ticksArray: this.generateTimeTicks(rateWindowStart, rateWindowEnd),
        translate: (value: number): string => {
          return this.formatTimeLabel(value);
        },
        disabled: false,
        draggableRangeOnly: true,
      } as Options;

      let currentMinutes = this.timeStringToMinutes(this.selectedService!.StartTime);

      if (currentMinutes < rateWindowStart) {
        currentMinutes += 1440;
      }

      this.sliderValue = (currentMinutes >= rateWindowStart && currentMinutes <= rateWindowEnd)
        ? Math.round(currentMinutes / 5) * 5
        : rateWindowStart;

      this.endValue = this.sliderValue + serviceDuration;
    }
  }

  onSliderChange(value: number) {
    this.sliderValue = value;
    this.endValue = value + Number(this.selectedService!.Duration);
    this.timeChanged = this.sliderValue === this.timeStringToMinutes(this.initialStartTime);
  }
  
  private timeStringToMinutes(time: string): number {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  }

  private minutesToTimeString(minutes: number): string {
    const adjustedMinutes = minutes % 1440;
    const hours = Math.floor(adjustedMinutes / 60);
    const mins = adjustedMinutes % 60;
    return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
  }

  private generateTimeTicks(start: number, end: number): number[] {
    const ticks: number[] = [];
    for (let minute = start; minute <= end; minute += 60) {
      ticks.push(minute);
    }
    return ticks;
  }  

  formatTimeLabel(minutes: number): string {
    const adjustedMinutes = minutes % 1440;
    const hours = Math.floor(adjustedMinutes / 60);
    const mins = adjustedMinutes % 60;
    return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
  }

  onPublish(): void {
    const service = this.selectedService;
    if (!service) return;

    // Skip for staff events without clients
    if (!this.taskItem.residentID) {
      this.notificationService.addMessage('warning', 'Cannot update service timing for staff events', this.taskItem);
      return;
    }

    const confirmMessage = "This change will affect all future occurrences of this recurring event. Do you want to proceed?";

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: {
        title: 'Confirm Changes',
        message: confirmMessage
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Show progress dialog
        this.progressDialogRef = this.dialog.open(ProgressDialogComponent, {
          disableClose: true,
          data: {
            title: 'Updating Service Time',
            finalStep: 'Updating service plan timing'
          }
        });

        const newStartTime = this.minutesToTimeString(this.sliderValue);

        // We've already checked that residentID is not null above
        this.carePlanService.updateServicePlanTiming(
          this.taskItem.residentID!,
          service.ID,
          newStartTime
        ).subscribe({
          next: (response) => {
            if (this.progressDialogRef) {
              const progressComponent = this.progressDialogRef.componentInstance;
              progressComponent.completeRemaining();

              if (response.status === 'success') {
                progressComponent.setSuccess();
                
                // Trigger background refresh after successful update, ensuring residentID exists
                if (this.taskItem.residentID) {
                  // We subscribe here but don't wait for it to close the dialog
                  this.carePlanService.refreshClientServicePlanCache(this.taskItem.residentID).subscribe();
                } else {
                  console.warn("Cannot refresh cache: residentID is missing from taskItem.");
                }
                // Close dialog and signal reload needed
                this.dialogRef.close({ success: true, needsReload: true });
              } else {
                const errorMessage = response.message || 'Failed to update service timing. Please try again or contact support.';
                progressComponent.setError(errorMessage);
              }
            }
          },
          error: (error) => {
            this.notificationService.addMessage('critical', 'Error updating service timing: ' + error, error);
            if (this.progressDialogRef) {
              const progressComponent = this.progressDialogRef.componentInstance;
              progressComponent.setError('Failed to update service timing. Please try again or contact support.');
            }
          }
        });
      }
    });
  }

  onClose(): void {
    if (this.profilePhotoUrl) {
      URL.revokeObjectURL(this.profilePhotoUrl);
    }
    this.dialogRef.close();
  }
}
