<div class="custom-dialog">
    <mat-dialog-content>
        <div *ngIf="loading" class="loading">
            <mat-spinner diameter="40"></mat-spinner>
            <p>Loading care plan...</p>
        </div>

        <div *ngIf="error" class="error">
            <mat-icon color="warn">error</mat-icon>
            <p>{{ error }}</p>
        </div>

        <div *ngIf="carePlan && !loading && !error" class="dialog-content">
            <div class="header-section">
                <div class="dialog-header">
                    <div class="photo-container" *ngIf="!loadingPhoto">
                        <img *ngIf="profilePhotoUrl" [src]="profilePhotoUrl" alt="Client photo" class="photo">
                        <mat-icon *ngIf="!profilePhotoUrl" class="placeholder-icon">account_circle</mat-icon>
                    </div>
                    <h3>{{ carePlan.headerDetails.Client }}</h3>
                </div>
                <div class="details-section">
                    <p><strong>{{ carePlan.headerDetails.PlanIsActive === '1' ? 'Active' : 'Inactive' }} Care
                            Plan</strong> v{{ carePlan.headerDetails.Version }}: {{ carePlan.headerDetails.StartDate |
                        date }} to {{ carePlan.headerDetails.EndDate | date }}</p>
                </div>
            </div>

            <div class="services-section" *ngIf="selectedService">
                <h4><span class="title-text">{{ initialStartTime }}</span> - {{ selectedService.Description }}</h4>
                <p class="service-details">{{ selectedService.DescriptionSchedule }} Care Plan Item {{
                    selectedService.ID }}</p>

                <div class="content-window-l1" *ngIf="rateWindow">
                    <!-- Two-person assist section - only show for 2PA events with multiple carers -->
                    <div *ngIf="is2pa && hasMultipleCarers" class="content-window-l2 two-pa-section">
                        <h5>Two-Person Assist</h5>
                        <p class="service-details">This service requires two staff members. You can remove one staff member if needed.</p>
                        
                        <div class="allocated-carers-list">
                            <div *ngFor="let carer of allocatedCarers" class="allocated-carer-item">
                                <span class="carer-name">{{ carer.name }}</span>
                                <button mat-icon-button color="warn" (click)="removeCarer(carer.id)" 
                                        [disabled]="allocatedCarers.length <= 1"
                                        matTooltip="Remove this carer">
                                    <mat-icon>remove_circle</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>

                    <h5>Adjust Care Plan</h5>

                    <div class="content-window-l2">
                        <div class="staff-grid">
                            <div class="label-column">Key staff:</div>
                            <div class="name-column">{{ taskItem.suggested_carer_name }}</div>
                            <div class="action-column" rowspan="2">
                                <button mat-raised-button class="set-staff-btn" 
                                        *ngIf="taskItem.suggested_carer_name !== staffLabel && staffLabel && staffLabel !== 'undefined' && staffLabel !== 'Unallocated'"
                                        (click)="setCurrentStaffToKey()">
                                    Set Current to Key
                                </button>
                                <mat-icon class="help-icon" 
                                         *ngIf="!(taskItem.suggested_carer_name !== staffLabel && staffLabel && staffLabel !== 'undefined' && staffLabel !== 'Unallocated')"
                                         matTooltip="To update the default carer for this activity, close this dialog box, drag the service to the target carer, then open this dialog box again to apply that change to the care plan."
                                         matTooltipPosition="left">
                                    help_outline
                                </mat-icon>
                            </div>
                            <div class="label-column">Current staff:</div>
                            <div class="name-column">{{ staffLabel }}</div>
                        </div>
                    </div>

                    <div class="content-window-l2">
                        <div class="time-slider-header">
                            <label class="time-slider-label"><span class="title-text">Start Time:</span></label>
                            <button mat-raised-button 
                                    *ngIf="!timeChanged"
                                    (click)="onPublish()">Publish</button>
                            <mat-icon class="help-icon" 
                                     *ngIf="timeChanged"
                                     matTooltip="You can adjust the service start time within the available timeslots shown on the timeline. These changes won't affect the client's care plan budget. For times outside these slots, please consult with the client about budget implications and make changes through eCase."
                                     matTooltipPosition="left">
                                help_outline
                            </mat-icon>
                        </div>
                        <ngx-slider [value]="sliderValue" [highValue]="endValue" [options]="sliderOptions"
                            (valueChange)="onSliderChange($event)">
                        </ngx-slider>
                    </div>

                </div>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-raised-button (click)="pendingChanges ? applyChanges() : onClose()">
            {{ pendingChanges ? 'Apply' : 'Close' }}
        </button>
    </mat-dialog-actions>
</div>
