import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from, forkJoin, Subject, of } from 'rxjs';
import { mergeMap, tap, finalize, takeUntil } from 'rxjs/operators';
import { EventService } from './event.service';
import { format } from 'date-fns';
import { TaskItem } from '../models/task.model';

@Injectable({
  providedIn: 'root'
})
export class ChangeManagementService {
  private _pendingChangesCount = new BehaviorSubject<number>(0);
  private _publishComplete = new BehaviorSubject<boolean>(false);
  private cancelPublish = new Subject<void>();

  constructor(private eventService: EventService) { }

  get pendingChangesCount$(): Observable<number> {
    return this._pendingChangesCount.asObservable();
  }

  get publishComplete$(): Observable<boolean> {
    return this._publishComplete.asObservable();
  }

  updatePendingChangesCount(events: any[]): void {
    const pendingCount = events.filter(event => event.pending_change).length;
    this._pendingChangesCount.next(pendingCount);
  }

  cancelPublishing(): void {
    this.cancelPublish.next();
    this._publishComplete.next(false);
  }

  publishPendingChanges(events: TaskItem[], updateEvent: (eventId: string) => void): void {
    if (this.cancelPublish.closed) {
      this.cancelPublish = new Subject<void>();
    }

    const pendingEvents = events.filter(event => event.pending_change && new Date(event.start_date) >= new Date());
    const totalPending = pendingEvents.length;
    this._pendingChangesCount.next(totalPending);

    from(pendingEvents).pipe(
      mergeMap(event => {
        const timingObservable = event.pending_timing_change
          ? this.eventService.updateActivityTiming(
            event.id,
            event.carer_string,
            event.start_date,
            event.end_date
          ).pipe(takeUntil(this.cancelPublish))
          : of(null);

        const allocationObservable = event.pending_allocation_change
          ? (this.isEventUnallocated(event)
            ? this.eventService.unallocateServiceInstance(event.id).pipe(takeUntil(this.cancelPublish))
            : this.eventService.allocateServiceInstance(event.id, event.carer_string).pipe(takeUntil(this.cancelPublish)))
          : of(null);

        return forkJoin([timingObservable, allocationObservable]).pipe(
          takeUntil(this.cancelPublish),
          tap(([timingResponse, allocationResponse]) => {
            event.original_carer_string = event.carer_string;
            event.original_start_date = event.start_date;
            event.original_end_date = event.end_date;
            event.pending_timing_change = false;
            event.pending_allocation_change = false;
            event.pending_change = false;
            event.css_custom = event.css_custom.replace('relocated-section-same', '').trim();

            if (this.isEventUnallocated(event)) {
              event.css_ecase = 'appointment-unallocated';
            } else if (allocationResponse && allocationResponse[event.carer_string]?.IsAllocated) {
              event.css_ecase = 'appointment-approved';
            }

            updateEvent(event.id);
            const currentCount = this._pendingChangesCount.getValue();
            this._pendingChangesCount.next(currentCount - 1);
          })
        );
      }, 3),
      takeUntil(this.cancelPublish),
      finalize(() => {
        this._publishComplete.next(true);
        this.updatePendingChangesCount(events);
      })
    ).subscribe(
      () => { },
      (error) => console.error('An error occurred while publishing changes:', error),
      () => { }
    );
  }

  private isEventUnallocated(event: any): boolean {
    const carerString = event.carer_string;
    if (!carerString) {
      return true;
    }
    const carerId = parseInt(carerString);
    return carerId >= 2000000 && carerId < 3000000;
  }
}
